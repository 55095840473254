export enum Environments {
  local = 'localhost',
  dev = 'dev.payactive.io',
  stage = 'stage.payactive.io',
  prod = 'www.payactive.io',
  sandbox = 'sandbox.payactive.io',
}

export function getAllEnvironments(): string[] {
  return Object.values(Environments);
}
