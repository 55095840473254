import { Button, Card, CardActions, CardContent, CardMedia, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../types/Project';

const useStyles = makeStyles((theme) => ({
  root: {
    /* minWidth: 280,
    maxWidth: 245,*/
    width: '100%',
    height: '420px',
    minHeight: '420px',
    textAlign: 'center',
  },
  title: {
    /* textAlign: 'center'*/
  },
  media: {
    height: 160,
  },
  info: {
    display: 'grid',
    justifyContent: 'center',
    color: 'black',
    marginBottom: theme.spacing(2),
  },
  descriptionContainer: {
    padding: '12px 12px 5px',
  },
  description: {
    height: '73px',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
  },
  button: {
    padding: '0.5rem 1rem',
    marginTop: '1rem',
  },
}));

type ProjectCardProps = {
  project: Project;
  index?: number;
  onSelect?: (index: number, close?: boolean) => void;
  style?: CSSProperties;
};
export default function ProjectCard({ project, index = 0, onSelect, style }: ProjectCardProps) {
  const { t } = useTranslation('global');

  const classes = useStyles();

  return (
    <Card
      variant="outlined"
      className={classes.root}
      style={style}
      onClick={() => {
        onSelect && onSelect(index, false);
      }}
    >
      <CardContent>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
          {project.title}
        </Typography>
      </CardContent>
      <CardMedia className={classes.media} image={project.image} title={project.title} />
      <CardContent className={classes.descriptionContainer}>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
          {project.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.info}>
        <Link color="secondary" style={{ fontSize: '0.8rem' }} href={project.link} target="_blank">
          {t('social_cashback.more_information')}
        </Link>
        {onSelect && (
          <Button className={classes.button} onClick={() => onSelect(index, true)} size="small" color="primary" variant="contained">
            {t('social_cashback.select')}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
