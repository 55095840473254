import { TFunction } from 'i18next';
import IBAN from 'iban';
import * as Yup from 'yup';
import bicValidator from 'bic-validator';

const schema = (t: TFunction): { [key in number]?: any } => {
  return {
    0: Yup.object().shape({
      accountHolder: Yup.string().required(t('user_form.required')),
      iban: Yup.mixed()
        .required(t('user_form.required'))
        .test('valid', t('user_form.no_valid_iban'), (iban?: string) => {
          return IBAN.isValid(iban || ''.replaceAll(/\s|_/g, ''));
        }),
      bic: Yup.mixed()
        .required(t('user_form.required'))
        .test('valid', t('user_form.no_valid_bic'), (bic) => bicValidator.isValid(bic)),
    }),
    1: Yup.object().shape({
      address: Yup.object().shape({
        line: Yup.string().required(t('user_form.required')).nullable(),
        zipCode: Yup.number().required(t('user_form.required')).positive(t('user_form.positive')).typeError(t('user_form.positive')),
        city: Yup.string().required(t('user_form.required')).nullable(),
        country: Yup.string().required(t('user_form.required')).nullable(),
      }),
    }),
  };
};

export { schema };
