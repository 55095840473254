import React, { LegacyRef, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import WithPayment from '../pages/PaymentFlow/WithPayment';
import PublicPaymentService from '../services/PublicPaymentService';
import { CancelReason } from '../pages/PaymentFlow/ManualPayment/CancelReason';
import useErrorHandler from '../pages/Error/ErrorHandler';
import { PaymentFlowPaths } from '../pages/PaymentFlow/PaymentFlow';
import { ErrorCodes } from '../pages/Error/ErrorPage';
import { WithProgress } from '../pages/WithProgressContext';

declare var xs2a: any;

const Xs2aStyles = () => {
  const theme = useTheme();
  return (
    <style>
      {`
    :root {
      --xs2a-primary: ${theme.palette.primary.main};
      --xs2a-input-border-focus-color: ${theme.palette.primary.main};
      --xs2a-box-shadow-bottom: ${theme.palette.primary.main};
      --xs2a-primary-darker: ${theme.palette.primary.dark};
      --xs2a-error: red;
      --xs2a-warning: yellow;
      --xs2a-border-radius: 5px;
      /* other overwrites, see top of xs2a_base.css file for all variables */
    }
    #XS2A-Form button.xs2a-abort, #XS2A-Form button.xs2a-back, #XS2A-Form button.xs2a-restart,
     #XS2A-Form button.xs2a-back:hover, #XS2A-Form button.xs2a-back:focus, #XS2A-Form button.xs2a-abort:hover, 
     #XS2A-Form button.xs2a-abort:focus, #XS2A-Form button.xs2a-restart:hover, #XS2A-Form button.xs2a-restart:focus{
      color: black;
      background: none;
      border: none!important;
      padding: 0;
      text-decoration: underline;
      cursor: pointer;
      box-shadow: none;
      font-size: smaller;
      margin: 32px 24px 0;
    }
    #XS2A-Form button, #XS2A-Form .xs2a-dialog-button {
      font-weight: 300;
    }
    #XS2A-Form .xs2a-submit-line :last-child, #XS2A-Form .xs2a-restart-line :last-child {
      min-width: 60%;
    }
    #XS2A-Form button:focus {
      box-shadow: none;
    }
    #XS2A-Form .xs2a-dialog {
      max-width: 400px;
      margin: 15% auto;
    }
      `}
    </style>
  );
};

const FintecDialog = ({
  wizardKey,
  close,
  loaded,
  success,
  variant,
}: {
  wizardKey: string;
  close: () => void;
  loaded: () => void;
  success: () => void;
  variant: 'direct' | 'online';
}) => {
  const xs2aRef: LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const fintec = <div ref={xs2aRef} id="XS2A-Form" data-xs2a={wizardKey} />;

  const handleGenericError = useErrorHandler();
  const navigate = useNavigate();
  const { i18n } = useTranslation('payment');

  const { paymentId, restartPath } = useContext(WithPayment);
  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://api.xs2a.com/xs2a.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => scriptLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableScroll = () => {
    document.getElementsByTagName('body')?.item(0)?.removeAttribute('style');
  };

  let errorMessage: string;
  const scriptLoaded = () => {
    xs2a.useBaseStyles();
    xs2a.lang(i18n.language);
    //fintec flow
    xs2a.configure({
      'validate-login-credentials': false,
      'back-button-action': () => {
        close();
      },
      'logo-alignment': 'center',
      'logo-height': 30,
    });
    xs2a.login(() => {
      loaded();
      updatesProgress(75);
    });

    xs2a.finish(() => {
      enableScroll();
      success();
    });

    variant === 'direct' && xs2a.abort(enableScroll);

    variant === 'online' &&
      xs2a.abort(() => {
        onAbort();
        enableScroll();
      });

    xs2a.error(function (errorCode: any, messages: any, recoverable: any) {
      console.error(`fintech error: ${errorCode} ${messages} ${recoverable}`);
    });

    xs2a.render(() => {});

    xs2a.init();
  };

  const onAbort = () => {
    const substringErrorMessage = errorMessage?.substring(0, 200);
    PublicPaymentService.cancelPayment(paymentId, CancelReason.OTHER, substringErrorMessage).then((res) => {
      if (res) {
        navigate(restartPath + PaymentFlowPaths.manual);
      } else {
        handleGenericError(new Error('PaymentService.cancelPayment failed'), ErrorCodes.Payment_cancel_failed);
      }
    });
  };

  return (
    <>
      <Xs2aStyles />
      {fintec}
    </>
  );
};

export default FintecDialog;
