import React, { ReactElement, useContext } from 'react';
import { Link, Typography } from '@mui/material';
import { OTPContext } from './OtpContextProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OtpVerifiedEmail(): ReactElement {
  const { emailAddress, clearAccessData } = useContext(OTPContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      {emailAddress && (
        <Typography variant={'body2'}>
          {emailAddress} (
          <Link
            color={'secondary'}
            onClick={() => {
              clearAccessData();
              navigate(0);
            }}
          >
            {t('otp.logout')}
          </Link>
          )
        </Typography>
      )}
    </>
  );
}
