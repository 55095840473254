import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Dialog from '../../components/Dialog';
import { Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { Button, FormikInputField } from '@payactive/app-common';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import schema from './RequestCancellationValidationSchema';
import SubscriptionService from '../../services/SubscriptionService';
import { Subscriptions } from '../../types/Subscription/Subscription';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyAccountId: string;
  subscriptionId: string;
};

const initialFormikValues: FormikValues = {
  cancellationType: Subscriptions.CancellationType.ORDINARY,
  cancellationReason: '',
  cancellationTargetDate: new Date(),
};

export default function RequestCancellationDialog({ isOpen, onClose, companyAccountId, subscriptionId }: Props) {
  const { t, i18n } = useTranslation();
  const [cancellationType, setCancellationType] = useState(Subscriptions.CancellationType.ORDINARY);

  const getLocale = () => {
    switch (i18n.language) {
      case 'de':
        return deLocale;
      default:
        return enLocale;
    }
  };

  return (
    <Dialog title={t('customer_portal.request_cancellation')} open={isOpen} close={onClose}>
      <>
        <Typography style={{ marginBottom: '1rem' }} variant="body2">
          {t('customer_portal.request_cancellation_dialog_description')}
        </Typography>
        <Formik initialValues={initialFormikValues} validationSchema={schema(t)} onSubmit={() => {}}>
          {({ values, setFieldValue, isValid }) => (
            <Form>
              <Grid container spacing={2} direction={'column'}>
                <Grid item>
                  <ToggleButtonGroup
                    fullWidth
                    exclusive
                    value={cancellationType}
                    onChange={(event, value) => {
                      setFieldValue('cancellationType', value);
                      setCancellationType(value);
                    }}
                  >
                    <ToggleButton value={Subscriptions.CancellationType.ORDINARY}>{t('customer_portal.ordinary_cancellation')}</ToggleButton>
                    <ToggleButton value={Subscriptions.CancellationType.EXTRAORDINARY}>
                      {t('customer_portal.extraordinary_cancellation')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item>
                  <FormikInputField name="cancellationReason" label={t('customer_portal.cancellation_reason')} fullWidth />
                </Grid>

                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
                    <DesktopDatePicker
                      disablePast
                      label={t('customer_portal.cancellation_target_date')}
                      inputFormat={t('customer_portal.date_format')}
                      onChange={(value) => {
                        setFieldValue('cancellationTargetDate', value);
                      }}
                      componentsProps={{
                        actionBar: { actions: ['clear', 'today', 'cancel', 'accept'] },
                      }}
                      value={values.cancellationTargetDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={'cancellationTargetDate'}
                          label={'Cancellation target date'}
                          onBlur={({ target: { value } }) => {
                            if (value && value !== '') {
                              let parsedDate = parse(value, t('checkout.date_format'), new Date());
                              setFieldValue('cancellationTargetDate', parsedDate);
                            } else {
                              setFieldValue('cancellationTargetDate', null);
                            }
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item alignSelf={'flex-end'}>
                  <Button
                    fullWidth
                    name="submit"
                    label={t('customer_portal.send_request')}
                    disabled={!isValid}
                    onClick={() => {
                      SubscriptionService.requestCancellation(
                        companyAccountId,
                        subscriptionId,
                        values.cancellationType,
                        values.cancellationReason,
                        values.cancellationTargetDate,
                      ).then(onClose);
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    </Dialog>
  );
}
