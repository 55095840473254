import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import CustomerThemeProvider, { WithCompanyBrandProvider } from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import DebtorPortalOverview from './DebtorPortalOverview';
import OtpContextProvider, { OtpSecured } from '../../components/Otp/OtpContextProvider';
import { ContainerRef, ContainerReference } from '../../components/Container';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import BrandService from '../../services/BrandService';
import DefaultBackground from '../../components/CustomerThemeProvider/default.png';
import PayactiveLogo from '../../components/CustomerThemeProvider/payactive.png';
import { CompanyBrand } from '../../types/CompanyBrand';
import LoadingContainer from '../../components/LoadingContainer';
import OtpVerifiedEmail from '../../components/Otp/OtpVerifiedEmail';

export default function DebtorPortalRouter() {
  const { t } = useTranslation();
  const [companyBrand, setCompanyBrand] = useState<CompanyBrand>();
  const [isLoading, setIsLoading] = useState(true);
  const container = useRef<ContainerReference>(null);
  const match = useMatch('/payment/subscriptions/:companyId/*');
  const companyId = match?.params.companyId;

  useEffect(() => {
    if (companyId) {
      BrandService.getBrandingByCompanyAccountId(companyId)
        .then(({ companyBrand }) => setCompanyBrand(companyBrand))
        .catch(() => setCompanyBrand({ brandBackgroundImageUrl: DefaultBackground, companyLogoUrl: PayactiveLogo }))
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <WithCompanyBrandProvider companyBrand={companyBrand}>
      <OtpContextProvider>
        <CustomerThemeProvider>
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <ContainerRef
              ref={container}
              title={
                <>
                  <Grid container alignItems={'center'} direction={'column'}>
                    <Grid item>
                      <Typography variant="h6">{t('customer_portal.app_title')}</Typography>
                    </Grid>
                    <Grid item>
                      <OtpVerifiedEmail />
                    </Grid>
                  </Grid>
                </>
              }
              bigIcon={true}
            >
              <OtpSecured description={t('customer_portal.otp_description')}>
                <Routes>
                  <Route path={'/'} element={<Navigate to={'/payment/subscriptions/' + companyId + '/overview'} />} />
                  <Route path={'/overview'} element={<DebtorPortalOverview />} />
                </Routes>
              </OtpSecured>
            </ContainerRef>
          )}
        </CustomerThemeProvider>
      </OtpContextProvider>
    </WithCompanyBrandProvider>
  );
}
