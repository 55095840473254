import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Link, List } from '@mui/material';
import { PoweredByPayactive } from './PayactiveLogo/PayactiveLogo';
import Dialog from './Dialog';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { LockOutlined } from '@mui/icons-material';

const useStyles = makeStyles((_) => ({
  footerLogo: {},
  footerButton: {
    fontSize: 'x-small',
  },
}));

export default function Footer(props: { className?: string }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation('global');

  const listEntries = [t('footer.list1'), t('footer.list2'), t('footer.list3'), t('footer.list4')];

  return (
    <div className={props.className}>
      <Box
        sx={{
          height: 100,
          backgroundColor: '#F2F2F2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PoweredByPayactive />

        <Link
          color="secondary"
          className={classes.footerButton}
          onClick={() => {
            setOpen(true);
          }}
        >
          {t('links.why_is_secure')}
        </Link>

        <Dialog
          title={t('links.why_is_secure')}
          xIcon={true}
          close={() => {
            setOpen(false);
          }}
          open={open}
        >
          <List>
            {listEntries.map((entry, index) => {
              return (
                <ListItem key={'footer' + index}>
                  <ListItemIcon style={{ minWidth: '30px' }}>
                    <LockOutlined style={{ fontSize: '1rem' }} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{entry}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Dialog>
      </Box>
    </div>
  );
}
