import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { Containerized, useContainerStyles } from '../components/Container';
import { WithProgress } from './WithProgressContext';
import SocialCashback from '../components/SocialCashback/SocialCashback';
import WithPayment from './PaymentFlow/WithPayment';
import { Project } from '../types/Project';
import { ErrorCodes } from './Error/ErrorPage';
import useErrorHandler from './Error/ErrorHandler';
import PublicPaymentService from '../services/PublicPaymentService';
import StatusIcon from '../components/StatusIcon';
import { PaymentState } from '../types/PaymentState';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentDetailsMenu from '../components/Payment/PaymentDetailsMenu';

const useStyles = makeStyles((theme) => ({
  greyColor: {
    color: theme.palette.secondary.main,
  },
}));

export default function CancelScreen({ updateContainer }: Containerized) {
  const { t } = useTranslation('global');
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { paymentId, paymentInformation } = useContext(WithPayment);

  const { updatesProgress } = useContext(WithProgress);
  const handleGenericError = useErrorHandler();

  useEffect(() => {
    const title = <Typography variant="h6">{t('success.your_online_payments')}</Typography>;
    const menu = <PaymentDetailsMenu />;

    updateContainer({ title: title, menu: menu, bigIcon: false });
  }, [updateContainer, t]);

  useEffect(() => {
    updatesProgress(100);
  }, [updatesProgress]);

  const onSelectProject = (project: Project) => {
    PublicPaymentService.updateProject(paymentId, project.id).then((res: any) => {
      if (!res) {
        handleGenericError(new Error('PublicPaymentService.updateProject failed'), ErrorCodes.Project_update_failed);
      }
    });
  };

  return (
    <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
      {paymentInformation.state === PaymentState.CANCELLED ? (
        <>
          <CancelIcon style={{ justifySelf: 'center' }} fontSize="large" />
          <Typography style={{ marginBottom: '0.5rem' }} variant="h6">
            {paymentInformation.invoiceTitle ? t('cancel.invoice_stop') : t('cancel.payment_stop')}
          </Typography>
          <Typography style={{ marginBottom: '0.5rem' }} variant="body2">
            {paymentInformation.invoiceTitle ? t('cancel.invoice_stop_subtitle') : t('cancel.payment_stop_subtitle')}
            <b>{t(`${paymentInformation.companyName}`)}</b>
            {t('cancel.payment_stop_subtitle_reason')} {paymentInformation.cancelReason}
          </Typography>
          <Typography style={{ marginBottom: '2rem' }} variant="body2">
            {t('cancel.nothing_todo')}
          </Typography>
        </>
      ) : paymentInformation.state === PaymentState.REFUND_COMPLETED ? (
        <StatusIcon variantIcon="up" variant="success" iconSize="medium" title={t('cancel.refund')}>
          <Typography style={{ marginBottom: '0.5rem' }} variant="body2">
            {t('cancel.refund_subtitle')}
            <b>{t(`${paymentInformation.companyName}`)}</b>
            {t('cancel.refund_subtitle_2')}
          </Typography>
          <Typography style={{ marginBottom: '2rem' }} variant="body2">
            {t('cancel.nothing_todo')}
          </Typography>
        </StatusIcon>
      ) : (
        <>
          <RotateLeftRoundedIcon style={{ justifySelf: 'center' }} fontSize="large" />
          <Typography variant="h6">{t('cancel.refund_invoice_progress_title')}</Typography>
          <Typography style={{ marginBottom: '0.5rem' }} variant="body2">
            {t('cancel.refund_progress_subtitle')}
            <b>{t(`${paymentInformation.companyName}`)}</b>
            {t('cancel.refund_progress_subtitle_reason')} {paymentInformation.cancelReason}
          </Typography>
          <Typography style={{ marginBottom: '2rem' }} variant="body2">
            {t('cancel.nothing_todo')}
          </Typography>
        </>
      )}

      <SocialCashback
        socialCashbackCents={paymentInformation.socialCashbackCents}
        project={paymentInformation.project}
        onSelectProject={onSelectProject}
        variant="manual"
      />
      <div style={{ marginTop: '1rem' }}>
        <Typography className={classes.greyColor} variant="h6">
          {t('success.all_done')}
        </Typography>
        <Typography className={classes.greyColor} variant="body2">
          {t('success.close_the_window')}
        </Typography>
      </div>
    </div>
  );
}
