import { AxiosInstance } from 'axios';
import { Project } from '../types/Project';
import ApiClient from './ApiClient';

export default class PublicProjectService {
  private static _instance: AxiosInstance;

  private static get projectClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  public static async fetchProjects(): Promise<Project[] | never> {
    let res = await this.projectClient.get<{ _embedded: { projects: Project[] } }>(ApiClient.getApiDomain() + '/public/projects');

    if (res.status === 200) {
      return res.data._embedded.projects;
    } else {
      throw new Error();
    }
  }
}
