import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { Environments, getAllEnvironments } from './Environments';

const getConnectionString = (): string => {
  switch (window.location.hostname) {
    case Environments.dev:
      return 'InstrumentationKey=09942f2e-44b1-4dc6-b694-d5281e6bea58;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/';
    case Environments.stage:
      return 'InstrumentationKey=b0d25d06-f746-42ce-b719-2973ca4638e0;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/';
    case Environments.prod:
      return 'InstrumentationKey=64df454d-2a94-4150-9061-038568c62cc1;IngestionEndpoint=https://germanywestcentral-0.in.applicationinsights.azure.com/';
    case Environments.sandbox:
      return 'InstrumentationKey=94f594d4-86a7-4993-a4e8-abf51b74c842;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/';
    default:
      return 'InstrumentationKey=5af78a7c-d28e-46b8-8416-2788c77bdf8e;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/';
  }
};

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: getConnectionString(),
    enableAjaxErrorStatusText: true,
    enableCorsCorrelation: true,
    correlationHeaderDomains: getAllEnvironments(),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
