import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';

import HeadlineMenu from '../../../../components/HeadlineMenu';
import { WithPaymentMethod } from '../../WithPaymentMethod';
import { SetupPaymentMethodData } from '../../../../types/SetupPaymentMethodData';

const ManualDetailsMenu = ({ fromSuccess = false, values }: { fromSuccess?: boolean; values?: SetupPaymentMethodData }) => {
  const { t } = useTranslation('global');
  const { paymentMethodSetUpValues, validation } = useContext(WithPaymentMethod);

  const menuEntries = [
    {
      name: t('menu.account_holder'),
      value: values?.accountHolder ? values?.accountHolder : validation?.account_holder ? validation.account_holder : '',
    },
    {
      name: t('menu.iban'),
      value: IBAN.printFormat(values?.iban ? values?.iban : validation?.iban ? validation.iban : ''),
    },
    {
      name: t('menu.bic'),
      value: values?.bic ? values?.bic : validation?.bic ? validation.bic : '',
    },
  ];

  fromSuccess &&
    menuEntries.push(
      {
        name: t('menu.creditor_id'),
        value: paymentMethodSetUpValues.creditorIdentifier,
      },
      {
        name: t('menu.company_name'),
        value: paymentMethodSetUpValues.companyName,
      },
    );

  return <HeadlineMenu entries={menuEntries}></HeadlineMenu>;
};

export default ManualDetailsMenu;
