export interface CountryType {
  code: string;
  labelEn: string;
  labelDe: string;
  suggested?: boolean;
}

export const countries: CountryType[] = [
  { code: 'AD', labelEn: 'Andorra', labelDe: 'Andorra' },
  { code: 'AE', labelEn: 'United Arab Emirates', labelDe: 'Vereinigte Arabische Emirate' },
  { code: 'AF', labelEn: 'Afghanistan', labelDe: 'Afghanistan' },
  { code: 'AG', labelEn: 'Antigua and Barbuda', labelDe: 'Antigua und Barbuda' },
  { code: 'AI', labelEn: 'Anguilla', labelDe: 'Anguilla' },
  { code: 'AL', labelEn: 'Albania', labelDe: 'Albanien' },
  { code: 'AM', labelEn: 'Armenia', labelDe: 'Armenien' },
  { code: 'AO', labelEn: 'Angola', labelDe: 'Angola' },
  { code: 'AQ', labelEn: 'Antarctica', labelDe: 'Antarktis' },
  { code: 'AR', labelEn: 'Argentina', labelDe: 'Argentinien' },
  { code: 'AS', labelEn: 'American Samoa', labelDe: 'Amerikanisch-Samoa' },
  { code: 'AT', labelEn: 'Austria', labelDe: 'Österreich', suggested: true },
  { code: 'AU', labelEn: 'Australia', labelDe: 'Australien' },
  { code: 'AW', labelEn: 'Aruba', labelDe: 'Aruba' },
  { code: 'AX', labelEn: 'Alland Islands', labelDe: 'Alland-Inseln' },
  { code: 'AZ', labelEn: 'Azerbaijan', labelDe: 'Aserbaidschan' },
  { code: 'BA', labelEn: 'Bosnia and Herzegovina', labelDe: 'Bosnien und Herzegowina' },
  { code: 'BB', labelEn: 'Barbados', labelDe: 'Barbados' },
  { code: 'BD', labelEn: 'Bangladesh', labelDe: 'Bangladesch' },
  { code: 'BE', labelEn: 'Belgium', labelDe: 'Belgien' },
  { code: 'BF', labelEn: 'Burkina Faso', labelDe: 'Burkina Faso' },
  { code: 'BG', labelEn: 'Bulgaria', labelDe: 'Bulgarien' },
  { code: 'BH', labelEn: 'Bahrain', labelDe: 'Bahrain' },
  { code: 'BI', labelEn: 'Burundi', labelDe: 'Burundi' },
  { code: 'BJ', labelEn: 'Benin', labelDe: 'Benin' },
  { code: 'BL', labelEn: 'Saint Barthelemy', labelDe: 'St. Barthelemy' },
  { code: 'BM', labelEn: 'Bermuda', labelDe: 'Bermuda' },
  { code: 'BN', labelEn: 'Brunei Darussalam', labelDe: 'Brunei Darussalam' },
  { code: 'BO', labelEn: 'Bolivia', labelDe: 'Bolivien' },
  { code: 'BR', labelEn: 'Brazil', labelDe: 'Brasilien' },
  { code: 'BS', labelEn: 'Bahamas', labelDe: 'Bahamas' },
  { code: 'BT', labelEn: 'Bhutan', labelDe: 'Bhutan' },
  { code: 'BV', labelEn: 'Bouvet Island', labelDe: 'Bouvetinsel' },
  { code: 'BW', labelEn: 'Botswana', labelDe: 'Botswana' },
  { code: 'BY', labelEn: 'Belarus', labelDe: 'Weißrussland' },
  { code: 'BZ', labelEn: 'Belize', labelDe: 'Belize' },
  { code: 'CA', labelEn: 'Canada', labelDe: 'Kanada' },
  { code: 'CC', labelEn: 'Cocos (Keeling) Islands', labelDe: 'Kokosinseln (Keeling)' },
  { code: 'CD', labelEn: 'Congo, Democratic Republic of the', labelDe: 'Kongo, Demokratische Republik' },
  { code: 'CF', labelEn: 'Central African Republic', labelDe: 'Zentralafrikanische Republik' },
  { code: 'CG', labelEn: 'Congo, Republic of the', labelDe: 'Kongo, Republik' },
  { code: 'CH', labelEn: 'Switzerland', labelDe: 'Schweiz', suggested: true },
  { code: 'CI', labelEn: "Cote d'Ivoire", labelDe: "Côte d'Ivoire" },
  { code: 'CK', labelEn: 'Cook Islands', labelDe: 'Cookinseln' },
  { code: 'CL', labelEn: 'Chile', labelDe: 'Chile' },
  { code: 'CM', labelEn: 'Cameroon', labelDe: 'Kamerun' },
  { code: 'CN', labelEn: 'China', labelDe: 'China' },
  { code: 'CO', labelEn: 'Colombia', labelDe: 'Kolumbien' },
  { code: 'CR', labelEn: 'Costa Rica', labelDe: 'Costa Rica' },
  { code: 'CU', labelEn: 'Cuba', labelDe: 'Kuba' },
  { code: 'CV', labelEn: 'Cape Verde', labelDe: 'Kap Verde' },
  { code: 'CW', labelEn: 'Curacao', labelDe: 'Curacao' },
  { code: 'CX', labelEn: 'Christmas Island', labelDe: 'Weihnachtsinsel' },
  { code: 'CY', labelEn: 'Cyprus', labelDe: 'Zypern' },
  { code: 'CZ', labelEn: 'Czech Republic', labelDe: 'Tschechische Republik' },
  { code: 'DE', labelEn: 'Germany', labelDe: 'Deutschland', suggested: true },
  { code: 'DJ', labelEn: 'Djibouti', labelDe: 'Dschibuti' },
  { code: 'DK', labelEn: 'Denmark', labelDe: 'Dänemark' },
  { code: 'DM', labelEn: 'Dominica', labelDe: 'Dominica' },
  { code: 'DO', labelEn: 'Dominican Republic', labelDe: 'Dominikanische Republik' },
  { code: 'DZ', labelEn: 'Algeria', labelDe: 'Algerien' },
  { code: 'EC', labelEn: 'Ecuador', labelDe: 'Ecuador' },
  { code: 'EE', labelEn: 'Estonia', labelDe: 'Estland' },
  { code: 'EG', labelEn: 'Egypt', labelDe: 'Ägypten' },
  { code: 'EH', labelEn: 'Western Sahara', labelDe: 'Westsahara' },
  { code: 'ER', labelEn: 'Eritrea', labelDe: 'Eritrea' },
  { code: 'ES', labelEn: 'Spain', labelDe: 'Spanien' },
  { code: 'ET', labelEn: 'Ethiopia', labelDe: 'Äthiopien' },
  { code: 'FI', labelEn: 'Finland', labelDe: 'Finnland' },
  { code: 'FJ', labelEn: 'Fiji', labelDe: 'Fidschi' },
  { code: 'FK', labelEn: 'Falkland Islands (Malvinas)', labelDe: 'Falklandinseln (Malwinen)' },
  { code: 'FM', labelEn: 'Micronesia, Federated States of', labelDe: 'Mikronesien, Föderative Staaten von' },
  { code: 'FO', labelEn: 'Faroe Islands', labelDe: 'Färöer Inseln' },
  { code: 'FR', labelEn: 'France', labelDe: 'Frankreich' },
  { code: 'GA', labelEn: 'Gabon', labelDe: 'Gabun' },
  { code: 'GB', labelEn: 'United Kingdom', labelDe: 'Vereinigtes Königreich' },
  { code: 'GD', labelEn: 'Grenada', labelDe: 'Grenada' },
  { code: 'GE', labelEn: 'Georgia', labelDe: 'Georgien' },
  { code: 'GF', labelEn: 'French Guiana', labelDe: 'Französisch-Guayana' },
  { code: 'GG', labelEn: 'Guernsey', labelDe: 'Guernsey' },
  { code: 'GH', labelEn: 'Ghana', labelDe: 'Ghana' },
  { code: 'GI', labelEn: 'Gibraltar', labelDe: 'Gibraltar' },
  { code: 'GL', labelEn: 'Greenland', labelDe: 'Grönland' },
  { code: 'GM', labelEn: 'Gambia', labelDe: 'Gambia' },
  { code: 'GN', labelEn: 'Guinea', labelDe: 'Guinea' },
  { code: 'GP', labelEn: 'Guadeloupe', labelDe: 'Guadeloupe' },
  { code: 'GQ', labelEn: 'Equatorial Guinea', labelDe: 'Äquatorialguinea' },
  { code: 'GR', labelEn: 'Greece', labelDe: 'Griechenland' },
  { code: 'GS', labelEn: 'South Georgia and the South Sandwich Islands', labelDe: 'Südgeorgien und die Südlichen Sandwichinseln' },
  { code: 'GT', labelEn: 'Guatemala', labelDe: 'Guatemala' },
  { code: 'GU', labelEn: 'Guam', labelDe: 'Guam' },
  { code: 'GW', labelEn: 'Guinea-Bissau', labelDe: 'Guinea-Bissau' },
  { code: 'GY', labelEn: 'Guyana', labelDe: 'Guyana' },
  { code: 'HK', labelEn: 'Hong Kong', labelDe: 'Hongkong' },
  { code: 'HM', labelEn: 'Heard Island and McDonald Islands', labelDe: 'Heard-Insel und McDonald-Inseln' },
  { code: 'HN', labelEn: 'Honduras', labelDe: 'Honduras' },
  { code: 'HR', labelEn: 'Croatia', labelDe: 'Kroatien' },
  { code: 'HT', labelEn: 'Haiti', labelDe: 'Haiti' },
  { code: 'HU', labelEn: 'Hungary', labelDe: 'Ungarn' },
  { code: 'ID', labelEn: 'Indonesia', labelDe: 'Indonesien' },
  { code: 'IE', labelEn: 'Ireland', labelDe: 'Irland' },
  { code: 'IL', labelEn: 'Israel', labelDe: 'Israel' },
  { code: 'IM', labelEn: 'Isle of Man', labelDe: 'Isle of Man' },
  { code: 'IN', labelEn: 'India', labelDe: 'Indien' },
  { code: 'IO', labelEn: 'British Indian Ocean Territory', labelDe: 'Britisches Territorium im Indischen Ozean' },
  { code: 'IQ', labelEn: 'Iraq', labelDe: 'Irak' },
  { code: 'IR', labelEn: 'Iran, Islamic Republic of', labelDe: 'Iran, Islamische Republik' },
  { code: 'IS', labelEn: 'Iceland', labelDe: 'Island' },
  { code: 'IT', labelEn: 'Italy', labelDe: 'Italien' },
  { code: 'JE', labelEn: 'Jersey', labelDe: 'Jersey' },
  { code: 'JM', labelEn: 'Jamaica', labelDe: 'Jamaika' },
  { code: 'JO', labelEn: 'Jordan', labelDe: 'Jordanien' },
  { code: 'JP', labelEn: 'Japan', labelDe: 'Japan' },
  { code: 'KE', labelEn: 'Kenya', labelDe: 'Kenia' },
  { code: 'KG', labelEn: 'Kyrgyzstan', labelDe: 'Kirgisistan' },
  { code: 'KH', labelEn: 'Cambodia', labelDe: 'Kambodscha' },
  { code: 'KI', labelEn: 'Kiribati', labelDe: 'Kiribati' },
  { code: 'KM', labelEn: 'Comoros', labelDe: 'Komoren' },
  { code: 'KN', labelEn: 'Saint Kitts and Nevis', labelDe: 'St. Kitts und Nevis' },
  { code: 'KP', labelEn: "Korea, Democratic People's Republic of", labelDe: 'Korea, Demokratische Volksrepublik' },
  { code: 'KR', labelEn: 'Korea, Republic of', labelDe: 'Korea, Republik' },
  { code: 'KW', labelEn: 'Kuwait', labelDe: 'Kuwait' },
  { code: 'KY', labelEn: 'Cayman Islands', labelDe: 'Kaimaninseln' },
  { code: 'KZ', labelEn: 'Kazakhstan', labelDe: 'Kasachstan' },
  { code: 'LA', labelEn: "Lao People's Democratic Republic", labelDe: 'Laos, Demokratische Volksrepublik' },
  { code: 'LB', labelEn: 'Lebanon', labelDe: 'Libanon' },
  { code: 'LC', labelEn: 'Saint Lucia', labelDe: 'St. Lucia' },
  { code: 'LI', labelEn: 'Liechtenstein', labelDe: 'Liechtenstein' },
  { code: 'LK', labelEn: 'Sri Lanka', labelDe: 'Sri Lanka' },
  { code: 'LR', labelEn: 'Liberia', labelDe: 'Liberia' },
  { code: 'LS', labelEn: 'Lesotho', labelDe: 'Lesotho' },
  { code: 'LT', labelEn: 'Lithuania', labelDe: 'Litauen' },
  { code: 'LU', labelEn: 'Luxembourg', labelDe: 'Luxemburg' },
  { code: 'LV', labelEn: 'Latvia', labelDe: 'Lettland' },
  { code: 'LY', labelEn: 'Libya', labelDe: 'Libyen' },
  { code: 'MA', labelEn: 'Morocco', labelDe: 'Marokko' },
  { code: 'MC', labelEn: 'Monaco', labelDe: 'Monaco' },
  { code: 'MD', labelEn: 'Moldova, Republic of', labelDe: 'Moldawien, Republik Moldau' },
  { code: 'ME', labelEn: 'Montenegro', labelDe: 'Montenegro' },
  { code: 'MF', labelEn: 'Saint Martin (French part)', labelDe: 'St. Martin (französischer Teil)' },
  { code: 'MG', labelEn: 'Madagascar', labelDe: 'Madagaskar' },
  { code: 'MH', labelEn: 'Marshall Islands', labelDe: 'Marshallinseln' },
  { code: 'MK', labelEn: 'North Macedonia', labelDe: 'Nordmazedonien' },
  { code: 'ML', labelEn: 'Mali', labelDe: 'Mali' },
  { code: 'MM', labelEn: 'Myanmar', labelDe: 'Myanmar' },
  { code: 'MN', labelEn: 'Mongolia', labelDe: 'Mongolei' },
  { code: 'MO', labelEn: 'Macao', labelDe: 'Macao' },
  { code: 'MP', labelEn: 'Northern Mariana Islands', labelDe: 'Nördliche Marianen' },
  { code: 'MQ', labelEn: 'Martinique', labelDe: 'Martinique' },
  { code: 'MR', labelEn: 'Mauritania', labelDe: 'Mauretanien' },
  { code: 'MS', labelEn: 'Montserrat', labelDe: 'Montserrat' },
  { code: 'MT', labelEn: 'Malta', labelDe: 'Malta' },
  { code: 'MU', labelEn: 'Mauritius', labelDe: 'Mauritius' },
  { code: 'MV', labelEn: 'Maldives', labelDe: 'Malediven' },
  { code: 'MW', labelEn: 'Malawi', labelDe: 'Malawi' },
  { code: 'MX', labelEn: 'Mexico', labelDe: 'Mexiko' },
  { code: 'MY', labelEn: 'Malaysia', labelDe: 'Malaysia' },
  { code: 'MZ', labelEn: 'Mozambique', labelDe: 'Mosambik' },
  { code: 'NA', labelEn: 'Namibia', labelDe: 'Namibia' },
  { code: 'NC', labelEn: 'New Caledonia', labelDe: 'Neukaledonien' },
  { code: 'NE', labelEn: 'Niger', labelDe: 'Niger' },
  { code: 'NF', labelEn: 'Norfolk Island', labelDe: 'Norfolkinsel' },
  { code: 'NG', labelEn: 'Nigeria', labelDe: 'Nigeria' },
  { code: 'NI', labelEn: 'Nicaragua', labelDe: 'Nicaragua' },
  { code: 'NL', labelEn: 'Netherlands', labelDe: 'Niederlande' },
  { code: 'NO', labelEn: 'Norway', labelDe: 'Norwegen' },
  { code: 'NP', labelEn: 'Nepal', labelDe: 'Nepal' },
  { code: 'NR', labelEn: 'Nauru', labelDe: 'Nauru' },
  { code: 'NU', labelEn: 'Niue', labelDe: 'Niue' },
  { code: 'NZ', labelEn: 'New Zealand', labelDe: 'Neuseeland' },
  { code: 'OM', labelEn: 'Oman', labelDe: 'Oman' },
  { code: 'PA', labelEn: 'Panama', labelDe: 'Panama' },
  { code: 'PE', labelEn: 'Peru', labelDe: 'Peru' },
  { code: 'PF', labelEn: 'French Polynesia', labelDe: 'Französisch-Polynesien' },
  { code: 'PG', labelEn: 'Papua New Guinea', labelDe: 'Papua-Neuguinea' },
  { code: 'PH', labelEn: 'Philippines', labelDe: 'Philippinen' },
  { code: 'PK', labelEn: 'Pakistan', labelDe: 'Pakistan' },
  { code: 'PL', labelEn: 'Poland', labelDe: 'Polen' },
  { code: 'PM', labelEn: 'Saint Pierre and Miquelon', labelDe: 'St. Pierre und Miquelon' },
  { code: 'PN', labelEn: 'Pitcairn', labelDe: 'Pitcairn' },
  { code: 'PR', labelEn: 'Puerto Rico', labelDe: 'Puerto Rico' },
  { code: 'PS', labelEn: 'Palestine, State of', labelDe: 'Palästina, Staat' },
  { code: 'PT', labelEn: 'Portugal', labelDe: 'Portugal' },
  { code: 'PW', labelEn: 'Palau', labelDe: 'Palau' },
  { code: 'PY', labelEn: 'Paraguay', labelDe: 'Paraguay' },
  { code: 'QA', labelEn: 'Qatar', labelDe: 'Katar' },
  { code: 'RE', labelEn: 'Reunion', labelDe: 'Réunion' },
  { code: 'RO', labelEn: 'Romania', labelDe: 'Rumänien' },
  { code: 'RS', labelEn: 'Serbia', labelDe: 'Serbien' },
  { code: 'RU', labelEn: 'Russian Federation', labelDe: 'Russische Föderation' },
  { code: 'RW', labelEn: 'Rwanda', labelDe: 'Ruanda' },
  { code: 'SA', labelEn: 'Saudi Arabia', labelDe: 'Saudi-Arabien' },
  { code: 'SB', labelEn: 'Solomon Islands', labelDe: 'Salomoninseln' },
  { code: 'SC', labelEn: 'Seychelles', labelDe: 'Seychellen' },
  { code: 'SD', labelEn: 'Sudan', labelDe: 'Sudan' },
  { code: 'SE', labelEn: 'Sweden', labelDe: 'Schweden' },
  { code: 'SG', labelEn: 'Singapore', labelDe: 'Singapur' },
  { code: 'SH', labelEn: 'Saint Helena', labelDe: 'Sankt Helena' },
  { code: 'SI', labelEn: 'Slovenia', labelDe: 'Slowenien' },
  { code: 'SJ', labelEn: 'Svalbard and Jan Mayen', labelDe: 'Spitzbergen und Jan Mayen' },
  { code: 'SK', labelEn: 'Slovakia', labelDe: 'Slowakei' },
  { code: 'SL', labelEn: 'Sierra Leone', labelDe: 'Sierra Leone' },
  { code: 'SM', labelEn: 'San Marino', labelDe: 'San Marino' },
  { code: 'SN', labelEn: 'Senegal', labelDe: 'Senegal' },
  { code: 'SO', labelEn: 'Somalia', labelDe: 'Somalia' },
  { code: 'SR', labelEn: 'Suriname', labelDe: 'Surinam' },
  { code: 'SS', labelEn: 'South Sudan', labelDe: 'Südsudan' },
  { code: 'ST', labelEn: 'Sao Tome and Principe', labelDe: 'São Tomé und Príncipe' },
  { code: 'SV', labelEn: 'El Salvador', labelDe: 'El Salvador' },
  { code: 'SX', labelEn: 'Sint Maarten (Dutch part)', labelDe: 'Sint Maarten (Niederländischer Teil)' },
  { code: 'SY', labelEn: 'Syrian Arab Republic', labelDe: 'Syrien, Arabische Republik ' },
  { code: 'SZ', labelEn: 'Swaziland', labelDe: 'Swasiland' },
  { code: 'TC', labelEn: 'Turks and Caicos Islands', labelDe: 'Turks- und Caicosinseln' },
  { code: 'TD', labelEn: 'Chad', labelDe: 'Tschad ' },
  { code: 'TF', labelEn: 'French Southern Territories', labelDe: 'Französische Südpolarterritorien' },
  { code: 'TG', labelEn: 'Togo', labelDe: 'Togo' },
  { code: 'TH', labelEn: 'Thailand', labelDe: 'Thailand' },
  { code: 'TJ', labelEn: 'Tajikistan', labelDe: 'Tadschikistan' },
  { code: 'TK', labelEn: 'Tokelau', labelDe: 'Tokelau' },
  { code: 'TL', labelEn: 'Timor-Leste', labelDe: 'Timor-Leste' },
  { code: 'TM', labelEn: 'Turkmenistan', labelDe: 'Turkmenistan' },
  { code: 'TN', labelEn: 'Tunisia', labelDe: 'Tunesien' },
  { code: 'TO', labelEn: 'Tonga', labelDe: 'Tonga' },
  { code: 'TR', labelEn: 'Turkey', labelDe: 'Türkei' },
  { code: 'TT', labelEn: 'Trinidad and Tobago', labelDe: 'Trinidad und Tobago' },
  { code: 'TV', labelEn: 'Tuvalu', labelDe: 'Tuvalu' },
  { code: 'TW', labelEn: 'Taiwan, Republic of China', labelDe: 'Taiwan, Republik China' },
  { code: 'TZ', labelEn: 'United Republic of Tanzania', labelDe: 'Vereinigte Republik Tansania' },
  { code: 'UA', labelEn: 'Ukraine', labelDe: 'Ukraine' },
  { code: 'UG', labelEn: 'Uganda', labelDe: 'Uganda' },
  { code: 'US', labelEn: 'United States of America', labelDe: 'Vereinigte Staaten von Amerika' },
  { code: 'UY', labelEn: 'Uruguay', labelDe: 'Uruguay' },
  { code: 'UZ', labelEn: 'Uzbekistan', labelDe: 'Usbekistan' },
  { code: 'VA', labelEn: 'Holy See (Vatican City State)', labelDe: 'Vatikanstadt, Staat' },
  { code: 'VC', labelEn: 'Saint Vincent and the Grenadines', labelDe: 'St. Vincent und die Grenadinen' },
  { code: 'VE', labelEn: 'Venezuela', labelDe: 'Venezuela' },
  { code: 'VG', labelEn: 'British Virgin Islands', labelDe: 'Britische Jungferninseln' },
  { code: 'VI', labelEn: 'US Virgin Islands', labelDe: 'Amerikanische Jungferninseln' },
  { code: 'VN', labelEn: 'Vietnam', labelDe: 'Vietnam' },
  { code: 'VU', labelEn: 'Vanuatu', labelDe: 'Vanuatu' },
  { code: 'WF', labelEn: 'Wallis and Futuna', labelDe: 'Wallis und Futuna' },
  { code: 'WS', labelEn: 'Samoa', labelDe: 'Samoa' },
  { code: 'XK', labelEn: 'Kosovo', labelDe: 'Kosovo' },
  { code: 'YE', labelEn: 'Yemen', labelDe: 'Jemen' },
  { code: 'YT', labelEn: 'Mayotte', labelDe: 'Mayotte' },
  { code: 'ZA', labelEn: 'South Africa', labelDe: 'Südafrika' },
  { code: 'ZM', labelEn: 'Zambia', labelDe: 'Sambia' },
  { code: 'ZW', labelEn: 'Zimbabwe', labelDe: 'Simbabwe' },
];