import { Project } from './Project';
import { CompanyBrand } from './CompanyBrand';

export interface CheckoutInformation extends CompanyBrand {
  availablePaymentMethods: PaymentMethods;
  checkoutState: string;
  companyName: string;
  buttonLabel: ButtonLabel;
  generateInvoice: boolean;
  includeRedirectUrl: boolean;
  notifyCreditor: boolean;
  product: Product;
  customFields?: CustomField[];
  requireAddress: boolean;
  allowQuantityChange: boolean;
  subTotalCheckoutAmount: number;
  totalCheckoutAmount: number;
  totalTaxAmount: number;
  project: Project;
  socialCashbackCents: number;
  companyIban: string;
  creditorIdentifier: string;
  termsAndConditions?: TermsAndConditions;
  reverseChargeEnabled: boolean;
}

interface TermsAndConditions {
  termsAndConditionsUrl?: string;
  privacyPolicyUrl?: string;
}

enum PaymentMethods {
  ONLINE_PAYMENT = 'ONLINE_PAYMENT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}
interface Product {
  createdDateTimeUTC: string;
  description?: string;
  id: string;
  image?: {
    blobURL: string;
    name: string;
  };
  name: string;
  price: number;
  defaultDonationAmount: number;
  donationOptions?: {
    isEnabled: boolean;
    option1: number;
    option2: number;
    option3: number;
  };
  productType: ProductType;
  billingType: BillingType;
  recurringBillingPeriod: RecurringBillingPeriod;
  taxRate: { description: null; rate: number };
}

enum ButtonLabel {
  DONATE = 'DONATE',
  BUY = 'BUY',
  PAY = 'PAY',
  SUBSCRIBE = 'SUBSCRIBE',
}

export enum RecurringBillingPeriod {
  NONE = 'NONE',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}
enum BillingType {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export enum ProductType {
  DONATION = 'DONATION',
  PRODUCT = 'PRODUCT',
}
export type CustomField = {
  externalIdentifier: null;
  fieldIdentifier: string;
  fieldLabel: string;
  fieldType: 'DATE' | 'DROPDOWN' | 'TEXT';
  fieldValue: null | string | number;
  required: boolean;
};
