import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Link } from '@mui/material';
import { useContainerStyles } from '../../../components/Container';
import ManuallyUserForm from '../../OnboardingFlow/DirectDebit/Manual/ManuallyUserForm';

interface PayManuallyScreenProps {
  onBack: () => void;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
}

export default function PayManuallyScreen({ onBack, setFieldValue }: PayManuallyScreenProps) {
  const containerClasses = useContainerStyles();
  const { t } = useTranslation('global');

  return (
    <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
      <ManuallyUserForm updateFormikField={setFieldValue} />
      <div className={containerClasses.horizontalContainer}>
        <Link style={{ marginLeft: '1rem' }} color="secondary" type="button" onClick={onBack}>
          {t('links.come_back')}
        </Link>
        <Button
          icon={''}
          type="submit"
          style={{ width: 'fit-content' }}
          className={containerClasses.button}
          name="submit"
          label={t('buttons.to_the_summary')}
        />
      </div>
    </div>
  );
}
