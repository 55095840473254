import { CircularProgress, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';

type WithLoadingProps = {
  text?: string;
};

export default function LoadingContainer({ text }: WithLoadingProps) {
  return (
    <Fragment>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Fragment>
          <CircularProgress />
          <Typography>{text}</Typography>
        </Fragment>
      </Grid>
    </Fragment>
  );
}
