import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PaymentMethod } from '../../../types/PaymentMethod';
import { useContainerStyles } from '../../../components/Container';
import Dialog from '../../../components/Dialog';
import AvatarNumberList from '../../../components/AvatarNumberList';
import WithCheckout from '../WithCheckout';
import OtpVerifiedEmail from '../../../components/Otp/OtpVerifiedEmail';
import AdvantagesDirectDebitDialog from '../../../components/Payment/AdvantagesDirectDebitDialog';

const useStyles = makeStyles((theme) => ({
  gray: {
    color: theme.palette.secondary.main,
  },
  subtitle: {
    color: theme.palette.secondary.main,
    marginTop: '1rem',
  },
  titleContainer: {
    padding: '0.7rem',
  },
}));

export default function PreferManuallyScreen({ nextStep }: { nextStep: (method: PaymentMethod) => void }): ReactElement {
  const containerClasses = useContainerStyles();
  const { t } = useTranslation('global');
  const classes = useStyles();
  const { checkoutInformation } = useContext(WithCheckout);

  const [advantagesIsOpen, setAdvantagesIsOpen] = useState(false);
  const [procedureIsOpen, setProcedureIsOpen] = useState(false);

  const isDirectDebitAvailable = (): boolean => checkoutInformation.availablePaymentMethods?.includes(PaymentMethod.DIRECT_DEBIT);

  const listEntries = [
    {
      text: t('dialogs.online_payment_procedure1'),
    },
    {
      text: t('dialogs.online_payment_procedure2'),
    },
    {
      text: t('dialogs.online_payment_procedure3'),
    },
  ];

  return (
    <>
      <div className={[containerClasses.contentContainer, containerClasses.greyContainer, classes.titleContainer].join(' ')}>
        <Typography variant="h6">{t('checkout.select_payment')}</Typography>
        <OtpVerifiedEmail />
      </div>

      {checkoutInformation.creditorIdentifier !== 'DE28ZZZ00002647856' /* SE-1700 */ && (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Button
            color="primary"
            variant={'contained'}
            className={containerClasses.button}
            name="pay online"
            onClick={() => nextStep(PaymentMethod.ONLINE_PAYMENT)}
            size="medium"
            label={t('buttons.invoice_pay_online')}
          />
          <p>
            <Link
              color="secondary"
              onClick={() => {
                setProcedureIsOpen(true);
              }}
            >
              {t('links.online_payment_procedure')}
            </Link>
          </p>
          <Dialog
            title={t('links.online_payment_procedure')}
            xIcon={true}
            open={procedureIsOpen}
            close={() => {
              setProcedureIsOpen(false);
            }}
          >
            <AvatarNumberList listEntries={listEntries} />
          </Dialog>
        </div>
      )}

      <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
        {isDirectDebitAvailable() && (
          <>
            <Typography variant="body2" className={classes.subtitle}>
              {t('checkout.pay_direct_debit')}
            </Typography>
            <Button
              className={containerClasses.button}
              name="submit"
              variant={'outlined'}
              fullWidth
              label={t('buttons.pay_direct_debit')}
              icon="lock"
              onClick={() => nextStep(PaymentMethod.DIRECT_DEBIT)}
            />
            <p>
              <Link
                color="secondary"
                onClick={() => {
                  setAdvantagesIsOpen(true);
                }}
              >
                {t('links.advantages_of_direct_debit')}
              </Link>
            </p>
          </>
        )}
        <AdvantagesDirectDebitDialog
          isOpen={advantagesIsOpen}
          onClose={() => setAdvantagesIsOpen(false)}
          companyName={checkoutInformation.companyName}
        />
      </div>
    </>
  );
}
