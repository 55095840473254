import { AxiosInstance } from 'axios';
import { VerificationT } from '../types/VerificationT';
import { VerificationInformation } from '../types/VerificationInformation';
import ApiClient from './ApiClient';

export default class VerifyService {
  private static _instance: AxiosInstance;

  private static get verifyClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  public static async verify(verificationId: string, lastName: string): Promise<VerificationT | never> {
    let res = await this.verifyClient.post<VerificationT>(ApiClient.getApiDomain() + '/verify', {
      verificationId: verificationId,
      lastName: lastName,
    });

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(`VerifyService.verify failed: ${res.status}`);
    }
  }

  public static async verifyInit(verificationId: string): Promise<VerificationInformation | never> {
    let res = await this.verifyClient.get<VerificationInformation>(ApiClient.getApiDomain() + `/verify/_init?verificationId=${verificationId}`);

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(`VerifyService.verify failed: ${res.status}`);
    }
  }

  public static async updateProject(verificationId: string, projectId: string): Promise<boolean> {
    let res = await this.verifyClient({
      method: 'PATCH',
      url: ApiClient.getApiDomain() + `/verify/_setProject?verificationId=${verificationId}`,
      data: {
        projectId: projectId,
      },
    });

    return res.status === 200;
  }
}
