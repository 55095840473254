import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import { UIFFIF } from '../../components/UIFFIF';
import CountrySelect from '../../components/CountrySelect';
import { countries } from '../../types/Country';
import { Address } from '../../types/Address';
import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  input: {
    alignItems: 'flex-start',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export default function UserInformationForm({ from }: { from?: 'online' | 'direct' }) {
  const { t } = useTranslation('global');
  const classes = useStyles();
  const { values }: { values: { address?: Address } } = useFormikContext();
  const countryType = countries.find((country) => {
    const debtorCountry = values.address?.country;
    return (
      debtorCountry === country.code ||
      debtorCountry?.toLowerCase() === country.labelEn.toLowerCase() ||
      debtorCountry?.toLowerCase() === country.labelDe.toLowerCase()
    );
  });

  return (
    <Grid container justifyContent={'center'} alignItems={'stretch'} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <b>{t('user_form.title')}</b>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">{from === 'online' ? t('onboarding_online.subtitle') : t('onboarding_direct.subtitle')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <UIFFIF name="address.line" label={t('user_form.line')} classes={classes} />
      </Grid>
      <Grid item xs={12}>
        <UIFFIF name="address.suffix" label={t('user_form.suffix')} classes={classes} />
      </Grid>

      <Grid item xs={4}>
        <UIFFIF name="address.zipCode" label={t('user_form.post')} classes={classes} />
      </Grid>
      <Grid item xs={8}>
        <UIFFIF name="address.city" label={t('user_form.city')} classes={classes} />
      </Grid>

      <Grid item xs={12}>
        <CountrySelect name="address.country" label={t('user_form.country')} classes={classes} value={countryType} />
      </Grid>
    </Grid>
  );
}
