import AutoNumeric from 'autonumeric';
import { Fab } from '@mui/material';
import React from 'react';
import { useContainerStyles } from '../Container';

interface Props {
  amount: number;
  currencySymbol: string;
  onClick: () => void;
  className?: string;
}

export default function CurrencyFab({ amount, currencySymbol, onClick, className }: Props) {
  const containerClasses = useContainerStyles();

  const options = { currencySymbol: currencySymbol, ...AutoNumeric.getPredefinedOptions().commaDecimalCharDotSeparator };

  return (
    <Fab variant="extended" size={'small'} className={[containerClasses.button, className].join(' ')} color={'primary'} onClick={onClick}>
      {`${AutoNumeric.format(amount, options)}`}
    </Fab>
  );
}
