import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WithPayment from '../WithPayment';
import StatusIcon from '../../../components/StatusIcon';
import { Project } from '../../../types/Project';
import useErrorHandler from '../../Error/ErrorHandler';
import { ErrorCodes } from '../../Error/ErrorPage';
import PublicPaymentService from '../../../services/PublicPaymentService';
import AvatarNumberList from '../../../components/AvatarNumberList';
import SocialCashback from '../../../components/SocialCashback/SocialCashback';
import { PaymentState } from '../../../types/PaymentState';

const useStyles = makeStyles((theme) => ({
  avatarListContainer: {
    backgroundColor: theme.palette.background.default,
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
  },
}));

export default function ManualSuccessful(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const { paymentId, paymentInformation } = useContext(WithPayment);
  const handleGenericError = useErrorHandler();

  const listEntries = [
    {
      text: t('success.manual1'),
    },
    {
      text: t('success.manual2'),
    },
  ];

  const onSelectProject = (project: Project) => {
    PublicPaymentService.updateProject(paymentId, project.id).then((res) => {
      if (!res) {
        handleGenericError(new Error('PublicPaymentService.updateProject failed'), ErrorCodes.Project_update_failed);
      }
    });
  };

  return (
    <>
      {paymentInformation.state === PaymentState.COMPLETED ? (
        <>
          <StatusIcon variantIcon="up" variant="success" iconSize="medium" title={t('success.paid')}>
            <Typography variant="body2">{t('success.payment_paid_subtitle')}</Typography>
          </StatusIcon>
          <div className={classes.avatarListContainer}>
            <AvatarNumberList listEntries={listEntries} />
          </div>
        </>
      ) : paymentInformation.state === PaymentState.VERIFIED ? (
        <StatusIcon variantIcon="up" variant="success" iconSize="medium" title={t('success.verified')}>
          <Typography style={{ marginBottom: '2rem' }} variant="body2">
            {t('success.received_your_payment')}
          </Typography>
        </StatusIcon>
      ) : (
        <>
          <StatusIcon variantIcon="up" variant="success" iconSize="medium" title={t('success.payment_marked')}>
            <Typography variant="body2">{t('success.payment_marked_subtitle')}</Typography>
          </StatusIcon>
          <div className={classes.avatarListContainer}>
            <AvatarNumberList listEntries={listEntries} />
          </div>
        </>
      )}
      <SocialCashback
        socialCashbackCents={paymentInformation.socialCashbackCents}
        project={paymentInformation.project}
        onSelectProject={onSelectProject}
        variant="manual"
      />
    </>
  );
}
