import React, { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@payactive/app-common';
import { DialogContent, DialogContentText, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WithPaymentMethod } from './WithPaymentMethod';
import { PaymentMethod } from '../../types/PaymentMethod';
import { Containerized, useContainerStyles } from '../../components/Container';
import Dialog from '../../components/Dialog';
import AvatarNumberList from '../../components/AvatarNumberList';
import { WithProgress } from '../WithProgressContext';

const useStyles = makeStyles((theme) => ({
  gray: {
    color: theme.palette.secondary.main,
  },
  subtitle: {
    color: theme.palette.secondary.main,
    marginTop: '1rem',
  },
}));

export default function PaymentMethodSwitch({ updateContainer }: Containerized): ReactElement {
  const { paymentMethodSetUpValues, setupId, companyId } = useContext(WithPaymentMethod);

  const containerClasses = useContainerStyles();
  const { t } = useTranslation('global');
  const classes = useStyles();
  const navigate = useNavigate();
  const { updatesProgress } = useContext(WithProgress);

  const [advantagesIsOpen, setAdvantagesIsOpen] = React.useState(false);
  const [procedureIsOpen, setProcedureIsOpen] = React.useState(false);

  useEffect(() => {
    updatesProgress(20);
  }, [updatesProgress]);

  useEffect(() => {
    updateContainer({ title: <Typography variant="h6">{t('onboarding.setup_payment')}</Typography> });
  }, [updateContainer, t]);

  const listEntries = [
    {
      text: t('dialogs.online_payment_procedure1'),
    },
    {
      text: t('dialogs.online_payment_procedure2'),
    },
    {
      text: t('dialogs.online_payment_procedure3'),
    },
  ];

  const isDirectDebitAvailable = (): boolean => paymentMethodSetUpValues.availablePaymentMethods?.includes(PaymentMethod.DIRECT_DEBIT);

  return (
    <>
      <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
        <div>
          <Typography variant="h6">
            <b>
              Hi {paymentMethodSetUpValues.firstName} {paymentMethodSetUpValues.lastName},
            </b>
          </Typography>
          <Typography variant="body2">{t('onboarding.how_would_you_pay', { companyName: 'payactive' })}</Typography>

          {isDirectDebitAvailable() && (
            <>
              <Typography variant="body2" className={classes.subtitle}>
                {t('onboarding.setup_once')}
              </Typography>
              <Button
                className={containerClasses.button}
                name="submit"
                fullWidth
                label={t('buttons.setup_direct_debit')}
                icon="lock"
                onClick={() => navigate(`/payment/${companyId}/payment-method-setup/${setupId}/directDebit`)}
              />
              <p>
                <Link
                  color="secondary"
                  onClick={() => {
                    setAdvantagesIsOpen(true);
                  }}
                >
                  {t('links.advantages_of_direct_debit')}
                </Link>
              </p>
            </>
          )}

          <Dialog
            title={t('links.advantages_of_direct_debit')}
            xIcon={true}
            close={() => {
              setAdvantagesIsOpen(false);
            }}
            open={advantagesIsOpen}
          >
            <DialogContent>
              <DialogContentText variant="body2">
                {t('dialogs.the_one_time_setup', { companyName: paymentMethodSetUpValues.companyName })}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
        <Typography variant="body2" className={classes.gray}>
          {t('onboarding.by_payment_request')}
        </Typography>
        <Button
          color="primary"
          variant={isDirectDebitAvailable() ? 'outlined' : 'contained'}
          className={containerClasses.button}
          name="pay online"
          onClick={() => navigate(`/payment/${companyId}/payment-method-setup/${setupId}/online`)}
          size="medium"
          label={t('buttons.invoice_pay_online')}
        />
        <p>
          <Link
            color="secondary"
            onClick={() => {
              setProcedureIsOpen(true);
            }}
          >
            {t('links.online_payment_procedure')}
          </Link>
        </p>
        <Dialog
          title={t('links.online_payment_procedure')}
          xIcon={true}
          open={procedureIsOpen}
          close={() => {
            setProcedureIsOpen(false);
          }}
        >
          <AvatarNumberList listEntries={listEntries} />
        </Dialog>
      </div>
    </>
  );
}
