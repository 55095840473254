import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import VerificationForm from '../VerificationForm';
import Container, { useContainerStyles } from '../../../components/Container';
import Dialog from '../../../components/Dialog';
import AvatarNumberList from '../../../components/AvatarNumberList';
import { WithProgress } from '../../WithProgressContext';

type VerificationStartProps = {
  onSubmit: (verificationId: string, lastName: string) => void;
};

const VerificationStart = ({ onSubmit }: VerificationStartProps) => {
  const containerClasses = useContainerStyles();
  const [open, setOpen] = React.useState(false);

  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    updatesProgress(33);
  }, [updatesProgress]);

  const { t } = useTranslation('global');

  const listEntries = [
    {
      text: t('verification.how_works1'),
    },
    {
      text: t('verification.how_works2'),
    },
    {
      text: t('verification.how_works3'),
    },
  ];

  return (
    <>
      <Container title={<Typography variant="h6">{t('verification.verify_payment')}</Typography>} bigIcon={true}>
        <>
          <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
            <Typography variant="h6">{t('verification.title')}</Typography>
            <Typography variant="body2">{t('verification.subtitle')}</Typography>
            <VerificationForm onSubmit={onSubmit} />
            <p>
              <Link
                onClick={() => {
                  setOpen(true);
                }}
                color="secondary"
              >
                {t('links.verify_payment_link')}
              </Link>
            </p>
          </div>
          <Dialog
            xIcon={true}
            title={t('links.verify_payment_link')}
            close={() => {
              setOpen(false);
            }}
            open={open}
          >
            <AvatarNumberList listEntries={listEntries} />
          </Dialog>
        </>
      </Container>
    </>
  );
};

export default VerificationStart;
