import React, { useState } from 'react';
import { FormikInputField } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { IMaskInput } from 'react-imask';
import IbanMasks, { IbanMask } from './IbanMasks';

const useStyles = makeStyles((theme) => ({
  input: {
    alignItems: 'flex-start',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  inputField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: string;
}
const IbanMaskedField = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const [matchedMasks, setMatchedMasks] = useState<Map<string, any>>(new Map<string, any>());
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={IbanMasks}
      placeholderChar="_"
      lazy={false}
      name="iban"
      inputRef={ref as any}
      onAccept={(value: any) => {
        return onChange({ target: { name: 'iban', value } });
      }}
      dispatch={function (appended, dynamicMasked) {
        const input = dynamicMasked.value + appended;
        const number = input.length > 1 ? input.substring(0, 2) : '__';
        if (matchedMasks.has(number)) {
          return matchedMasks.get(number);
        } else {
          const mask = dynamicMasked.compiledMasks.find(function (m) {
            return number.indexOf((m as unknown as IbanMask).startsWith) === 0;
          });
          setMatchedMasks(matchedMasks.set(number, mask));
          return mask;
        }
      }}
    />
  );
});

export default function ManuallyUserForm({
  updateFormikField,
}: {
  updateFormikField: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}) {
  const { t } = useTranslation('global');
  const classes = useStyles();

  const [iban, setIban] = useState<string>();

  const handleIbanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedIban = event.target.value;
    updatedIban = updatedIban.replace(/\s/g, '');
    setIban(updatedIban);
    updateFormikField && updateFormikField('iban', updatedIban);
  };

  return (
    <div>
      <div>
        <UIFFIF
          className={classes.inputField}
          name="accountHolder"
          label={t('menu.account_holder')}
          onChange={(e) => updateFormikField('accountHolder', e.target.value)}
        />
        <UIFFIF
          className={classes.inputField}
          name="iban"
          label={t('IBAN')}
          value={iban}
          onChange={handleIbanChange}
          InputProps={
            !!updateFormikField
              ? {
                  inputComponent: IbanMaskedField as any,
                }
              : undefined
          }
        />
        <UIFFIF className={classes.inputField} name="bic" label={t('menu.bic')} onChange={(e) => updateFormikField('bic', e.target.value)} />
      </div>
    </div>
  );
}
const UIFFIF = ({
  disabled = false,
  name,
  label,
  className,
  value,
  InputProps,
  onChange,
}: {
  disabled?: boolean;
  name: string;
  label: string;
  className: string;
  value?: string;
  InputProps?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const classes = useStyles();
  return (
    <FormikInputField
      className={[className, classes.input].join(' ')}
      disabled={disabled}
      name={name}
      value={value}
      InputProps={InputProps}
      label={label}
      onChange={onChange}
      required
      fullWidth
    />
  );
};
