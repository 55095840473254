import { useTranslation } from 'react-i18next';
import { useContainerStyles } from '../../components/Container';
import React, { useEffect, useState } from 'react';
import SubscriptionService from '../../services/SubscriptionService';
import { useMatch } from 'react-router-dom';
import { Badge, Chip, CircularProgress, Grid, Typography } from '@mui/material';
import { Subscription, Subscriptions } from '../../types/Subscription/Subscription';
import PriceSummary from '../CheckoutFlow/PriceSummary';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@payactive/app-common';
import RequestCancellationDialog from './RequestCancellationDialog';
import { HourglassEmpty } from '@mui/icons-material';
import { formatDate } from '../../utils/DateFormatUtils';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '12px 0',
    padding: '10px 0',
  },
  priceSummary: {
    padding: '5px 0 0 0',
  },
}));

export default function DebtorPortalOverview() {
  const { t } = useTranslation();
  const containerClasses = useContainerStyles();
  const match = useMatch('/payment/subscriptions/:companyAccountId/*');
  const companyAccountId = match?.params.companyAccountId;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [subscriptions, setSubscriptions] = useState<any>();
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] = useState(false);

  const loadSubscriptions = () => {
    if (companyAccountId) {
      setIsLoading(true);
      SubscriptionService.getSubscriptions(companyAccountId, 100)
        .then(({ subscriptions }) => setSubscriptions(subscriptions))
        .catch(() => setError(t('error.error')))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  return (
    <>
      {isLoading ? (
        <Grid
          className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}
          container
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : error ? (
        <Grid container className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Grid item>{error}</Grid>
        </Grid>
      ) : !subscriptions || subscriptions.length === 0 ? (
        <Grid container className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Grid item>{t('customer_portal.no_active_subscriptions')}</Grid>
        </Grid>
      ) : (
        subscriptions.map((subscription: Subscription, idx: number) => {
          return (
            <Grid
              className={[classes.box, containerClasses.greyContainer].join(' ')}
              container
              item
              alignItems={'center'}
              justifyContent={'center'}
              direction={'column'}
              key={subscription.id}
            >
              <Grid item>
                <Typography variant={'h6'}>{subscription.subscriptionTitle}</Typography>
              </Grid>
              <Grid item style={{ margin: '0 0 -0.5rem' }}>
                <Typography variant={'caption'} component={'span'}>
                  {t('customer_portal.subscribed_on')}&nbsp;
                  {formatDate(subscription.createdDateTime)}
                </Typography>
              </Grid>
              <PriceSummary className={classes.priceSummary} totalPrice={subscription.price} recurring={subscription.interval} tax={0} />
              {subscription.status === Subscriptions.Status.CANCELLATION_REQUESTED ? (
                <Grid item>
                  <Badge>
                    <Chip label={t('customer_portal.cancellation_pending')} color="info" size="small" icon={<HourglassEmpty />} />
                  </Badge>
                </Grid>
              ) : (
                <>
                  <Button
                    name={t('customer_portal.request_cancellation')}
                    className={containerClasses.button}
                    onClick={() => setIsCancellationDialogOpen(true)}
                  />
                  <RequestCancellationDialog
                    isOpen={isCancellationDialogOpen}
                    onClose={() => {
                      setIsCancellationDialogOpen(false);
                      loadSubscriptions();
                    }}
                    companyAccountId={companyAccountId ? companyAccountId : ''} // FIXME: cover the case when there is no company account id
                    subscriptionId={subscription.id}
                  />
                </>
              )}
            </Grid>
          );
        })
      )}
    </>
  );
}
