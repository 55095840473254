import React, { ReactElement, useContext, useEffect, useImperativeHandle, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, LinearProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Footer from './Footer';
import { WithCompanyBrand } from './CustomerThemeProvider/CustomerThemeProvider';
import PayactiveLogo from '../components/CustomerThemeProvider/payactive.png';
import { WithProgress } from '../pages/WithProgressContext';

const useStyles = makeStyles((theme) => ({
  progress: {
    height: '5px',
  },
  progressBarBackground: {
    backgroundColor: '#E6E6E6',
  },
  container: {
    margin: '0 auto',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '375px',
      minHeight: '712px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      minHeight: '100vh',
    },
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    paddingBottom: '120px',
  },

  innerContainer: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },

  smallLogo: {
    width: 'calc(100% - 8rem)',
    height: '4em',
    margin: '0 4rem 0.3rem',
  },
  bigLogo: {
    width: '100%',
    height: '7em',
    marginBottom: '0.3rem',
  },
  logo: {
    backgroundImage: `url(${PayactiveLogo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'block',
    transitionProperty: 'width, margin, height',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '0.3s',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  titleContainer: {
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    backgroundColor: '#F2F2F2',
    padding: '5px 15px',
    alignItems: 'center',
  },
  titleContainerCentered: {
    placeContent: 'center',
  },
  contentContainer: {
    backgroundColor: '#F2F2F2',
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
    padding: '25px 15px',
    textAlign: 'center',
    display: 'grid',
  },
  menu: {
    transition: 'max-height 0.5s ease-in-out',
    height: 'auto',
    overflow: 'hidden',
    maxHeight: '0px',
  },
  menuActive: {
    maxHeight: '260px',
    overflowY: 'auto',
  },
}));

const useContainerStyles = makeStyles((theme) => ({
  button: {
    maxWidth: '70%',
    justifySelf: 'center',
    margin: '0.5rem 0',
  },
  w50: {
    width: '50%',
  },
  greyContainer: {
    backgroundColor: theme.palette.background.default,
  },
  whiteContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  horizontalContainer: {
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionContainer: {
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  contentContainer: {
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
    padding: '25px 15px',
    textAlign: 'center',
    display: 'grid',
    fontSize: 'smaller',
    '& .pa-container': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  contentContainerFlexible: {
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
    padding: '25px 15px',
    fontSize: 'smaller',
  },
  outerContainer: {
    padding: '0 15px',
  },
}));

type ContainerProps = {
  title?: ReactElement;
  menu?: ReactElement;
  hideExpand?: boolean;
  menuOpen?: boolean;
  children?: ReactElement;
  bigIcon?: boolean;
};

export type Containerized = {
  updateContainer: (props: ContainerProps) => void;
};

export interface ContainerReference {
  setProps: (newProps: ContainerProps) => void;
}

export const ContainerRef = React.forwardRef<ContainerReference, ContainerProps>((props, ref) => {
  const [customProps, setCustomProps] = useState<ContainerProps>();

  useImperativeHandle(ref, () => ({
    setProps(newProps) {
      setCustomProps(newProps);
    },
  }));

  return <Container {...props} {...customProps} />;
});

export default function Container({ title, menu, children, bigIcon = false, menuOpen = false, hideExpand = false }: ContainerProps) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(menuOpen);
  const { companyLogoUrl } = useContext(WithCompanyBrand);

  useEffect(() => {
    //menu is closed and new value for menu
    if (!isOpen && menuOpen) {
      setIsOpen(menuOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  return (
    <div className={classes.container}>
      <>
        <WithProgress.Consumer>
          {({ currentProgress }) => (
            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={currentProgress}
              classes={{
                colorPrimary: classes.progressBarBackground,
              }}
            />
          )}
        </WithProgress.Consumer>
        <div className={classes.innerContainer}>
          <div
            className={[bigIcon ? classes.bigLogo : classes.smallLogo, classes.logo].join(' ')}
            style={{ backgroundImage: `url(${companyLogoUrl})` }}
          />

          {title && (
            <>
              <div
                className={classes.titleContainer + ' ' + (menu ? '' : classes.titleContainerCentered)}
                onClick={() => !hideExpand && setIsOpen(!isOpen)}
              >
                {title}
                {menu &&
                  !hideExpand &&
                  (isOpen ? (
                    <IconButton>
                      <KeyboardArrowUpIcon />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  ))}
              </div>
              <div className={isOpen ? [classes.menu, classes.menuActive].join(' ') : classes.menu}>{menu}</div>
            </>
          )}

          {children}
        </div>
      </>
      <Footer className={classes.footer} />
    </div>
  );
}

export { useContainerStyles };
