import { useTranslation } from 'react-i18next';
import React from 'react';
import PaymentOption from './PaymentOption';
import { useNavigate } from 'react-router-dom';
import { PaymentFlowPaths } from '../../pages/PaymentFlow/PaymentFlow';

type Props = {
  paymentFlowId: string;
};

export default function ManualPaymentOption({ paymentFlowId }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const paymentPath = `/payment/${paymentFlowId}`;

  return (
    <PaymentOption
      optionDescription={t('resolution.manual_payment.description')}
      buttonLabel={t('resolution.manual_payment.button_label')}
      isPrimary={false}
      onClick={() => navigate(paymentPath + PaymentFlowPaths.manual)}
    />
  );
}
