import React from 'react';
import PaginationDot from './PaginationDot';

const Pagination = ({
  dots,
  index,
  onChangeIndex,
  className,
}: {
  dots: number;
  index: number;
  onChangeIndex: (index: number) => void;
  className: string;
}) => {
  const children = [];

  for (let i = 0; i < dots; i += 1) {
    children.push(
      <PaginationDot
        key={i}
        index={i}
        active={i === index}
        onClick={(index) => {
          onChangeIndex(index);
        }}
      />,
    );
  }

  return <div className={className}>{children}</div>;
};
export default Pagination;
