import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 18,
    width: 18,
    cursor: 'pointer',
    border: 0,
    background: 'none',
    padding: 0,
  },
  dot: {
    backgroundColor: theme.palette.background.paper,
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: 3,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const PaginationDot = ({ active, index, onClick }: { active: boolean; index: number; onClick: (index: number) => void }) => {
  let styleDot;
  const classes = useStyles();
  if (active) {
    styleDot = [classes.dot, classes.active].join(' ');
  } else {
    styleDot = classes.dot;
  }

  return (
    <button type="button" className={classes.root} data-index={index} onClick={() => onClick(index)}>
      <div className={styleDot} />
    </button>
  );
};

export default PaginationDot;
