import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { useContainerStyles } from '../Container';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@payactive/app-common';

const useStyles = makeStyles(() => ({
  button: {
    margin: '15px 0 0 0',
  },
  childrenPadding: {
    padding: '15px 0 0 0',
  },
}));

type Props = {
  optionDescription: string;
  buttonLabel: string;
  onClick: () => void;
  isPrimary?: boolean;
  children?: ReactElement | ReactElement[];
};

export default function PaymentOption({ optionDescription, buttonLabel, onClick, isPrimary = true, children }: Props) {
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  let optionStyles = [containerClasses.contentContainerFlexible];
  if (isPrimary) optionStyles.push(containerClasses.greyContainer);

  return (
    <Grid item container direction={'column'} flexWrap={'nowrap'} alignItems={'center'} className={optionStyles.join(' ')}>
      <Grid item>
        <Typography variant={'body2'}>{optionDescription}</Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant={isPrimary ? 'contained' : 'outlined'}
          className={classes.button}
          name="pay online"
          onClick={onClick}
          size="medium"
          label={buttonLabel}
        />
      </Grid>
      <Grid item className={classes.childrenPadding}>
        {children}
      </Grid>
    </Grid>
  );
}
