import { createContext, ReactElement } from 'react';
import { WithCompanyBrandProvider } from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import { CheckoutInformation } from '../../types/CheckoutInformation';

type WithCheckoutT = {
  paylinkExternalId: string;
  restartPath: string;
  checkoutInformation: CheckoutInformation;
  reloadCheckoutInformation: () => Promise<any>;
};

const WithCheckout = createContext<WithCheckoutT>({
  paylinkExternalId: '',
  restartPath: '',
  checkoutInformation: {} as CheckoutInformation,
  reloadCheckoutInformation: () => Promise.reject(),
});

export const WithCheckoutBrandedProvider = ({ value, children }: { value: WithCheckoutT; children: ReactElement }) => {
  return (
    <WithCheckout.Provider value={value}>
      <WithCompanyBrandProvider companyBrand={value.checkoutInformation}>{children}</WithCompanyBrandProvider>
    </WithCheckout.Provider>
  );
};

export default WithCheckout;
