import { createContext } from 'react';
import { VerificationT } from '../../types/VerificationT';

type WithVerifiedInfoT = {
  verifiedInfo: VerificationT;
  restartPath: string;
};

const WithVerifiedInfo = createContext<WithVerifiedInfoT>({
  verifiedInfo: {} as VerificationT,
  restartPath: '',
});

export default WithVerifiedInfo;
