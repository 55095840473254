import React, { ReactElement, useContext, useState } from 'react';
import { Link, Typography } from '@mui/material';
import { useContainerStyles } from '../../components/Container';
import StatusIcon from '../../components/StatusIcon';
import useErrorHandler from '../Error/ErrorHandler';
import { ErrorCodes } from '../Error/ErrorPage';
import { Project } from '../../types/Project';
import SocialCashback from '../../components/SocialCashback/SocialCashback';
import LoadingButton from '../../components/LoadingButton';
import WithCheckout from './WithCheckout';
import AdvantagesOnlinePayment from '../../global/AdvantagesOnlinePayment';
import Dialog from '../../components/Dialog';
import { PaymentMethod } from '../../types/PaymentMethod';
import { DoCheckout } from '../../types/DoCheckout';
import { useTranslation } from 'react-i18next';
import { WithCustomerData } from './WithCustomerData';
import { useMatch } from 'react-router-dom';
import PublicCheckoutService from '../../services/PublicCheckoutService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function SuccessCheckout({
  paymentMethod,
  responseSubmit,
}: {
  paymentMethod: string;
  responseSubmit: DoCheckout | undefined;
}): ReactElement {
  const [openProsOnlinePayment, setOpenProsOnlinePayment] = useState(false);

  const { t } = useTranslation('global');
  const containerClasses = useContainerStyles();
  const handleGenericError = useErrorHandler();
  const { checkoutInformation } = useContext(WithCheckout);
  useContext(WithCustomerData).clearSessionStorage();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const match = useMatch('/payment/checkout/:paylinkExternalId/*');
  const paylinkExternalId = match?.params.paylinkExternalId;

  const isSubscription = checkoutInformation.product.billingType === 'RECURRING';

  const onSelectProject = (project: Project) => {
    if (executeRecaptcha && !!project.id && !!responseSubmit?.debitorEmailAddress && !!paylinkExternalId) {
      executeRecaptcha('otpChallenge').then(
        (token) => {
          PublicCheckoutService.updateProject(paylinkExternalId, responseSubmit?.debitorEmailAddress, project.id, token).then((res) => {
            if (!res) {
              handleGenericError(new Error('PublicPaymentService.updateProject failed'), ErrorCodes.Project_update_failed);
            }
          });
        },
        (e) => {
          handleGenericError(new Error('PublicPaymentService.updateProject failed'), ErrorCodes.Project_update_failed);
        },
      );
    }
  };

  const redirect = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (checkoutInformation.includeRedirectUrl && responseSubmit) {
      window.location.assign(responseSubmit.redirectUrl);
    }
  };

  return (
    <>
      <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
        <StatusIcon
          variantIcon="up"
          variant="success"
          iconSize="large"
          title={isSubscription ? t('success.checkout_subscription_title') : t('success.checkout_title')}
        >
          <Typography variant="body2" style={{ marginBottom: '2rem' }}>
            {isSubscription
              ? t('success.checkout_subscription_subtitle', { email: localStorage.getItem('emailAddress') })
              : t('success.checkout_subtitle', { email: localStorage.getItem('emailAddress') })}
          </Typography>
        </StatusIcon>
      </div>
      {paymentMethod === PaymentMethod.ONLINE_PAYMENT && !isSubscription ? (
        <div>
          <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
            <Typography variant="body2">{t('online_payment.safe_and_easy')}</Typography>
            <LoadingButton style={{ width: 'fit-content' }} className={containerClasses.button} loading={false}>
              {t('buttons.directly_to_payment')}
            </LoadingButton>
            <Link
              color="secondary"
              onClick={() => {
                setOpenProsOnlinePayment(true);
              }}
            >
              {t('links.init_payment')}
            </Link>
            <Dialog
              title={t('dialogs.advantages_online_payment_title')}
              xIcon={true}
              open={openProsOnlinePayment}
              close={() => {
                setOpenProsOnlinePayment(false);
              }}
            >
              <AdvantagesOnlinePayment />
            </Dialog>
          </div>
          {checkoutInformation.includeRedirectUrl && (
            <div style={{ textAlign: 'center' }}>
              <Link underline="always" component="button" color="secondary" onClick={redirect}>
                {t('checkout.redirect_url')} {checkoutInformation.companyName}
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
          <SocialCashback onSelectProject={onSelectProject} variant="onboarding" />
          {checkoutInformation.includeRedirectUrl && (
            <div style={{ marginTop: '1rem' }}>
              <Link underline="always" component="button" color="secondary" onClick={redirect}>
                {t('checkout.redirect_url')} {checkoutInformation.companyName}
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
}
