import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Container, { useContainerStyles } from '../../../components/Container';
import StatusIcon from '../../../components/StatusIcon';
import { WithProgress } from '../../WithProgressContext';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emailStyle: {
    color: '#262626',
  },
}));

const VerificationError = ({ back }: { back: () => void }) => {
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    updatesProgress(100);
  }, [updatesProgress]);

  return (
    <>
      <Container title={<Typography variant="h6">{t('verification.verify_payment')}</Typography>}>
        <>
          <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
            <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
              <StatusIcon title={t('verification.failed')} variant="failed" variantIcon="down" iconSize="large">
                <Typography className={classes.spacing} variant="body2">
                  {t('verification.cannot_be_verified')}
                </Typography>
                <Button name="come back" label={t('buttons.back')} onClick={back}></Button>
                <Typography className={classes.spacing} variant="body2">
                  {t('verification.error_message')}
                  <a className={classes.emailStyle} href="mailto:help@payactive.eu">
                    help@payactive.eu
                  </a>
                </Typography>
              </StatusIcon>
            </div>
          </div>
        </>
      </Container>
    </>
  );
};

export default VerificationError;
