import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@payactive/app-common';
import { Link, Typography } from '@mui/material';
import ManualData from './ManualData';
import ManualInstructions from './ManualInstructions';
import ManualReason from './ManualReason';
import WithPayment from '../WithPayment';
import { PaymentFlowPaths } from '../PaymentFlow';
import { ErrorCodes } from '../../Error/ErrorPage';
import useErrorHandler from '../../Error/ErrorHandler';
import Dialog from '../../../components/Dialog';
import { Containerized, useContainerStyles } from '../../../components/Container';
import PublicPaymentService from '../../../services/PublicPaymentService';
import { WithProgress } from '../../WithProgressContext';

export default function ManualPayment({ updateContainer }: Containerized) {
  const containerClasses = useContainerStyles();
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const location = useLocation();

  enum STEPS {
    INSTRUCTIONS = 1,
    REASON = 2,
    DATA = 3,
    SUCCESSFUL = 4,
  }

  useEffect(() => {
    updateContainer({ bigIcon: false });
  }, [updateContainer]);

  const [currentStep, setCurrentStep] = useState(STEPS.DATA);
  const [isOpen, setIsOpen] = React.useState(false);

  const { paymentId, restartPath, reloadPaymentInformation } = useContext(WithPayment);

  const handleGenericError = useErrorHandler();

  const { updatesProgress } = useContext(WithProgress);

  const retryOnlinePayment = () => {
    PublicPaymentService.restartPayment(paymentId).then((res) => {
      if (res) {
        reloadPaymentInformation()
          .then(() => {
            navigate(restartPath);
          })
          .catch(() => {
            handleGenericError(new Error('PublicPaymentService.reloadPayment failed'), ErrorCodes.Payment_get_failed);
          });
      } else {
        handleGenericError(new Error('PublicPaymentService.restartPayment failed'), ErrorCodes.Payment_restart_failed);
      }
    });
  };

  const onSubmit = () => {
    PublicPaymentService.completeManually(paymentId).then((res) => {
      if (res) {
        reloadPaymentInformation();
      } else {
        handleGenericError(new Error('PublicPaymentService.completeManually failed'), ErrorCodes.Payment_manually_failed);
      }
    });
  };

  const nextButton = () => {
    if (currentStep === STEPS.DATA) {
      setIsOpen(true);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  useEffect(() => {
    switch (currentStep) {
      case STEPS.INSTRUCTIONS:
        updatesProgress(30);
        break;
      case STEPS.REASON:
        updatesProgress(45);
        break;
      case STEPS.DATA:
        updatesProgress(65);
        break;
      default:
    }
  }, [currentStep, updatesProgress, STEPS.INSTRUCTIONS, STEPS.REASON, STEPS.DATA]);

  const getContentByStep = (): JSX.Element | undefined => {
    switch (currentStep) {
      case STEPS.INSTRUCTIONS:
        return <ManualInstructions />;
      case STEPS.REASON:
        return <ManualReason currentStep={currentStep} setCurrentStep={setCurrentStep} paymentId={paymentId} />;
      case STEPS.DATA:
        return <ManualData />;
      case STEPS.SUCCESSFUL:
        return <Navigate to={`${restartPath}${PaymentFlowPaths.success}?f=m`} />;
      default:
    }
  };

  return (
    <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
      <>
        {getContentByStep()}
        {(currentStep === STEPS.INSTRUCTIONS || currentStep === STEPS.DATA) && (
          <div className={containerClasses.horizontalContainer}>
            <Link
              style={{ marginLeft: '1rem' }}
              color="secondary"
              type="button"
              onClick={() => {
                if (currentStep === 1) {
                  // email redirect detection - not very reliable, but all we got for now... :(
                  if (window.history.state && window.history.state.idx === 1) {
                    navigate(restartPath);
                  } else if (location.key !== 'default') {
                    navigate(-1);
                  } else {
                    navigate(restartPath);
                  }
                } else {
                  setCurrentStep(currentStep - 1);
                }
              }}
            >
              {t('links.come_back')}
            </Link>
            <Button
              onClick={() => nextButton()}
              style={{ width: 'fit-content' }}
              className={containerClasses.button}
              name="submit"
              label={currentStep === STEPS.INSTRUCTIONS ? t('buttons.specify_reason') : t('buttons.transferred_manually')}
            />
          </div>
        )}

        {currentStep === STEPS.DATA && (
          <div className={containerClasses.horizontalContainer}>
            <Link
              style={{ marginLeft: '1rem' }}
              color="secondary"
              type="button"
              onClick={() => {
                retryOnlinePayment();
              }}
            >
              {t('links.pay_online_better')}
            </Link>
          </div>
        )}

        <Dialog
          title={t('dialogs.payment_manual_title')}
          close={() => {
            setIsOpen(false);
          }}
          open={isOpen}
        >
          <>
            <Typography variant="body2">{t('dialogs.payment_manual_text')}</Typography>
            <div className={containerClasses.horizontalContainer}>
              <Link style={{ marginRight: '1rem' }} color="secondary" type="button" onClick={() => setIsOpen(false)}>
                {t('links.come_back')}
              </Link>
              <Button
                onClick={() => {
                  onSubmit();
                  setCurrentStep(currentStep + 1);
                  setIsOpen(false);
                }}
                style={{ width: 'fit-content' }}
                className={containerClasses.button}
                name="submit"
                label={t('buttons.yes_i_have')}
              />
            </div>
          </>
        </Dialog>
      </>
    </div>
  );
}
