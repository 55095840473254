import React, { useContext } from 'react';
import WithCheckout from './WithCheckout';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import CurrencyTextField from '../../components/CurrencyTextField';
import { makeStyles } from '@mui/styles';
import CurrencyFab from '../../components/SocialCashback/CurrencyFab';

const useStyles = makeStyles((theme) => ({
  donationOption: {
    margin: '5px 10px',
  },
}));

export default function FlexiblePriceSummary() {
  const { values, setFieldValue } = useFormikContext<any>();
  const [t] = useTranslation();
  const classes = useStyles();

  const { checkoutInformation } = useContext(WithCheckout);
  const { product } = checkoutInformation;

  return (
    <>
      <Grid container direction={'column'}>
        {product.donationOptions?.isEnabled && (
          <>
            <Grid item>
              <Typography variant={'subtitle1'} style={{ margin: '0 0 0.5rem' }}>
                {t('checkout.pick_a_donation_amount')}
              </Typography>
            </Grid>
            <Grid item>
              {checkoutInformation.product.donationOptions && (
                <>
                  <CurrencyFab
                    amount={checkoutInformation.product.donationOptions?.option1}
                    currencySymbol={'€'}
                    onClick={() => setFieldValue('donationAmount', checkoutInformation.product.donationOptions?.option1)}
                    className={classes.donationOption}
                  />

                  <CurrencyFab
                    amount={checkoutInformation.product.donationOptions?.option2}
                    currencySymbol={'€'}
                    onClick={() => setFieldValue('donationAmount', checkoutInformation.product.donationOptions?.option2)}
                    className={classes.donationOption}
                  />

                  <CurrencyFab
                    amount={checkoutInformation.product.donationOptions?.option3}
                    currencySymbol={'€'}
                    onClick={() => setFieldValue('donationAmount', checkoutInformation.product.donationOptions?.option3)}
                    className={classes.donationOption}
                  />
                </>
              )}
            </Grid>
          </>
        )}
        <Grid item style={{ maxWidth: '95%' }}>
          <CurrencyTextField
            name={'donationAmount'}
            label={t('checkout.donation_amount')}
            currencySymbol={'€'}
            value={values.donationAmount}
            required
            fullWidth
          />
        </Grid>
        <Grid item>
          <Typography variant={'body2'} component={'span'}>
            &nbsp;
            {t(`checkout.${checkoutInformation.product.recurringBillingPeriod}`)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
