import React, { ReactElement } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

export default function AdvantagesOnlinePayment(): ReactElement {
  const { t } = useTranslation('global');

  const proEntries = [
    t('dialogs.advantages_online_payment1'),
    t('dialogs.advantages_online_payment2'),
    t('dialogs.advantages_online_payment3'),
    <>
      <b>Social Cashback: </b>
      {t('dialogs.advantages_online_payment4')}
    </>,
  ];
  return (
    <List>
      {proEntries.map((entry, index) => {
        return (
          <ListItem key={'pros' + index}>
            <ListItemIcon style={{ minWidth: '30px', alignSelf: 'baseline', marginTop: '0.4rem' }}>
              <CheckCircleIcon style={{ color: '#20B39D', fontSize: '1.25rem' }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{entry}</ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}
