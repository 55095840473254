import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({
  listText: {
    textAlign: 'left',
    marginLeft: '1rem',
    marginBottom: '0px',
  },
  avatar: {
    fontSize: '0.9rem',
    fontWeight: '700',
    height: '1.25rem',
    width: '1.25rem',
    marginTop: '0.5rem',
    alignSelf: 'baseline',
  },
  avatarColor: {
    backgroundColor: 'black',
  },
  listTextColor: {
    color: theme.palette.secondary.main,
  },
  avatarColorSC: {
    backgroundColor: 'white',
    color: '#0198B5',
  },
  listTextColorSC: {
    color: 'white',
  },
}));

type AvatarNumberListProps = {
  listEntries: Array<{
    text: string;
  }>;
  socialCashback?: boolean;
};

export default function AvatarNumberList({ listEntries, socialCashback = false }: AvatarNumberListProps) {
  const classes = useStyles();

  return (
    <List>
      {listEntries.map((entry, index) => {
        return (
          <ListItem key={'al'+index}>
            <Avatar className={`${classes.avatar}  ${socialCashback ? classes.avatarColorSC : classes.avatarColor}`}>{index + 1}</Avatar>
            <ListItemText
              primaryTypographyProps={{ variant: 'body2' }}
              className={`${classes.listText}  ${socialCashback ? classes.listTextColorSC : classes.listTextColor}`}
            >
              {entry.text}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}
