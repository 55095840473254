import { Dialog } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ProjectCard from './ProjectCard';
import { Project } from '../../types/Project';
import useErrorHandler from '../../pages/Error/ErrorHandler';
import { ErrorCodes } from '../../pages/Error/ErrorPage';
import PublicProjectService from '../../services/PublicProjectService';
import Pagination from './Pagination';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const divWidth = window.innerWidth > 880 ? 880 : window.innerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-elevation': {
      backgroundColor: 'transparent',
      margin: '0',
      borderRadius: '0',
      boxShadow: 'none',
      width: '100%',
      height: '510px',
      [theme.breakpoints.up('sm')]: {
        width: '858px',
        maxWidth: '858px',
      },
    },
  },
  swipeContainer: {
    padding: '0 ' + (window.innerWidth - 300) / 2 + 'px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 ' + (window.innerWidth - 300) / 2 + 'px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 ' + (divWidth - 300) / 2 + 'px',
    },
  },
  close: {
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  pagination: {
    position: 'absolute',
    bottom: '50px',
    display: 'flex',
    flexDirection: 'row',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ProjectChooser = ({
  open,
  onSelect,
  onClose,
  currentSelectedProject,
}: {
  open: boolean;
  onSelect: (project: Project) => void;
  onClose: () => void;
  currentSelectedProject: Project;
}) => {
  const [index, setIndex] = useState(1);
  const classes = useStyles();
  const [projects, setProjects] = useState<Project[]>([] as Project[]);

  const { t } = useTranslation('global');
  const handleGenericError = useErrorHandler();
  const styles = {
    slideActive: {
      transform: 'scale(1)',
      transition: ' all 0.3s',
    },
    slideInactive: {
      transform: 'scale(0.9)',
      transition: ' all 0.3s',
    },
  };

  useEffect(() => {
    PublicProjectService.fetchProjects()
      .then((projects: Project[]) => {
        projects.forEach((v, i) => {
          if (currentSelectedProject.id === v.id) {
            setIndex(i);
          }
        });
        setProjects(projects);
      })
      .catch(() => {
        handleGenericError(new Error('PublicProjectService.fetchProjects failed'), ErrorCodes.Project_fetch_failed);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const select = (i: number, close?: boolean) => {
    setIndex(i);
    if (close) {
      onSelect(projects[i]);
    }
  };
  const cancelSelection = () => {
    onSelect(currentSelectedProject);
  };

  if (projects.length === 0) {
    return <></>;
  }

  return (
    <Dialog open={open} className={classes.root} onBackdropClick={() => onClose()}>
      <SwipeableViews className={classes.swipeContainer} enableMouseEvents={true} index={index} onChangeIndex={(index) => setIndex(index)}>
        {projects.map((project, i) => (
          <ProjectCard key={'p' + i} style={index === i ? styles.slideActive : styles.slideInactive} project={project} index={i} onSelect={select} />
        ))}
      </SwipeableViews>
      <Pagination className={classes.pagination} dots={projects.length} index={index} onChangeIndex={select} />
      <Button variant="contained" className={classes.close} onClick={cancelSelection}>
        {t('buttons.cancel_project_chooser')}
      </Button>
    </Dialog>
  );
};

export default ProjectChooser;
