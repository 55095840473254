import React, { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import loadingSpinner from '../components/assets/spinner.gif';
import lock from '../components/assets/lock.svg';

const useStyle = makeStyles((_) => ({
  loadingSpinner: {
    position: 'absolute',
    width: '64px',
    height: '64px',
    top: '0',
    left: '0',
  },
  iconContainer: { position: 'relative', margin: 'auto', width: '64px', height: '64px' },
  lockIcon: { position: 'absolute', top: '20px', left: '24px' },
}));

export default function LoadingSpinnerDiv(): ReactElement {
  const classes = useStyle();

  return (
    <div className={classes.iconContainer}>
      <img src={loadingSpinner} className={classes.loadingSpinner} alt={'loading'}></img>
      <img src={lock} className={classes.lockIcon} alt={'lock'}></img>
    </div>
  );
}
