import { createContext, ReactElement, useEffect, useState } from 'react';
import { PaymentInformation } from '../../types/PaymentInformation';
import { WithCompanyBrandProvider } from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import PublicPaymentService from '../../services/PublicPaymentService';
import useErrorHandler from '../Error/ErrorHandler';
import { ErrorCodes } from '../Error/ErrorPage';
import LoadingContainer from '../../components/LoadingContainer';

type WithPaymentT = {
  paymentId: string;
  restartPath: string;
  paymentInformation: PaymentInformation;
  reloadPaymentInformation: () => Promise<any>;
};

const WithPayment = createContext<WithPaymentT>({
  paymentId: '',
  restartPath: '',
  paymentInformation: {} as PaymentInformation,
  reloadPaymentInformation: () => Promise.reject(),
});

export const WithPaymentBrandedProvider = ({ value, children }: { value: WithPaymentT; children: ReactElement }) => {
  return (
    <WithPayment.Provider value={value}>
      <WithCompanyBrandProvider companyBrand={value.paymentInformation}>{children}</WithCompanyBrandProvider>
    </WithPayment.Provider>
  );
};

export const WithPaymentFromVerificationIdProvider = ({
  verificationId,
  restartPath,
  children,
}: {
  verificationId: string;
  restartPath: string;
  children: ReactElement;
}) => {
  const [paymentInformation, setPaymentInformation] = useState<PaymentInformation>();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    PublicPaymentService.getPaymentInformationByVerificationId(verificationId)
      .then(setPaymentInformation)
      .catch((reason) => errorHandler(reason, ErrorCodes.Payment_information_not_found));
  }, [verificationId]);

  if (!paymentInformation) {
    return <LoadingContainer />;
  }

  return (
    <WithPayment.Provider
      value={{ paymentId: paymentInformation?.paymentFlowId, paymentInformation, reloadPaymentInformation: () => Promise.reject(), restartPath }}
    >
      {children}
    </WithPayment.Provider>
  );
};

export default WithPayment;
