export enum CancelReason {
  OTHER = 'OTHER',
  NO_WILL_TO_PAY_BILL_ONLINE = 'NO_WILL_TO_PAY_BILL_ONLINE',
  NO_WILL_TO_ENTER_BANK_DETAILS = 'NO_WILL_TO_ENTER_BANK_DETAILS',
  ALREADY_PAID = 'ALREADY_PAID',
}

export function getCancelReason(value: string): CancelReason {
  return Object.values(CancelReason).find((x) => x === value) || CancelReason.OTHER;
}
