import { Project } from '../../types/Project';
import PublicProjectService from '../../services/PublicProjectService';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

const selectProject = async (exclude?: Project): Promise<Project> => {
  let project = undefined;
  do {
    let projects = await PublicProjectService.fetchProjects();
    project = projects[getRandomInt(projects.length)];
  } while (exclude === project);

  return project;
};

export { selectProject };
