import { FormikInputField } from '@payactive/app-common';
import React from 'react';

export const UIFFIF = ({
  disabled = false,
  name,
  label,
  className,
  required = false,
  classes,
}: {
  disabled?: boolean;
  required?: boolean;
  name: string;
  label: string;
  className?: string;
  classes: { input: string };
}) => {
  return (
    <FormikInputField className={[className, classes.input].join(' ')} disabled={disabled} name={name} label={label} required={required} fullWidth />
  );
};
