import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import VerifyService from '../../services/VerifyService';
import useErrorHandler from '../Error/ErrorHandler';
import CustomerThemeProvider, { WithCompanyBrand } from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import { VerificationInformation } from '../../types/VerificationInformation';
import SocialCashback from '../../components/SocialCashback/SocialCashback';
import Container, { useContainerStyles } from '../../components/Container';
import WithVerificationId from '../VerificationFlow/WithVerificationId';
import { Project } from '../../types/Project';
import { ErrorCodes } from '../Error/ErrorPage';
import LoadingContainer from '../../components/LoadingContainer';

export default function SocialCashbackFlow() {
  const match = useMatch('/payment/socialCashback/:verificationId');

  const containerClasses = useContainerStyles();
  const [verificationInformation, setVerificationInformation] = useState<VerificationInformation | undefined>();

  const handleGenericError = useErrorHandler();

  const verificationId = match?.params.verificationId;
  useEffect(() => {
    if (verificationId)
      VerifyService.verifyInit(verificationId)
        .then((verificationInformation) => {
          setVerificationInformation(verificationInformation);
        })
        .catch(() => {
          handleGenericError(new Error('VerificationService.init failed'), ErrorCodes.Verification_not_found);
        });
  }, [verificationId]);

  const onSelectProject = (project: Project) => {
    if (!verificationId) {
      return;
    }
    VerifyService.updateProject(verificationId, project.id).then((res) => {
      if (!res) {
        handleGenericError(new Error('VerificationService.updateProject failed'), ErrorCodes.Project_update_failed);
      }
    });
  };

  if (!verificationInformation) {
    return <LoadingContainer />;
  }

  return (
    <WithVerificationId.Provider value={{ verificationId: verificationId }}>
      <WithCompanyBrand.Provider value={verificationInformation}>
        <CustomerThemeProvider>
          <Container>
            <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
              <SocialCashback
                socialCashbackCents={verificationInformation.socialCashbackCents}
                project={verificationInformation.project}
                onSelectProject={onSelectProject}
                variant="onlinePayment"
              />
            </div>
          </Container>
        </CustomerThemeProvider>
      </WithCompanyBrand.Provider>
    </WithVerificationId.Provider>
  );
}
