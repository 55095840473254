import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';
import { Subscription } from '../types/Subscription/Subscription';
import { Page, PageMetadata } from '../types/Page';

export default class SubscriptionService {
  private static _instance: AxiosInstance;
  private static _apiDomain = ApiClient.getApiDomain();

  private static get subscriptionClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  public static async getSubscriptions(
    companyAccountId: string,
    size: number = 5,
    page?: number,
    sort?: string,
  ): Promise<{ subscriptions: Subscription[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: sort,
    };

    let res = await this.subscriptionClient.get<Page<Subscription, 'subscriptions'>>(`${this._apiDomain}/public/subscriptions/${companyAccountId}`, {
      params,
    });

    return { subscriptions: res.data._embedded?.subscriptions || [], page: res.data.page };
  }

  public static async requestCancellation(
    companyAccountId: string,
    subscriptionId: string,
    cancellationType: string,
    reason: string,
    targetDate: Date,
  ): Promise<void> {
    let payload = {
      type: cancellationType,
      reason,
      targetDate,
    };

    let res = await this.subscriptionClient.post<void>(
      `${this._apiDomain}/public/subscriptions/${companyAccountId}/${subscriptionId}/_requestCancellation`,
      payload,
    );
  }
}
