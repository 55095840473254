import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Alert, CircularProgress, Link, Typography } from '@mui/material';
import { Button as ButtonAppCommon, FormikInputField } from '@payactive/app-common';
import { makeStyles } from '@mui/styles';
import { useContainerStyles } from '../Container';
import Dialog from '../Dialog';
import { useTranslation } from 'react-i18next';
import OtpService from '../../services/OtpService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, FormikValues, useFormikContext } from 'formik';
import { OTPContext } from './OtpContextProvider';

const useStyles = makeStyles((theme) => ({
  input: {
    alignItems: 'flex-start',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type FormValues = FormikValues & { verificationCode: string };

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  emailAddress: string;
};

export default function OtpVerificationDialog({ isOpen, onClose, onSuccess, emailAddress }: Props): ReactElement {
  const { setAccessData } = useContext(OTPContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [challengeId, setChallengeId] = useState<string>('');
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      (async () => {
        if (executeRecaptcha) {
          const token = await executeRecaptcha('otpChallenge');
          const { success, challenge } = await OtpService.createChallenge(emailAddress, token);
          if (success && challenge) {
            setChallengeId(challenge.challengeId);
          } else {
            setHasError(true);
          }
          setIsLoading(false);
        }
      })();
    }
  }, [isOpen]);

  const validateOtp = async (values: FormValues) => {
    setIsLoading(true);
    if (executeRecaptcha) {
      const token = await executeRecaptcha('validateOtpChallenge');
      const { success, accessToken } = await OtpService.verifyChallenge(challengeId, emailAddress, token, values.verificationCode);
      if (success && accessToken) {
        setAccessData(accessToken, emailAddress);
        onSuccess();
      } else {
        setHasError(true);
      }
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      title={t('checkout.enter_code')}
      open={isOpen}
      close={() => {
        onClose();
        setHasError(false);
      }}
      alert={
        /* TODO: handle different errors */
        !isLoading && hasError ? (
          <Alert severity="error">
            <Typography>{t('error.otp_invalid')}</Typography>
          </Alert>
        ) : undefined
      }
    >
      <>
        {isLoading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && (
          <>
            <Form>
              <Typography style={{ marginBottom: '1rem' }} variant="body2">
                {t('checkout.enter_code_subtitle')}
              </Typography>
              <FormikInputField name="verificationCode" label={'Code'} required fullWidth className={classes.input} />
              <div className={containerClasses.actionContainer}>
                <Link color="secondary" type="button" onClick={onClose}>
                  {t('links.come_back')}
                </Link>
                <ButtonAppCommon
                  style={{ width: 'fit-content', marginLeft: '2rem' }}
                  className={containerClasses.button}
                  name="submit"
                  label={t('buttons.verify')}
                  onClick={() => validateOtp(values)}
                />
              </div>
            </Form>
          </>
        )}
      </>
    </Dialog>
  );
}
