import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PaymentOption from './PaymentOption';
import { Link } from '@mui/material';
import AdvantagesOnlinePaymentDialog from './AdvantagesOnlinePaymentDialog';
import { PaymentFlowPaths } from '../../pages/PaymentFlow/PaymentFlow';
import { useNavigate } from 'react-router-dom';

type Props = {
  paymentFlowId: string;
};

export default function OnlinePaymentOption({ paymentFlowId }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAdvantagesOpen, setIsAdvantagesOpen] = useState(false);

  const paymentPath = `/payment/${paymentFlowId}`;

  return (
    <>
      <PaymentOption
        optionDescription={t('resolution.online_payment.description')}
        buttonLabel={t('resolution.online_payment.button_label')}
        onClick={() => navigate(paymentPath + PaymentFlowPaths.online)}
      >
        <Link
          color="secondary"
          onClick={(event) => {
            event.stopPropagation();
            setIsAdvantagesOpen(true);
          }}
        >
          {t('links.init_payment')}
        </Link>
      </PaymentOption>
      <AdvantagesOnlinePaymentDialog isOpen={isAdvantagesOpen} onClose={() => setIsAdvantagesOpen(false)} />
    </>
  );
}
