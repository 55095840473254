import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import ManualSuccessful from './PaymentFlow/ManualPayment/ManualSuccessful';
import YourPaymentTitle from './PaymentFlow/YourPaymentTitle';
import { Containerized, useContainerStyles } from '../components/Container';
import OnlineSuccessful from './PaymentFlow/OnlinePayment/OnlineSuccessful';
import OnboardingSuccessful from './OnboardingFlow/OnboardingSuccessful';
import DirectDebitDetailsMenu from './OnboardingFlow/DirectDebit/DirectDebitDetailsMenu';
import ManualDetailsMenu from './OnboardingFlow/DirectDebit/Manual/ManuallyDetailsMenu';
import { WithProgress } from './WithProgressContext';
import PaymentDetailsMenu from '../components/Payment/PaymentDetailsMenu';

const useStyles = makeStyles((theme) => ({
  greyColor: {
    color: theme.palette.secondary.main,
  },
}));

export default function SuccessScreen({ updateContainer }: Containerized) {
  const from = new URLSearchParams(window.location.search).get('f');
  //from: 'o' (online) | 'm' (manual) | 'bo' (onboarding online) | 'bd' (onboarding direct debit) | 'bdm' (onboarding direct debit manual)

  const { t } = useTranslation('global');
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    const title =
      from === 'bd' || from === 'bdm' ? (
        <Typography variant="h6">{t('success.your_debit_mandate')}</Typography>
      ) : from === 'bo' ? (
        <Typography variant="h6">{t('success.your_online_payments')}</Typography>
      ) : (
        <YourPaymentTitle />
      );

    const menu =
      from === 'bd' ? (
        <DirectDebitDetailsMenu fromSuccess={true} />
      ) : from === 'bdm' ? (
        <ManualDetailsMenu fromSuccess={true} />
      ) : from === 'bo' ? undefined : (
        <PaymentDetailsMenu />
      );

    updateContainer({ title: title, menu: menu, bigIcon: false, menuOpen: true });
  }, [updateContainer, from, t]);

  useEffect(() => {
    updatesProgress(100);
  }, [updatesProgress]);

  return (
    <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
      {from === 'm' ? <ManualSuccessful /> : from === 'o' ? <OnlineSuccessful /> : <OnboardingSuccessful variant={from && from} />}

      <div style={{ marginTop: '1rem' }}>
        <Typography className={classes.greyColor} variant="h6">
          {t('success.all_done')}
        </Typography>
        <Typography className={classes.greyColor} variant="body2">
          {t('success.close_the_window')}
        </Typography>
      </div>
    </div>
  );
}
