import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';

export default class OtpService {
  private static _instance: AxiosInstance;
  private static _apiDomain = ApiClient.getApiDomain();

  private static get otpClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  public static async createChallenge(emailAddress: string, token: string): Promise<{ success: boolean; challenge?: { challengeId: string } }> {
    try {
      let res = await this.otpClient.post(this._apiDomain + '/public/otp/_create', { emailAddress: emailAddress, token: token });
      if (res.status === 201) {
        return { success: true, challenge: res.data };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }

  public static async verifyChallenge(
    challengeId: string,
    emailAddress: string,
    token: string,
    code: string,
  ): Promise<{ success: boolean; accessToken?: string; error?: unknown }> {
    try {
      let res = await this.otpClient.post<{ accessToken: string }>(this._apiDomain + '/public/otp/' + challengeId + '/_verify', {
        challengeId: challengeId,
        emailAddress: emailAddress,
        token: token,
        code: code,
      });
      if (res.status === 200) {
        return { success: true, accessToken: res.data.accessToken };
      }
      return { success: false };
    } catch (error) {
      return { success: false, error };
    }
  }

  public static async verifyToken(recaptchaToken: string, accessToken: string): Promise<{ isValid: boolean; error?: unknown }> {
    try {
      let res = await this.otpClient.post(this._apiDomain + '/public/otp/_verify-token', {
        recaptchaToken,
        accessToken,
      });
      if (res.status === 200) {
        return { isValid: true };
      }
      return { isValid: false };
    } catch (error) {
      return { isValid: false, error };
    }
  }
}
