import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, Link, MenuItem, TextField, Typography } from '@mui/material';
import { CancelReason, getCancelReason } from './CancelReason';
import LoadingButton from '../../../components/LoadingButton';
import PublicPaymentService from '../../../services/PublicPaymentService';
import useErrorHandler from '../../Error/ErrorHandler';
import { ErrorCodes } from '../../Error/ErrorPage';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    justifySelf: 'center',
    width: '100%',
    textAlign: 'left',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

type ManualReasonProps = {
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  paymentId: string;
};

const CUSTOM_REASON_MAX_LENGTH = 200

export default function ManualReason({ currentStep, setCurrentStep, paymentId }: ManualReasonProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('global');

  const [reason, setReason] = useState<CancelReason>();
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGenericError = useErrorHandler();

  const REASONS = [
    {
      code: CancelReason.NO_WILL_TO_ENTER_BANK_DETAILS,
      reason: t('manual.cancel_reason1'),
    },
    {
      code: CancelReason.NO_WILL_TO_PAY_BILL_ONLINE,
      reason: t('manual.cancel_reason2'),
    },
    {
      code: CancelReason.ALREADY_PAID,
      reason: t('manual.cancel_reason3'),
    },
    {
      code: CancelReason.OTHER,
      reason: t('manual.cancel_reason4'),
    },
  ];

  const onSubmit = () => {
    setLoading(true);
    PublicPaymentService.cancelPayment(paymentId, reason !== undefined ? reason : CancelReason.OTHER, otherReason).then((res) => {
      setLoading(false);
      if (res) {
        setCurrentStep(currentStep + 1);
      } else {
        handleGenericError(new Error(), ErrorCodes.Payment_cancel_failed);
      }
    });
  };

  return (
    <>
      <Typography variant="h6">{t('manual.reason_title')}</Typography>
      <Typography variant="body2" style={{ marginBottom: '1rem' }}>
        {t('manual.reason_subtitle')}
      </Typography>

      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          select
          value={reason}
          label={t('manual.manual_reason_label')}
          onChange={(event) => setReason(getCancelReason(event.target.value as string))}
        >
          {REASONS.map((reason) => (
            <MenuItem value={reason.code}>{reason.reason}</MenuItem>
          ))}
        </TextField>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          margin="normal"
          value={otherReason}
          label={t('manual.comment_label')}
          onChange={(e) => setOtherReason(e.target.value)}
          required={reason === CancelReason.OTHER}
          inputProps={{ maxLength: CUSTOM_REASON_MAX_LENGTH }}
          helperText={`${otherReason.length} / ${CUSTOM_REASON_MAX_LENGTH}`}
        />
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ marginLeft: '1rem' }}>
          <Link color="secondary" type="button" onClick={() => setCurrentStep(currentStep - 1)}>
            {t('links.come_back')}
          </Link>
        </p>
        <LoadingButton
          disabled={reason === undefined || (reason === CancelReason.OTHER && otherReason.length === 0)}
          loading={loading}
          onClick={onSubmit}
        >
          {t('buttons.show_payment_data')}
        </LoadingButton>
      </div>
    </>
  );
}
