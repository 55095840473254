import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { Environments } from './Environments';

export default class ApiClient {
  private static _instance: AxiosInstance;
  private static _instanceNoRetry: AxiosInstance;
  private static _instanceHardRetry: AxiosInstance;

  public static get client(): AxiosInstance {
    return this._instance || (this._instance = this.create());
  }

  public static get clientNoRetry(): AxiosInstance {
    return this._instanceNoRetry || (this._instanceNoRetry = this.create(false, false));
  }

  public static get clientHardRetry(): AxiosInstance {
    return this._instanceHardRetry || (this._instanceHardRetry = this.create(true));
  }

  private static create(hardRetry = false, retry = true): AxiosInstance {
    const instance = axios.create();
    instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (!!config.headers) {
          config.headers['Accept'] = 'application/json';
          config.headers['Content-Type'] = 'application/json';
          let accessToken = localStorage.getItem('accessToken');
          if (accessToken) config.headers['x-payactive-access-token'] = accessToken;
        }
        return config;
      },
      (error) => {
        Promise.reject(error).then((r) => {
          return r;
        });
      },
    );
    if (retry) {
      if (hardRetry) {
        axiosRetry(instance, { retries: 3, retryCondition: (_error) => true, shouldResetTimeout: true });
      } else {
        axiosRetry(instance, { retries: 3 });
      }
    }
    return instance;
  }

  public static getApiDomain(): string {
    if (process.env.REACT_APP_API_DOMAIN) {
      return process.env.REACT_APP_API_DOMAIN;
    }

    switch (window.location.hostname) {
      case Environments.local:
        return 'http://localhost:8080';
      case Environments.dev:
        return 'https://dev.api.payactive.io';
      case Environments.stage:
        return 'https://stage.api.payactive.io';
      case Environments.prod:
        return 'https://api.payactive.io';
      case Environments.sandbox:
        return 'https://sandbox.api.payactive.io';
      default:
        return 'https://api.payactive.io';
    }
  }

  public static handleError(error: AxiosError<{ message: string }>): Error {
    if (error.response) {
      return new Error(error.response.data.message);
    } else if (error.request) {
      return new Error('Network problem');
    } else {
      return new Error('Unknown');
    }
  }
}
