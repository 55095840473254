import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((_) => ({
  wrapper: {
    position: 'relative',
    width: 'fit-content',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

type LoadingButtonProp = {
  icon?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  loading: boolean;
  style?: React.CSSProperties;
  className?: string;
};

export default function LoadingButton(props: LoadingButtonProp) {
  const classes = useStyles();
  const { icon, children, disabled, onClick, loading, style, className } = props;

  const [_loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className={[classes.wrapper, className].join(' ')}>
      <Button
        style={style}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={disabled || _loading}
        onClick={onClick}
        startIcon={icon && <Icon>{icon}</Icon>}
      >
        {children}
      </Button>
      {_loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}
