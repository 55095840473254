import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Link, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import UserInformationForm from '../UserInformationForm';
import { schema } from '../Schemas';
import PublicPaymentService from '../../../services/PublicPaymentService';
import { PaymentMethod } from '../../../types/PaymentMethod';
import { WithPaymentMethod } from '../WithPaymentMethod';
import { Button } from '@payactive/app-common';
import { Containerized, useContainerStyles } from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../components/Dialog';
import useErrorHandler from '../../Error/ErrorHandler';
import { WithProgress } from '../../WithProgressContext';
import { SetupPaymentMethodData } from '../../../types/SetupPaymentMethodData';

export default function OnlinePayment({ updateContainer }: Containerized): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const { setupId, paymentMethodSetUpValues, restartPath } = useContext(WithPaymentMethod);
  const { updatesProgress } = useContext(WithProgress);

  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const schemas = schema(t)[1];
  const containerClasses = useContainerStyles();
  const handleGenericError = useErrorHandler();

  const selectedPayment = PaymentMethod.ONLINE_PAYMENT;

  useEffect(() => {
    updatesProgress(50);
  }, [updatesProgress]);

  useEffect(() => {
    updateContainer({ title: <Typography variant="h6">{t('onboarding_online.title')}</Typography>, bigIcon: false });
  }, [updateContainer, t]);

  let filterValues: SetupPaymentMethodData;
  const _handleSubmit = (values: SetupPaymentMethodData, actions: FormikHelpers<SetupPaymentMethodData>) => {
    filterValues = {
      address: {
        line: values.address.line,
        suffix: values.address.suffix,
        zipCode: values.address.zipCode,
        city: values.address.city,
        country: values.address.country,
      },
      paymentMethod: selectedPayment,
    };
    PublicPaymentService.setPaymentMethod(setupId, filterValues)
      .then(() => {
        actions.setSubmitting(false);
        navigate(`${restartPath}/success?f=bo`);
      })
      .catch((e) => {
        handleGenericError(e);
      });
    actions.setSubmitting(true);
  };

  const formInitialValues: SetupPaymentMethodData = {
    address: {
      line: paymentMethodSetUpValues.address?.line,
      suffix: paymentMethodSetUpValues.address?.suffix,
      zipCode: paymentMethodSetUpValues.address?.zipCode,
      city: paymentMethodSetUpValues.address?.city,
      country: paymentMethodSetUpValues.address?.country,
    },
    paymentMethod: selectedPayment,
  };

  return (
    <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
      <div className={[containerClasses.contentContainer, containerClasses.greyContainer, 'pa-container'].join(' ')}>
        <Formik initialValues={formInitialValues} validationSchema={schemas} onSubmit={_handleSubmit}>
          {() => (
            <Form>
              <UserInformationForm from="online" />
              <div className={containerClasses.horizontalContainer}>
                <Link
                  style={{ marginLeft: '1rem' }}
                  color="secondary"
                  type="button"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  {t('links.cancel')}
                </Link>
                <Button
                  type="submit"
                  name="submit"
                  style={{ width: 'fit-content' }}
                  className={containerClasses.button}
                  label={t('buttons.finalize_setup')}
                />
              </div>
            </Form>
          )}
        </Formik>

        {/* dialog when cancel User-Information form */}
        <Dialog
          title={t('dialogs.cancel_setup')}
          close={() => {
            setIsOpen(false);
          }}
          open={isOpen}
        >
          <>
            <Typography variant="body2">{t('dialogs.cancel_setup_online_subtitle')}</Typography>
            <div className={containerClasses.horizontalContainer}>
              <Link style={{ marginRight: '1rem' }} color="secondary" type="button" onClick={() => setIsOpen(false)}>
                {t('links.come_back')}
              </Link>
              <Button
                onClick={() => {
                  navigate(restartPath);
                }}
                style={{ width: 'fit-content' }}
                className={containerClasses.button}
                name="submit"
                label={t('buttons.cancel_setup')}
              />
            </div>
          </>
        </Dialog>
      </div>
    </div>
  );
}
