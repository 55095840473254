import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { VerificationT } from '../../types/VerificationT';
import VerifyService from '../../services/VerifyService';
import useErrorHandler from '../Error/ErrorHandler';
import VerificationStart from './content/VerificationStart';
import WithVerificationId from './WithVerificationId';
import VerificationVerified from './content/VerificationVerified';
import VerificationLoading from './content/VerificationLoading';
import VerificationError from './content/VerificationError';
import CustomerThemeProvider, { WithCompanyBrand } from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import { VerificationInformation } from '../../types/VerificationInformation';
import WithVerifiedInfo from './WithVerifiedInfo';

export default function Verification() {
  const match = useMatch('/payment/verify/:verificationId');

  const { t } = useTranslation('global');

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verification, setVerification] = useState<VerificationT>();
  const [branding, setBranding] = useState<VerificationInformation>({} as VerificationInformation);

  const handleGenericError = useErrorHandler();

  const onSubmit = (verificationId: string, lastName: string) => {
    setError(null);
    setLoading(true);
    setTimeout(() => {
      VerifyService.verify(verificationId, lastName)
        .then(async (verification) => {
          setVerification(verification);
          setLoading(false);
        })
        .catch(() => {
          handleGenericError(new Error('VerificationFlow failed'), undefined, false);
          setError(t('verification.verification_unsuccessful'));
          setLoading(false);
        });
    }, 1000);
  };

  const reset = () => {
    setLoading(false);
    setError(null);
  };

  const verificationId = match?.params.verificationId;
  useEffect(() => {
    if (verificationId)
      VerifyService.verifyInit(verificationId).then((branding) => {
        setBranding(branding);
      });
  }, [verificationId]);

  let container;
  if (loading) {
    container = <VerificationLoading />;
  } else {
    if (error !== null) {
      container = <VerificationError back={reset} />;
    } else if (verification !== undefined && branding !== undefined) {
      container = (
        <WithVerifiedInfo.Provider
          value={{
            verifiedInfo: verification,
            restartPath: '/payment/' + verification.paymentId,
          }}
        >
          <VerificationVerified />
        </WithVerifiedInfo.Provider>
      );
    } else {
      container = <VerificationStart onSubmit={onSubmit} />;
    }
  }

  return (
    <WithVerificationId.Provider value={{ verificationId: verificationId }}>
      <WithCompanyBrand.Provider value={branding}>
        <CustomerThemeProvider>{container}</CustomerThemeProvider>
      </WithCompanyBrand.Provider>
    </WithVerificationId.Provider>
  );
}
