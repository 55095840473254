import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';

type Entry = {
  name: ReactElement | string;
  value: ReactElement | string;
};

type HeadlineMenuProps = {
  entries: Entry[];
};

const useStyles = makeStyles((_) => ({
  list: {
    backgroundColor: '#F2F2F2',
    margin: '0px',
    padding: '0px',
    borderRadius: '5px',
    fontSize: 'smaller',
  },
  listItemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    placeContent: 'space-between',
    borderTop: '1px solid white',
  },
  listItemLeft: {
    padding: '12px 0px 12px 15px',
    fontWeight: '600',
    textAlign: 'left',
  },
  listItemRight: {
    padding: '4.5px 15px 4.5px 0px',
    textAlign: 'right',
    alignSelf: 'center',
  },
}));

const HeadlineMenu = ({ entries }: HeadlineMenuProps) => {
  const classes = useStyles();

  return (
    <div className={classes.list}>
      {entries.map((entry, index) => {
        let value = entry.value;
        if (typeof value === 'string' && 'date') {
          value = (
            <Typography variant="body2" className={classes.listItemRight}>
              {entry.value}
            </Typography>
          );
        } else {
          value = <div className={classes.listItemRight}>{entry.value}</div>;
        }

        return (
          <div className={classes.listItemsContainer} key={'menu' + index}>
            <Typography variant="body2" className={classes.listItemLeft}>
              {entry.name}
            </Typography>
            {value}
          </div>
        );
      })}
    </div>
  );
};

export default HeadlineMenu;
