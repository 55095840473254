import React, { ReactElement, useContext } from 'react';
import WithCheckout from '../WithCheckout';
import { useTranslation } from 'react-i18next';
import HeadlineMenu from '../../../components/HeadlineMenu';
import { PaymentState } from '../../../types/PaymentState';
import { Typography } from '@mui/material';
import StateWithIcon from '../../../components/StateWithIcon';
import { STEPS, WithCustomerData } from '../WithCustomerData';
import { formatDate } from '../../../utils/DateFormatUtils';
import IBAN from 'iban';
import { FintecRiskResult } from '../../../types/FintecRiskResult';
import { PaymentMethod } from '../../../types/PaymentMethod';
import { insertAt } from '../../../utils/MenuUtils';

interface Props {
  currentStep: number;
  paymentMethod: PaymentMethod;
  accountValidation: FintecRiskResult | undefined;
}

export default function CheckoutDirectDebitMenu({ currentStep, paymentMethod, accountValidation }: Props): ReactElement {
  const { checkoutInformation } = useContext(WithCheckout);
  const { t } = useTranslation('global');

  const isDonation = checkoutInformation.product.productType === 'DONATION';
  const isSubscription = checkoutInformation.product.billingType === 'RECURRING';

  const { customFields, customerData } = useContext(WithCustomerData);

  const bankAccountInformation =
    !!customerData.accountHolder && (currentStep === STEPS.SUMMARY || currentStep === STEPS.USER_FORM || currentStep === STEPS.SUCCESS);

  const menuEntries = [
    {
      name: t('checkout.info'),
      value: checkoutInformation.product.description ? checkoutInformation.product.description : checkoutInformation.product.name,
    },
  ];

  customFields?.map(
    (cf: any, index: any) =>
      customFields?.[index].fieldValue &&
      insertAt(menuEntries, 1 + index, {
        name: cf.fieldLabel,
        value: customFields?.[index].fieldType === 'DATE' ? formatDate(customFields?.[index].fieldValue as string) : customFields?.[index].fieldValue,
      }),
  );

  currentStep >= STEPS.USER_FORM &&
    insertAt(menuEntries, 3, {
      name: t('checkout.payment'),
      value: t('checkout.direct_debit'),
    });

  (bankAccountInformation || accountValidation) &&
    insertAt(
      menuEntries,
      4,

      {
        name: t('menu.account_holder'),
        value: customerData.accountHolder || accountValidation?.account_holder,
      },
      {
        name: t('menu.iban'),
        value: IBAN.printFormat(customerData.iban || accountValidation?.iban),
      },
      {
        name: t('menu.bic'),
        value: customerData.bic || accountValidation?.bic,
      },
    );

  (currentStep === STEPS.USER_FORM || currentStep === STEPS.SUCCESS) &&
    insertAt(
      menuEntries,
      7,
      {
        name: t('menu.company_name'),
        value: checkoutInformation.companyName,
      },
      {
        name: t('menu.creditor_id'),
        value: checkoutInformation.creditorIdentifier,
      },
    );

  ((paymentMethod === PaymentMethod.DIRECT_DEBIT && (bankAccountInformation || accountValidation)) ||
    (paymentMethod === PaymentMethod.DIRECT_DEBIT_MANUALLY && currentStep === STEPS.SUCCESS)) &&
    insertAt(menuEntries, menuEntries.length, {
      name: t('menu.state'),
      value: (
        <>
          <Typography variant="body2" style={{ display: 'flex' }}>
            <StateWithIcon
              state={PaymentState.VERIFIED}
              label={
                currentStep === STEPS.SUCCESS
                  ? isDonation
                    ? t('states.donated')
                    : isSubscription
                    ? t('states.subscribed')
                    : t('states.bought')
                  : t('states.account_verified')
              }
            />
          </Typography>
        </>
      ),
    });

  return <HeadlineMenu entries={menuEntries}></HeadlineMenu>;
}
