import React, { ReactElement, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Stack, TextField } from '@mui/material';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormikInputField } from '@payactive/app-common';
import { useContainerStyles } from '../../components/Container';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse } from 'date-fns';
import { WithCustomerData } from './WithCustomerData';

const useStyles = makeStyles((theme) => ({
  input: {
    alignItems: 'flex-start',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  margin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiFormControl-root': {
      width: '100%',
      margin: 'auto',
    },
  },
}));

export default function CustomFields(): ReactElement {
  const { customFields, updateCustomFields } = useContext(WithCustomerData);
  const { values, setFieldValue, getFieldMeta } = useFormikContext<any>();
  const containerClasses = useContainerStyles();

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const getLocale = () => {
    switch (i18n.language) {
      case 'de':
        return deLocale;
      default:
        return enLocale;
    }
  };

  const currentDate = new Date();
  currentDate.setHours(12, 0, 0, 0);

  return (
    <>
      {customFields !== undefined && customFields?.length > 0 && (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          {customFields?.map((cf, index) =>
            cf.fieldType === 'DATE' ? ( // DATE
              <LocalizationProvider dateAdapter={AdapterDateFns} key={index} locale={getLocale()}>
                <Stack>
                  <div style={{ display: 'flex' }}>
                    <DesktopDatePicker
                      disablePast
                      label={cf.required ? cf.fieldLabel + ' *' : cf.fieldLabel}
                      value={values.customFields?.[index].fieldValue || currentDate}
                      inputFormat={t('checkout.date_format')}
                      onChange={(e) => {
                        setFieldValue(`customFields.${index}.fieldValue`, e);
                        customFields[index].fieldValue = e;
                        updateCustomFields(customFields);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!getFieldMeta(`customFields.${index}.fieldValue`).error}
                          name={`customFields.${index}.fieldValue`}
                          label={cf.required ? cf.fieldLabel + ' *' : cf.fieldLabel}
                          className={[classes.margin, classes.input].join(' ')}
                          helperText={getFieldMeta(`customFields.${index}.fieldValue`).error}
                          onBlur={({ target: { value } }) => {
                            let date = null;
                            if (value && value !== '') {
                              date = parse(value, t('checkout.date_format'), new Date());
                            }
                            setFieldValue(`customFields.${index}.fieldValue`, date);
                            customFields[index].fieldValue = date?.toISOString() ?? null;
                            updateCustomFields(customFields);
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </Stack>
              </LocalizationProvider>
            ) : cf.fieldType === 'TEXT' ? ( // TEXT
              <div key={index} style={{ display: 'flex' }}>
                <FormikInputField
                  className={[classes.margin, classes.input].join(' ')}
                  name={`customFields.${index}.fieldValue`}
                  value={values.customFields?.[index].fieldValue ?? undefined}
                  label={cf.required ? cf.fieldLabel + ' *' : cf.fieldLabel}
                  onBlur={({ target: { value } }) => {
                    customFields[index].fieldValue = value;
                    updateCustomFields(customFields);
                  }}
                />
              </div>
            ) : (
              ''
            ),
          )}
        </div>
      )}
    </>
  );
}
