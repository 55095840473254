import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert, Grid, Link, Typography } from '@mui/material';
import WithCheckout from './WithCheckout';
import { useContainerStyles } from '../../components/Container';
import LoadingButton from '../../components/LoadingButton';
import { useFormikContext } from 'formik';
import CountrySelect from '../../components/CountrySelect';
import { UIFFIF } from '../../components/UIFFIF';
import { WithCustomerData } from './WithCustomerData';
import { CheckoutInformation } from '../../types/CheckoutInformation';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    alignItems: 'flex-start',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

type valuesT = {
  debtor: {
    type: string;
    vatId?: string;
  };
  address: {
    country?: string;
  };
};

const isReverseChargeApplicable = (checkoutInformation: CheckoutInformation, values: valuesT): Boolean => {
  return (
    checkoutInformation.reverseChargeEnabled &&
    values.debtor?.type === 'ORGANIZATION' &&
    !!values.debtor?.vatId &&
    !!values.address?.country &&
    values.address.country !== 'DE'
  );
};

const calculateTax = (quantity: number, taxRate: number, netPrice: number) => {
  return (taxRate / 100) * netPrice * quantity;
};

const calculatePrice = (quantity: number, taxRate: number, netPrice: number) => {
  return netPrice * quantity + calculateTax(quantity, taxRate, netPrice);
};

export default function UserFormInfoScreen({ loading }: { loading: boolean }) {
  const { t } = useTranslation('global');
  const { checkoutInformation } = useContext(WithCheckout);
  const { quantity, updateTotalPrice, productPrice } = useContext(WithCustomerData);
  const { values, isValid, status, setFieldValue } = useFormikContext<any>();
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const [isOrganizazion, setIsOrganization] = useState(false);

  useEffect(() => {
    let taxRate: number = checkoutInformation.product.taxRate.rate;
    if (isReverseChargeApplicable(checkoutInformation, values)) {
      taxRate = 0;
    }
    updateTotalPrice(calculatePrice(quantity, taxRate, productPrice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.address.country, values.debtor.vatId, values.debtor.type]);

  useEffect(() => {
    setFieldValue('debtor.type', 'PERSON', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
        <Grid container alignItems={'stretch'} justifyItems={'center'} spacing={2}>
          <Grid item xs={12}>
            {!!status && <Alert severity="error">{t('checkout.error')}</Alert>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              <b>{checkoutInformation.requireAddress ? t('user_form.title') : t('user_form.title2')}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">{checkoutInformation.requireAddress ? t('user_form.subtitle') : t('checkout.online_subtitle')}</Typography>
          </Grid>

          {checkoutInformation.requireAddress && (
            <Grid item xs={12}>
              <RadioGroup
                style={{ display: 'flex', width: '100%' }}
                defaultValue="private_customer"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  style={{ flexGrow: '1' }}
                  value="private_customer"
                  control={
                    <Radio
                      onChange={() => {
                        setIsOrganization(false);
                        setFieldValue('debtor.type', 'PERSON', false);
                      }}
                    />
                  }
                  label={<>{t('user_form.private_customer')}</>}
                />
                <FormControlLabel
                  style={{ flexGrow: '1' }}
                  value="organization"
                  control={
                    <Radio
                      onChange={() => {
                        setIsOrganization(true);
                        setFieldValue('debtor.type', 'ORGANIZATION', false);
                      }}
                    />
                  }
                  label={<>{t('user_form.organisation')}</>}
                />
              </RadioGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <UIFFIF name="debtor.firstName" label={t('user_form.firstName')} required classes={classes} />
          </Grid>
          <Grid item xs={12}>
            <UIFFIF name="debtor.lastName" label={t('user_form.lastName')} required classes={classes} />
          </Grid>
          {isOrganizazion && (
            <>
              <Grid item xs={12}>
                <UIFFIF name="debtor.companyName" label={t('user_form.company')} required classes={classes} />
              </Grid>
              <Grid item xs={12}>
                <UIFFIF name="debtor.vatId" label={t('user_form.vatId')} required={checkoutInformation.reverseChargeEnabled} classes={classes} />
              </Grid>
            </>
          )}

          {checkoutInformation.requireAddress && (
            <>
              <Grid item xs={12}>
                <UIFFIF name="address.line" label={t('user_form.line')} required={checkoutInformation.requireAddress} classes={classes} />
              </Grid>
              <Grid item xs={12}>
                <UIFFIF name="address.suffix" label={t('user_form.suffix')} classes={classes} />
              </Grid>
              <Grid item xs={4}>
                <UIFFIF name="address.zipCode" label={t('user_form.post')} required={checkoutInformation.requireAddress} classes={classes} />
              </Grid>
              <Grid item xs={8}>
                <UIFFIF name="address.city" label={t('user_form.city')} required={checkoutInformation.requireAddress} classes={classes} />
              </Grid>
              <Grid item xs={12}>
                <CountrySelect
                  name="address.country"
                  label={t('user_form.country')}
                  required={checkoutInformation.requireAddress}
                  classes={classes}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
        <Typography variant="body2">{t('checkout.button_invoiceAddress')}</Typography>
        <LoadingButton disabled={!isValid} style={{ width: 'fit-content' }} className={containerClasses.button} loading={loading}>
          {isReverseChargeApplicable(checkoutInformation, values)
            ? t(`buttons.${checkoutInformation.buttonLabel}_REVERSE_CHARGE`)
            : t(`buttons.${checkoutInformation.buttonLabel}`)}
        </LoadingButton>
        {checkoutInformation.termsAndConditions && (
          <div>
            <Typography variant="caption">
              <Trans
                i18nKey="checkout.terms_and_conditions"
                components={{
                  link1: <Link target="_blank" href={checkoutInformation.termsAndConditions.termsAndConditionsUrl}></Link>,
                  link2: <Link target="_blank" href={checkoutInformation.termsAndConditions.privacyPolicyUrl}></Link>,
                }}
              />
            </Typography>
          </div>
        )}
      </div>
    </>
  );
}
