import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import Container, { useContainerStyles } from '../../../components/Container';
import StatusIcon from '../../../components/StatusIcon';
import { WithProgress } from '../../WithProgressContext';
import WithVerifiedInfo from '../WithVerifiedInfo';
import { PaymentMethod } from '../../../types/PaymentMethod';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const VerificationVerified = () => {
  const navigate = useNavigate();

  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { verifiedInfo, restartPath } = useContext(WithVerifiedInfo);
  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    updatesProgress(100);
  }, [updatesProgress]);

  const { t } = useTranslation('global');

  const paymentMethodLabel = (method: PaymentMethod) => {
    if (method === PaymentMethod.ONLINE_PAYMENT) {
      return t('verification.online_payment');
    } else if (method === PaymentMethod.DIRECT_DEBIT) {
      return t('verification.direct_debit');
    } else {
      return '';
    }
  };

  return (
    <Container title={<Typography variant="h6">{t('verification.verify_payment')}</Typography>}>
      <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
        <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
          <StatusIcon title={t('verification.successful')} variant="success" variantIcon="down" iconSize="large">
            <Typography className={classes.spacing} variant="body2">
              {t('verification.to_company_name', { payment_type: paymentMethodLabel(verifiedInfo.paymentMethod) })}
              <br />
              <b>{verifiedInfo.companyName}</b>
            </Typography>

            {verifiedInfo.paymentMethod === PaymentMethod.ONLINE_PAYMENT && (
              <Button
                name="next"
                label={t('buttons.continue_to_payment_status')}
                onClick={() => {
                  navigate(restartPath);
                }}
              />
            )}
          </StatusIcon>
        </div>
      </div>
    </Container>
  );
};

export default VerificationVerified;
