// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TFunction } from 'react-i18next';

const formatDateTime = (dateTime: string | number | Date, t: TFunction) => {
  const timeFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  let result = new Intl.DateTimeFormat('de-DE', timeFormat).format(new Date(dateTime));
  const newDate = result.split(',')[0];
  const newHour = result.split(',')[1];

  return `${newDate} ${t('menu.at')} ${newHour}`;
};

const formatDate = (dateTime: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return new Intl.DateTimeFormat('de-DE', options).format(new Date(dateTime));
};

export { formatDateTime, formatDate };
