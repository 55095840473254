import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ContainerRef, ContainerReference } from '../../components/Container';
import { useTranslation } from 'react-i18next';
import CustomerThemeProvider, { WithCompanyBrandProvider } from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import LoadingContainer from '../../components/LoadingContainer';
import OtpContextProvider, { OtpSecured } from '../../components/Otp/OtpContextProvider';
import PaymentResolution from './PaymentResolution';
import { CompanyBrand } from '../../types/CompanyBrand';
import BrandService from '../../services/BrandService';
import DefaultBackground from '../../components/CustomerThemeProvider/default.png';
import PayactiveLogo from '../../components/CustomerThemeProvider/payactive.png';
import { WithPaymentFromVerificationIdProvider } from '../PaymentFlow/WithPayment';
import useErrorHandler from '../Error/ErrorHandler';
import { ErrorCodes } from '../Error/ErrorPage';

export default function PaymentResolutionFlow() {
  const { t } = useTranslation();
  const container = useRef<ContainerReference>(null);
  const [companyBrand, setCompanyBrand] = useState<CompanyBrand>();
  const errorHandler = useErrorHandler();

  const match = useMatch('/payment/resolution/:verificationId/*');
  const verificationId = match?.params.verificationId;

  useEffect(() => {
    if (verificationId) {
      BrandService.getBrandingByVerificationId(verificationId)
        .then(({ companyBrand }) => setCompanyBrand(companyBrand))
        .catch(() => setCompanyBrand({ brandBackgroundImageUrl: DefaultBackground, companyLogoUrl: PayactiveLogo }));
    }
  }, []);

  if (!verificationId) {
    errorHandler(new Error('Verification id not set'), ErrorCodes.Verification_not_set);
    return <></>;
  }

  if (companyBrand === undefined) {
    return <LoadingContainer />;
  }

  return (
    <WithCompanyBrandProvider companyBrand={companyBrand}>
      <OtpContextProvider>
        <CustomerThemeProvider>
          <OtpSecured description={t('resolution.otp_description')} withContainer>
            <WithPaymentFromVerificationIdProvider verificationId={verificationId} restartPath={`/payment/resolution/${verificationId}`}>
              <ContainerRef
                menuOpen={false}
                ref={container}
                title={
                  <>
                    <Grid container alignItems={'center'} direction={'column'}>
                      <Grid item>
                        <Typography variant="h6">{t('resolution.app_title')}</Typography>
                      </Grid>
                    </Grid>
                  </>
                }
                bigIcon
              >
                <Routes>
                  <Route path={'/'} element={<Navigate to={'/payment/resolution/' + verificationId + '/overview'} />} />
                  <Route
                    path={'/overview'}
                    element={
                      verificationId && (
                        <PaymentResolution
                          updateContainer={(props) => {
                            container.current?.setProps(props);
                          }}
                        />
                      )
                    }
                  />
                </Routes>
              </ContainerRef>
            </WithPaymentFromVerificationIdProvider>
          </OtpSecured>
        </CustomerThemeProvider>
      </OtpContextProvider>
    </WithCompanyBrandProvider>
  );
}
