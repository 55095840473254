import React, { ReactElement, useState } from 'react';

interface Props {
  children: React.ReactElement;
}

const WithProgress = React.createContext({
  currentProgress: 0,
  updatesProgress: (progress: number) => {
    console.error('using context without correct initialization: ' + progress);
  },
});

export default function WithProgressContext({ children }: Props): ReactElement {
  const [currentProgress, setCurrentProgress] = useState<number>(0);

  const updatesProgress = (progress: number) => {
    setCurrentProgress(progress);
  };

  return (
    <WithProgress.Provider
      value={{
        currentProgress: currentProgress,
        updatesProgress: updatesProgress,
      }}
    >
      {children}
    </WithProgress.Provider>
  );
}

export { WithProgress };
