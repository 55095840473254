// /**create one context with custom fields and step */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CustomField, ProductType } from '../../types/CheckoutInformation';
import WithCheckout from './WithCheckout';

type WithCustomerDataT = {
  customFields: CustomField[];
  updateCustomFields: (cf: CustomField[]) => void;
  customerData: any;
  updateCustomerData: (o: any) => void;
  quantity: number;
  updateQuantity: (q: number) => void;
  totalTax: number;
  updateTotalTax: (tax: number) => void;
  totalPrice: number;
  productPrice: number;
  updateProductPrice: (price: number) => void;
  updateTotalPrice: (p: number) => void;
  wizardSessionKey: string;
  updateWizardSessionKey: (w: string) => void;
  clearSessionStorage: () => void;
};

export enum STEPS {
  INIT = 0,
  PAYMENT_METHOD = 3,
  DIRECT_DEBIT = 4,
  DIRECT_DEBIT_MANUALLY = 5,
  SUMMARY = 6,
  USER_FORM = 7,
  SUCCESS = 8,
}

export const WithCustomerData = React.createContext<WithCustomerDataT>({
  customFields: [],
  updateCustomFields: () => {},
  customerData: {},
  updateCustomerData: () => {},
  quantity: 1,
  updateQuantity: () => {},
  totalTax: 0,
  updateTotalTax: () => {},
  totalPrice: 0,
  productPrice: 0,
  updateProductPrice: () => {},
  updateTotalPrice: () => {},
  wizardSessionKey: '',
  updateWizardSessionKey: () => {},
  clearSessionStorage: () => {
    sessionStorage.clear();
  },
});

const calculateTax = (quantity: number, taxRate: number, netPrice: number) => {
  return (taxRate / 100) * netPrice * quantity;
};

function WithCustomerDataContextProvider({ children }: { children: ReactElement }): ReactElement {
  const { checkoutInformation } = useContext(WithCheckout);

  const sessionData = JSON.parse(sessionStorage.getItem('customerData') || '{}');
  const [customFields, setCustomFields] = useState<CustomField[]>(sessionData.customFields || checkoutInformation.customFields);
  const [customerData, setCustomerData] = useState<any>(sessionData.customerData || {});
  const [wizardSessionKey, setWizardSessionKey] = useState('');

  const product = checkoutInformation.product;
  const [quantity, setQuantity] = useState(sessionData.quantity || 1);
  const [totalTax, setTotalTax] = useState(calculateTax(quantity, product.taxRate.rate, product.price));

  const [productPrice, setProductPrice] = useState(
    product.productType === ProductType.DONATION ? sessionData.productPrice || product.defaultDonationAmount : product.price,
  );
  const [totalPrice, setTotalPrice] = useState(productPrice * quantity + calculateTax(quantity, product.taxRate.rate, product.price));

  useEffect(() => {
    let tax = calculateTax(quantity, product.taxRate.rate, product.price);
    setTotalTax(tax);
    setTotalPrice(productPrice * quantity + tax);
    saveToStorage();
  }, [quantity, customFields]);

  /**
   * session storage name: customerData
   * value: {
   *          customerData: { //data of the customer, address, name, lastname ...}
   *          currentStep: number
   *          customFields: [] of custom fields
   *          customerData:  any
   *          quantity: number
   *         }
   */

  const saveToStorage = () => {
    const storageData = {
      customFields: customFields,
      customerData: customerData,
      quantity: quantity,
      wizardSessionKey: wizardSessionKey,
      productPrice: productPrice,
    };
    sessionStorage.setItem('customerData', JSON.stringify(storageData));
  };
  saveToStorage();

  return (
    <WithCustomerData.Provider
      value={{
        customFields: customFields,
        updateCustomFields: (cf: CustomField[]) => {
          setCustomFields(cf);
          saveToStorage();
        },
        customerData: customerData,
        updateCustomerData: (o) => {
          setCustomerData(o);
          saveToStorage();
        },
        quantity: quantity,
        updateQuantity: (q: number) => {
          setQuantity(q);
          saveToStorage();
        },
        totalTax: totalTax,
        updateTotalTax: (tax) => {
          setTotalTax(tax);
          setTotalPrice(product.price * quantity + tax);
          saveToStorage();
        },
        productPrice: productPrice,
        updateProductPrice: setProductPrice,
        totalPrice: totalPrice,
        updateTotalPrice: setTotalPrice,
        wizardSessionKey: wizardSessionKey,
        updateWizardSessionKey: (w: string) => {
          setWizardSessionKey(w);
          saveToStorage();
        },
        clearSessionStorage: () => {
          sessionStorage.clear();
        },
      }}
    >
      {children}
    </WithCustomerData.Provider>
  );
}

export default WithCustomerDataContextProvider;
