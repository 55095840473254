import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import PaymentMethodSwitch from './PaymentMethodSwitch';
import DirectDebit from './DirectDebit/DirectDebit';
import OnlinePayment from './OnlinePayment/OnlinePayment';
import { WithPaymentMethodBrandedProvider } from './WithPaymentMethod';
import { ErrorCodes, ErrorPage } from '../Error/ErrorPage';
import useErrorHandler from '../Error/ErrorHandler';
import SuccessScreen from '../SuccessScreen';
import PublicPaymentService from '../../services/PublicPaymentService';
import { DebtorPaymentMethodSetup } from '../../types/Debtor/DebtorPaymentMethodSetup';
import LoadingContainer from '../../components/LoadingContainer';
import CustomerThemeProvider from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import { FintecRiskResult } from '../../types/FintecRiskResult';
import Manually from './DirectDebit/Manual/Manually';
import { ContainerRef, ContainerReference } from '../../components/Container';

const PaymentMethodPath = {
  directDebit: '/directDebit',
  manual: '/manually',
  online: '/online',
  success: '/success*',
};

export default function PaymentMethodSetupFlow(): ReactElement {
  const [values, setValues] = useState<DebtorPaymentMethodSetup>();
  const [validation, setValidation] = useState<FintecRiskResult>();

  const match1 = useMatch('/payment/:companyId/payment-method-setup/:setupId/*');
  const match2 = useMatch('/payment/setup/:setupId/*');

  let setupId = match1?.params.setupId;
  let companyId = match1?.params.companyId;
  const handleGenericError = useErrorHandler();

  useEffect(() => {
    setupId &&
      PublicPaymentService.getPaymentMethodSetUp(setupId)
        .then((res) => {
          setValues(res);
        })
        .catch((e) => {
          handleGenericError(e, ErrorCodes.SetupId_not_found);
        });
  }, []);

  if (!setupId) {
    setupId = match2?.params.setupId;
  }

  const container = useRef<ContainerReference>(null);
  if (!setupId) {
    return <ErrorPage errorCode={ErrorCodes.SetupId_not_found} />;
  }
  if (!values) {
    return <LoadingContainer />;
  }
  return (
    <WithPaymentMethodBrandedProvider
      value={{
        setupId: setupId,
        companyId: companyId,
        paymentMethodSetUpValues: values,
        restartPath: '/payment/' + companyId + '/payment-method-setup/' + setupId,
        validation: validation,
        setValidation: setValidation,
      }}
    >
      <CustomerThemeProvider>
        <ContainerRef ref={container} bigIcon={true}>
          <Routes>
            {/* prettier-ignore */}
            <Route path={'/'} element={<PaymentMethodSwitch updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            {/* prettier-ignore */}
            <Route path={PaymentMethodPath.directDebit} element={<DirectDebit  updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            {/* prettier-ignore */}
            <Route path={PaymentMethodPath.manual} element={<Manually  updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            {/* prettier-ignore */}
            <Route path={PaymentMethodPath.online} element={<OnlinePayment  updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            {/* prettier-ignore */}
            <Route path={PaymentMethodPath.success} element={<SuccessScreen  updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            <Route path={'*'} element={<Navigate to={'/payment/' + companyId + '/payment-method-setup/' + setupId} />} />
          </Routes>
        </ContainerRef>
      </CustomerThemeProvider>
    </WithPaymentMethodBrandedProvider>
  );
}
