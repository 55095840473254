import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import OnlinePayment from './OnlinePayment/OnlinePayment';
import InitPayment from './InitPayment';
import { WithPaymentBrandedProvider } from './WithPayment';
import SuccessScreen from '../SuccessScreen';
import ManualPayment from './ManualPayment/ManualPayment';
import { ErrorCodes, ErrorPage } from '../Error/ErrorPage';
import useErrorHandler from '../Error/ErrorHandler';
import PublicPaymentService from '../../services/PublicPaymentService';
import { PaymentInformation } from '../../types/PaymentInformation';
import LoadingContainer from '../../components/LoadingContainer';
import { PaymentState } from '../../types/PaymentState';
import { ContainerRef, ContainerReference } from '../../components/Container';
import YourPaymentTitle from './YourPaymentTitle';
import CancelScreen from '../CancelScreen';
import CustomerThemeProvider from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import PaymentDetailsMenu from '../../components/Payment/PaymentDetailsMenu';

export const PaymentPaths = {
  online: '/online',
  manual: '/manual',
  success: '/success',
  abort: '/abort',
  cancel: '/cancel',
};

export default function PaymentFlowRouter() {
  const match = useMatch('/payment/:paymentId/*');
  const paymentId = match?.params.paymentId;
  const navigate = useNavigate();

  const [paymentInformation, setPaymentInformation] = useState<PaymentInformation>({} as PaymentInformation);
  const handleGenericError = useErrorHandler();

  function loadPaymentInformation() {
    if (paymentId !== undefined) {
      const payId = paymentId;
      return PublicPaymentService.getPaymentInformationByPaymentFlowId(paymentId)
        .then((res) => {
          if (res.state === PaymentState.ERROR) {
            retryLoadPaymentInformation(payId);
          } else {
            setPaymentInformation(res);
          }
        })
        .catch((e) => {
          handleGenericError(e, ErrorCodes.Payment_get_failed);
        });
    }
    return Promise.reject<any>();
  }
  useEffect(() => {
    if (paymentInformation.state === undefined) {
      loadPaymentInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      paymentInformation.state === PaymentState.CANCELLED ||
      paymentInformation.state === PaymentState.REFUND_COMPLETED ||
      paymentInformation.state === PaymentState.REFUND_IN_PROGRESS
    ) {
      navigate('/payment/' + paymentId + PaymentPaths.cancel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInformation]);

  function retryLoadPaymentInformation(payId: string) {
    PublicPaymentService.restartPayment(payId).then((restarted) => {
      if (restarted) {
        PublicPaymentService.getPaymentInformationByPaymentFlowId(payId)
          .then((res) => {
            setPaymentInformation(res);
          })
          .catch((e) => {
            handleGenericError(e, ErrorCodes.Payment_get_failed);
          });
      } else {
        handleGenericError(new Error('PublicPaymentService.restartPayment failed'), ErrorCodes.Payment_restart_failed);
      }
    });
  }

  const container = useRef<ContainerReference>(null);
  if (paymentId === undefined) {
    return <ErrorPage errorCode={ErrorCodes.Payment_not_found_flow} />;
  }
  if (paymentInformation.state === undefined) {
    return <LoadingContainer />;
  }
  return (
    <WithPaymentBrandedProvider
      value={{
        paymentId: paymentId,
        restartPath: '/payment/' + paymentId,
        paymentInformation: paymentInformation,
        reloadPaymentInformation: loadPaymentInformation,
      }}
    >
      <CustomerThemeProvider>
        <ContainerRef ref={container} title={<YourPaymentTitle />} menu={<PaymentDetailsMenu />} menuOpen={true} bigIcon={true}>
          <Routes>
            {/* prettier-ignore */}
            <Route path={'/'}  element={<InitPayment  updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            {/* prettier-ignore */}
            <Route path={PaymentPaths.online} element={<OnlinePayment updateContainer={(props) => {
              container.current?.setProps(props);
            }} /> } />
            {/* prettier-ignore */}
            <Route path={PaymentPaths.manual} element={<ManualPayment updateContainer={(props) => {
              container.current?.setProps(props);
            }}  />} />
            {/* prettier-ignore */}
            <Route path={PaymentPaths.success} element={<SuccessScreen updateContainer={(props) => {
              container.current?.setProps(props);
            }} />} />
            {/* prettier-ignore */}
            <Route path={PaymentPaths.cancel} element={<CancelScreen updateContainer={(props) => {
              container.current?.setProps(props);
            }} />} />
            {/* next routes for downwards compatibility */}
            <Route path={PaymentPaths.abort} element={<Navigate to={'/payment/' + paymentId + PaymentPaths.manual} />} />

            <Route path={'*'} element={<Navigate to={'/payment/' + paymentId} />} />
          </Routes>
        </ContainerRef>
      </CustomerThemeProvider>
    </WithPaymentBrandedProvider>
  );
}

export { PaymentPaths as PaymentFlowPaths };
