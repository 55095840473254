import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import AvatarNumberList from '../../../components/AvatarNumberList';

export default function ManualInstructions(): ReactElement {
  const { t } = useTranslation('global');

  const listEntries = [
    {
      text: t('manual.howto_1'),
    },
    {
      text: t('manual.howto_2'),
    },
    {
      text: t('manual.howto_3'),
    },
  ];

  return (
    <>
      <Typography variant="h6">{t('manual.pay_manually')}</Typography>
      <Typography variant="body2">{t('manual.subtitle')}</Typography>
      <Typography variant="body2">{t('manual.subtitle2')}</Typography>
      <AvatarNumberList listEntries={listEntries} />
    </>
  );
}
