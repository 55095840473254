import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikInputField } from '@payactive/app-common';
import makeStyles from '@mui/styles/makeStyles';
import WithVerificationId from './WithVerificationId';

type VerificationFormProps = {
  onSubmit: (id: string, lastName: string) => void;
};

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  input: {
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export default function VerificationForm({ onSubmit }: VerificationFormProps) {
  const { t } = useTranslation('global');
  const classes = useStyles();
  const { verificationId } = useContext(WithVerificationId);

  const _handleSubmit = (values: { verificationId: string; lastName: string }) => {
    onSubmit(values.verificationId, values.lastName);
  };

  const formInitialValues = {
    verificationId: verificationId || '',
    lastName: '',
  };
  const schema = Yup.object().shape({
    verificationId: Yup.string().required(t('verification.id_required')),
    lastName: Yup.string().required(t('verification.lastname_required')),
  });

  return (
    <>
      <Formik initialValues={formInitialValues} onSubmit={_handleSubmit} validationSchema={schema}>
        {({ dirty, isSubmitting }) => (
          <div className={classes.spacing}>
            <FormikInputField
              className={classes.input}
              name="verificationId"
              label={t('verification.id_label')}
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <Form>
              <div className={classes.spacing}>
                <FormikInputField
                  className={classes.input}
                  InputLabelProps={{ shrink: true }}
                  name="lastName"
                  label={t('verification.lastname_label')}
                  type="text"
                  fullWidth
                />
              </div>
              <Button disabled={!dirty || isSubmitting} type="submit" name="submit" fullWidth label={t('buttons.verify_payment')} icon="lock" />
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
}
