import { SubscriptionRate } from './SubscriptionRate';
import { Metadata } from '../Metadata';
import { Debtor } from '../Debtor/Debtor';
import { BankAccount } from '../Bank/BankAccount';

export type Subscription = {
  bankAccount: BankAccount;
  createdDateTime: string;
  id: string;
  debtor: Debtor;
  interval: Subscriptions.Interval;
  metadata: Metadata[];
  nextPaymentDueDate: string;
  price: number;
  startDate: string;
  status: Subscriptions.Status;
  subscriptionsRates: SubscriptionRate[];
  subscriptionTitle: string;
  correlationId: string;
};

export namespace Subscriptions {
  export enum Interval {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
  }

  export enum Status {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  }

  export enum CancellationType {
    ORDINARY = 'ORDINARY',
    EXTRAORDINARY = 'EXTRAORDINARY',
  }
}
