import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WithPaymentMethod } from '../WithPaymentMethod';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
  },
  textField: {
    backgroundColor: '#E6E6E6',
    marginBottom: '1.25rem',
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function UserSummary(): ReactElement {
  const { t } = useTranslation('global');
  const classes = useStyles();

  const { paymentMethodSetUpValues } = useContext(WithPaymentMethod);

  return (
    <Grid direction="column">
      <Grid>
        <Typography variant="h6">
          <b>{t('onboarding_direct.summary_title')}</b>
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 300 }}>
          {t('onboarding_direct.summary_subtitle')} <Link href={'mailto:help@payactive.eu'}>{t('onboarding_direct.support')}</Link>
        </Typography>
      </Grid>
      <Grid className={classes.paper}>
        <TextField
          fullWidth
          className={classes.textField}
          variant="outlined"
          label={t('onboarding_direct.id')}
          value={paymentMethodSetUpValues.creditorIdentifier}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          fullWidth
          className={classes.textField}
          variant="outlined"
          label={t('onboarding_direct.company_id')}
          value={paymentMethodSetUpValues.companyName}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
