import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { versions } from '../versions';

const build = versions.BuildId;
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/i18n/{{ns}}/{{lng}}.json?v=${build}`,
    },
    supportedLngs: ['en', 'de'],
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: false,
    ns: ['global'],
    defaultNS: 'global',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    detection: {
      order: ['querystring', 'navigator'],
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
