import { AxiosInstance } from 'axios';
import { FintecInitiation } from '../types/FintecInitiation';
import { FintecRiskResult } from '../types/FintecRiskResult';
import ApiClient from './ApiClient';

export default class FintecService {
  private static _instance: AxiosInstance;
  private static _instanceHardRetry: AxiosInstance;

  private static get fintecService(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  private static get fintecServiceHardRetry(): AxiosInstance {
    return this._instanceHardRetry || (this._instanceHardRetry = ApiClient.clientHardRetry);
  }

  public static async initPayment(paymentFlowId: string, bic?: string): Promise<FintecInitiation | never> {
    return await this.fintecServiceHardRetry
      .post<FintecInitiation>(ApiClient.getApiDomain() + '/fintec/' + paymentFlowId + '/_init' + (bic ? `?senderBic=${bic}` : ''))
      .then((res) => {
        return res.data;
      });
  }

  public static async initAccountValidation(bic?: string): Promise<string | never> {
    let res = await this.fintecService.post<{ wizard_session_key: string }>(
      ApiClient.getApiDomain() + '/fintec/accountverification/public/_init' + (bic ? `?senderBic=${bic}` : ''),
      {
        bankCode: '88888888',
        countryId: 'DE',
      },
    );

    if (res.status === 201) {
      return res.data.wizard_session_key;
    } else {
      throw new Error(`FintecService.initAccountValidation failed: ${res.status}`);
    }
  }

  public static async fetchAccountValidation(wizardSessionKey: string): Promise<FintecRiskResult | never> {
    let res = await this.fintecService.get<FintecRiskResult>(ApiClient.getApiDomain() + '/fintec/accountverification/public/' + wizardSessionKey);
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(`FintecService.fetchAccountValidation failed: ${res.status}`);
    }
  }
}
