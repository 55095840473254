import { AxiosInstance } from 'axios';
import { Bank } from '../types/Bank/Bank';
import { CheckoutInformation } from '../types/CheckoutInformation';
import ApiClient from './ApiClient';
import { Amount } from '../types/Amount';

export default class PublicCheckoutService {
  private static _instance: AxiosInstance;
  private static _instanceHardRetry: AxiosInstance;
  private static _instanceNoRetry: AxiosInstance;

  private static get checkoutClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  private static get checkoutServiceNoRetry(): AxiosInstance {
    return this._instanceNoRetry || (this._instanceNoRetry = ApiClient.clientNoRetry);
  }

  public static async getCheckoutPaylinks(paylinkExternalId: string): Promise<CheckoutInformation | never> {
    return await this.checkoutClient
      .get<CheckoutInformation>(ApiClient.getApiDomain() + '/public/checkouts/paylinks/' + paylinkExternalId)
      .then((res) => {
        return res.data;
      });
  }

  public static async calculateTotalAmount(paylinkExternalId: string, quantity: number): Promise<Amount> {
    let params = {
      q: quantity,
    };
    let res = await this.checkoutClient({
      method: 'GET',
      url: ApiClient.getApiDomain() + '/public/checkouts/paylinks/' + paylinkExternalId + '/_calculateTotalAmount',
      params: params,
    });

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error('no amount data available');
    }
  }

  public static async doCheckout(paylinkExternalId: string, data: any) {
    try {
      let res = await this.checkoutServiceNoRetry({
        method: 'POST',
        url: ApiClient.getApiDomain() + '/public/checkouts/paylinks/' + paylinkExternalId,
        data: data,
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }

  public static async searchForBanksWithPayLinkExternalId(paylinkExternalId: string, search: string, country: string): Promise<Bank[]> {
    let res = await this.checkoutClient({
      method: 'GET',
      url: `${ApiClient.getApiDomain()}/public/checkouts/paylinks/${paylinkExternalId}/bank-codes?q=${search}&c=${country}`,
    });

    return res.data;
  }

  public static async updateProject(paylinkExternalId: string, debtorEmailAddress: string, projectId: string, token: string) {
    let res = await this.checkoutClient({
      method: 'PATCH',
      url: `${ApiClient.getApiDomain()}/public/checkouts/paylinks/${paylinkExternalId}/projects`,
      data: {
        projectId: projectId,
        email: debtorEmailAddress,
        token: token,
      },
    });

    return res.status === 200;
  }
}
