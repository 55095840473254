import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@payactive/app-common';
import BankAvatar from '../../../components/BankAvatar';
import { useContainerStyles } from '../../../components/Container';
import FintecDialog from '../../../components/FintecDialog';
import LoadingSpinnerDiv from '../../../components/loadingSpinnerDiv';
import { Bank } from '../../../types/Bank/Bank';
import { FintecRiskResult } from '../../../types/FintecRiskResult';
import useErrorHandler from '../../Error/ErrorHandler';
import { ErrorCodes } from '../../Error/ErrorPage';
import Dialog from '../../../components/Dialog';
import FintecService from '../../../services/FintecService';
import { WithCustomerData } from '../WithCustomerData';
import BankChooser from '../../../components/BankChooser';

const useStyles = makeStyles((_) => ({
  warningContainer: {
    display: 'flex',
    backgroundColor: '#DDAF3B',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
  },
}));

interface PayDirectDebitScreenProps {
  onManualSetup: () => void;
  onBack: () => void;
  succeedFintec: () => void;
  setAccountValidation: (a: FintecRiskResult) => void;
}

export default function PayDirectDebitScreen({
  onManualSetup,
  onBack,
  succeedFintec,
  setAccountValidation,
}: PayDirectDebitScreenProps): ReactElement {
  enum STEPS {
    EMPTY,
    USER_INFORMATION,
    MANUALLY,
    SUMMARY,
  }

  const [currentStep] = useState(STEPS.EMPTY);
  const [isOpenManualDialog, setIsOpenManualDialog] = React.useState(false);
  const [isOpenFintecDialog, setOpenFintecDialog] = useState(false);
  const [isCancelDialogOpen, setOpenCancelDialog] = useState(false);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [wizardKey, setWizardKey] = useState<string>();
  const [loading, setLoading] = useState<'intro' | 'outro'>();

  const { updateWizardSessionKey } = useContext(WithCustomerData);

  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { t } = useTranslation('global');

  const handleGenericError = useErrorHandler();

  const openFintecDialog = () => {
    setLoading('intro');
    FintecService.initAccountValidation(selectedBank?.bic)
      .then((wizard_session_key) => {
        setWizardKey(wizard_session_key);
        updateWizardSessionKey(wizard_session_key);
      })
      .catch(() => {
        handleGenericError(new Error('PublicPaymentService.reloadPayment failed'), ErrorCodes.SetupId_not_found);
      });
  };

  const resetFintecDialog = () => {
    setOpenFintecDialog(false);
    setWizardKey(undefined);
    setLoading(undefined);
  };

  const onFinish = () => {
    setLoading('outro');
    setOpenFintecDialog(false);
    if (wizardKey !== undefined) {
      FintecService.fetchAccountValidation(wizardKey)
        .then((res) => {
          setAccountValidation(res);
          succeedFintec();
          setLoading(undefined);
        })
        .catch((e) => {
          handleGenericError(e);
        });
    } else {
      //should be caught in useEffect init
      handleGenericError(new Error('wizardSessionKey is missing'));
    }
  };

  return (
    <>
      <div style={{ display: 'none' } /* preload spinner for slow connections */}>{<LoadingSpinnerDiv />}</div>
      {loading ? (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
          <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
            <Typography variant={'h6'} style={{ margin: '0 0 1rem' }}>
              {loading === 'intro' ? t('fintec.secure_connection_setup') : t('fintec.account_will_be_verified')}
            </Typography>
            {selectedBank && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                <BankAvatar bank={selectedBank} />
                &nbsp;{selectedBank.name}
              </div>
            )}
            {<LoadingSpinnerDiv />}
          </div>
        </div>
      ) : !loading && currentStep === STEPS.EMPTY ? (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Typography variant={'h6'} style={{ margin: '0 0 1rem' }}>
            {t('fintec.choose_your_bank')}
          </Typography>
          <BankChooser setBank={setSelectedBank} />
          <div className={containerClasses.horizontalContainer}>
            <Link color="secondary" style={{ margin: '0 1rem 0' }} onClick={onBack}>
              {t('links.come_back')}
            </Link>
            <Button
              disabled={selectedBank === undefined}
              className={[containerClasses.button, containerClasses.w50].join(' ')}
              name="submit"
              fullWidth
              label={t('buttons.to_bank_login')}
              icon="lock"
              onClick={() => openFintecDialog()}
            />
          </div>
          <Link
            color="secondary"
            style={{ margin: '0 1rem 0', textAlignLast: 'left' }}
            onClick={() => {
              setIsOpenManualDialog(true);
            }}
          >
            {t('links.manual_activation')}
          </Link>
        </div>
      ) : (
        ''
      )}

      {/* dialog to make direct debit manually */}
      <Dialog
        title={t('dialogs.manual_activation')}
        close={() => {
          setIsOpenManualDialog(false);
        }}
        open={isOpenManualDialog}
      >
        <>
          <div className={classes.warningContainer}>
            <WarningIcon style={{ marginRight: '0.6rem' }} />
            <Typography variant="body2">
              <b>{t('onboarding_direct.note')}</b>
              {t('onboarding_direct.manual_warning1')}
              <Link
                style={{ color: 'white', textDecoration: 'underline' }}
                onClick={() => {
                  setIsOpenManualDialog(false);
                }}
              >
                <b>{t('onboarding_direct.instant_verification')}</b>
              </Link>
              {t('onboarding_direct.manual_warning2')}
            </Typography>
          </div>
          <Typography variant="body2">{t('dialogs.manual_activation_subtitle')}</Typography>
          <div className={containerClasses.horizontalContainer}>
            <Link style={{ marginRight: '1rem' }} color="secondary" type="button" onClick={() => setIsOpenManualDialog(false)}>
              {t('links.come_back')}
            </Link>
            <Button
              onClick={() => {
                setIsOpenManualDialog(false);
                onManualSetup();
              }}
              style={{ width: 'fit-content' }}
              className={containerClasses.button}
              name="submit"
              label={t('buttons.cancel_setup')}
            />
          </div>
        </>
      </Dialog>

      {/* fintec dialog */}
      {wizardKey && (
        <Dialog
          style={{ display: isOpenFintecDialog ? 'inherit' : 'none' }}
          xIcon={true}
          title={
            selectedBank && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <BankAvatar bank={selectedBank} />
                &nbsp;{selectedBank.name}
              </div>
            )
          }
          open={true}
          close={() => {
            setOpenCancelDialog(true);
          }}
        >
          <FintecDialog
            variant="direct"
            wizardKey={wizardKey}
            close={() => {
              resetFintecDialog();
            }}
            loaded={() => {
              setOpenFintecDialog(true);
            }}
            success={onFinish}
          />
        </Dialog>
      )}

      {/* dialog when cancel Fintec flow */}
      {isCancelDialogOpen && (
        <Dialog title={t('dialogs.cancel_fintec_setup')} open={isCancelDialogOpen}>
          <>
            <Typography variant={'body2'}>{t('dialogs.cancel_fintec_setup_subtitle')}</Typography>
            <div className={containerClasses.horizontalContainer}>
              <Link
                color="secondary"
                style={{ margin: '0 1rem 0' }}
                onClick={() => {
                  setOpenCancelDialog(false);
                }}
              >
                {t('links.come_back')}
              </Link>
              <Button
                disabled={selectedBank === undefined}
                className={[containerClasses.button, containerClasses.w50].join(' ')}
                name="submit"
                fullWidth
                label={t('buttons.cancel_setup')}
                onClick={() => {
                  setOpenCancelDialog(false);
                  resetFintecDialog();
                }}
              />
            </div>
          </>
        </Dialog>
      )}
    </>
  );
}
