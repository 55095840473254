export enum PaymentState {
  CREATING = 'CREATING',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  VERIFIED = 'VERIFIED',
  ABORTED = 'ABORTED',
  MANUAL = 'MANUAL',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
  REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
  REFUND_COMPLETED = 'REFUND_COMPLETED',
}
