import { AxiosInstance } from 'axios';
import { DebtorPaymentMethodSetup } from '../types/Debtor/DebtorPaymentMethodSetup';
import { PaymentInformation } from '../types/PaymentInformation';
import { SetupPaymentMethod } from '../types/SetupPaymentMethod';
import ApiClient from './ApiClient';
import { Bank } from '../types/Bank/Bank';
import { CancelReason } from '../pages/PaymentFlow/ManualPayment/CancelReason';
import { SetupPaymentMethodData } from '../types/SetupPaymentMethodData';

export default class PublicPaymentService {
  private static _instance: AxiosInstance;
  private static _apiDomain = ApiClient.getApiDomain();

  private static get paymentClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  public static async getPaymentInformationByPaymentFlowId(paymentFlowId: string): Promise<PaymentInformation | never> {
    return await this.paymentClient.get<PaymentInformation>(`${this._apiDomain}/public/payments/${paymentFlowId}`).then((res) => {
      return res.data;
    });
  }

  public static async getPaymentInformationByVerificationId(verificationId: string): Promise<PaymentInformation | never> {
    return await this.paymentClient
      .get<PaymentInformation>(`${this._apiDomain}/public/payments/verification-id/${verificationId}`)
      .then((res) => res.data);
  }

  public static async cancelPayment(paymentFlowId: string, reason: CancelReason, otherReason: string): Promise<boolean> {
    try {
      let res = await this.paymentClient({
        method: 'POST',
        url: `${this._apiDomain}/public/payments/${paymentFlowId}/_cancel`,
        data: {
          reason: reason,
          customReason: otherReason,
        },
      });

      return res.status === 201;
    } catch {
      return false;
    }
  }

  public static async restartPayment(paymentId: string): Promise<boolean> {
    try {
      let res = await this.paymentClient({
        method: 'POST',
        url: `${this._apiDomain}/public/payments/${paymentId}/_retry`,
      });

      return res.status === 201;
    } catch {
      return false;
    }
  }

  public static async payManually(paymentId: string): Promise<boolean> {
    try {
      let res = await this.paymentClient({
        method: 'POST',
        url: `${this._apiDomain}/public/payments/${paymentId}/_paymanually`,
      });

      return res.status === 201;
    } catch {
      return false;
    }
  }

  public static async completeManually(paymentId: string): Promise<boolean> {
    try {
      let res = await this.paymentClient({
        method: 'POST',
        url: `${this._apiDomain}/public/payments/${paymentId}/_completemanually`,
      });

      return res.status === 201;
    } catch {
      return false;
    }
  }

  public static async searchForBanksWithPayment(paymentId: string, search: string, country: string): Promise<Bank[]> {
    let res = await this.paymentClient({
      method: 'GET',
      url: `${this._apiDomain}/public/payments/${paymentId}/bank-codes?q=${search}&c=${country}`,
    });

    return res.data;
  }

  public static async searchForBanksWithSetup(setupId: string, search: string, country: string): Promise<Bank[]> {
    let res = await this.paymentClient({
      method: 'GET',
      url: `${this._apiDomain}/public/payments/setup-payment-methods/${setupId}/bank-codes?q=${search}&c=${country}`,
    });

    return res.data;
  }

  public static async updateProject(paymentId: string, projectId: string): Promise<boolean> {
    let res = await this.paymentClient({
      method: 'PATCH',
      url: `${this._apiDomain}/public/payments/${paymentId}`,
      data: {
        projectId: projectId,
      },
    });
    return res.status === 200;
  }

  public static async getPaymentMethodSetUp(setupId: string): Promise<DebtorPaymentMethodSetup | never> {
    try {
      let res = await this.paymentClient.get<DebtorPaymentMethodSetup>(`${this._apiDomain}/public/payments/setup-payment-methods/${setupId}`);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('response not ok');
      }
    } catch (error) {
      throw error;
    }
  }

  public static async setPaymentMethod(setupId: string, data: SetupPaymentMethodData): Promise<SetupPaymentMethod | never> {
    try {
      let res = await this.paymentClient.post<SetupPaymentMethod>(`${this._apiDomain}/public/payments/setup-payment-methods/${setupId}`, data);

      if (res.status === 201) {
        return res.data;
      } else {
        throw new Error('response not ok');
      }
    } catch (error) {
      throw error;
    }
  }

  public static async updateProjectSetup(setupId: string, projectId: string): Promise<boolean> {
    let res = await this.paymentClient({
      method: 'PATCH',
      url: `${this._apiDomain}/public/payments/setup-payment-methods/${setupId}/projects`,
      data: {
        projectId: projectId,
      },
    });

    return res.status === 200;
  }
}
