import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Project } from '../../types/Project';
import PublicPaymentService from '../../services/PublicPaymentService';
import { ErrorCodes } from '../Error/ErrorPage';
import useErrorHandler from '../Error/ErrorHandler';
import StatusIcon from '../../components/StatusIcon';
import SocialCashback from '../../components/SocialCashback/SocialCashback';
import { WithPaymentMethod } from './WithPaymentMethod';

export default function OnboardingSuccessful({ variant }: { variant: string | null }): ReactElement {
  const { t } = useTranslation('global');
  const { setupId } = useContext(WithPaymentMethod);
  const handleGenericError = useErrorHandler();

  const onSelectProject = (project: Project) => {
    PublicPaymentService.updateProjectSetup(setupId, project.id).then((res) => {
      if (!res) {
        handleGenericError(new Error('PublicPaymentService.updateProject failed'), ErrorCodes.Project_update_failed);
      }
    });
  };

  return (
    <>
      <StatusIcon
        variantIcon="up"
        variant="success"
        iconSize="large"
        title={variant === 'bd' || variant === 'bdm' ? t('success.direct_debit_set_up') : t('success.online_payment_set_up')}
      >
        <Typography variant="body2" style={{ marginBottom: '2rem' }}>
          {variant === 'bd' || variant === 'bdm' ? t('success.direct_debit_set_up_subtitle') : t('success.online_payment_set_up_subtitle')}
        </Typography>
      </StatusIcon>
      <SocialCashback onSelectProject={onSelectProject} variant="onboarding" />
    </>
  );
}
