import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContainerStyles } from '../../components/Container';
import { Button } from '@payactive/app-common';
import WithCheckout from './WithCheckout';
import Dialog from '../../components/Dialog';
import { PaymentMethod } from '../../types/PaymentMethod';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
  },
  textField: {
    backgroundColor: '#E6E6E6',
    marginBottom: '1.25rem',
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      color: theme.palette.secondary.main,
    },
  },
}));

interface CheckoutUserSummaryProps {
  onBack: () => void;
  paymentMethod: PaymentMethod;
}

export default function CheckoutUserSummary({ onBack, paymentMethod }: CheckoutUserSummaryProps): ReactElement {
  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation('global');
  const classes = useStyles();
  const containerClasses = useContainerStyles();
  const { checkoutInformation } = useContext(WithCheckout);

  return (
    <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
      <Grid direction="column">
        <Grid>
          <Typography variant="h6">
            <b>{t('onboarding_direct.summary_title')}</b>
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 300 }}>
            {t('onboarding_direct.summary_subtitle')} <Link href={'mailto:help@payactive.eu'}>{t('onboarding_direct.support')}</Link>
          </Typography>
        </Grid>
        <Grid className={classes.paper}>
          <TextField
            fullWidth
            className={classes.textField}
            variant="outlined"
            label={t('onboarding_direct.id')}
            value={checkoutInformation.creditorIdentifier}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            fullWidth
            className={classes.textField}
            variant="outlined"
            label={t('onboarding_direct.company_id')}
            value={checkoutInformation.companyName}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <div className={containerClasses.horizontalContainer}>
          <Link
            style={{ marginLeft: '1rem' }}
            color="secondary"
            type="button"
            onClick={() => (paymentMethod === PaymentMethod.DIRECT_DEBIT ? setIsOpen(true) : onBack())}
          >
            {t('links.come_back')}
          </Link>
          <Button
            icon={'lock_icon'}
            type="submit"
            style={{ width: 'fit-content' }}
            className={containerClasses.button}
            name="submit"
            label={t('buttons.give_direct_debit_mandate')}
          />
        </div>
      </Grid>

      {/* Dialog if you want to come back from the overview form */}
      <Dialog
        title={t('dialogs.cancel_setup')}
        close={() => {
          setIsOpen(false);
        }}
        open={isOpen}
      >
        <>
          <Typography variant="body2">{t('dialogs.cancel_setup_subtitle')}</Typography>
          <div className={containerClasses.horizontalContainer}>
            <Link style={{ marginRight: '1rem' }} color="secondary" type="button" onClick={() => setIsOpen(false)}>
              {t('links.come_back')}
            </Link>
            <Button
              onClick={() => {
                onBack();
              }}
              style={{ width: 'fit-content' }}
              className={containerClasses.button}
              name="submit"
              label={t('buttons.cancel_setup')}
            />
          </div>
        </>
      </Dialog>
    </div>
  );
}
