import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@payactive/app-common';
import { Link, Typography } from '@mui/material';
import ManualDetailsMenu from './ManuallyDetailsMenu';
import ManuallyUserForm from './ManuallyUserForm';
import UserSummary from '../UserSummary';
import { WithPaymentMethod } from '../../WithPaymentMethod';
import UserInformationForm from '../../UserInformationForm';
import { schema } from '../../Schemas';
import { Containerized, useContainerStyles } from '../../../../components/Container';
import PublicPaymentService from '../../../../services/PublicPaymentService';
import { ContactMethod } from '../../../../types/ContactMethod';
import { PaymentMethod } from '../../../../types/PaymentMethod';
import { FintecRiskResult } from '../../../../types/FintecRiskResult';
import { WithProgress } from '../../../WithProgressContext';
import { SetupPaymentMethodData } from '../../../../types/SetupPaymentMethodData';

enum STEPS {
  USER_BANK_INFORMATION,
  USER_INFORMATION,
  SUMMARY,
}

export default function Manually({ updateContainer }: Containerized) {
  const [currentStep, setCurrentStep] = useState(STEPS.USER_BANK_INFORMATION);

  const { setupId, paymentMethodSetUpValues, restartPath, setValidation } = useContext(WithPaymentMethod);
  const [values, setValues] = useState<any>();
  const { updatesProgress } = useContext(WithProgress);

  const containerClasses = useContainerStyles();
  const { t } = useTranslation('global');
  const schemas = schema(t)[currentStep];
  const navigate = useNavigate();

  const selectedPayment = PaymentMethod.DIRECT_DEBIT;

  useEffect(() => {
    updateContainer({
      title: <Typography variant="h6">{t('onboarding_direct.manual_title')}</Typography>,
      menu: currentStep !== STEPS.USER_BANK_INFORMATION ? <ManualDetailsMenu values={values} /> : undefined,
      bigIcon: false,
      menuOpen: currentStep === STEPS.USER_INFORMATION,
    });
  }, [updateContainer, t, values, currentStep]);

  const getContentByStep = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void): JSX.Element | undefined => {
    switch (currentStep) {
      case STEPS.USER_BANK_INFORMATION:
        updatesProgress(50);
        return <ManuallyUserForm updateFormikField={setFieldValue} />;
      case STEPS.USER_INFORMATION:
        updatesProgress(60);
        return <UserInformationForm />;
      case STEPS.SUMMARY:
        updatesProgress(80);
        return <UserSummary />;
      default:
    }
  };

  let filterValues;
  let filterValidation: FintecRiskResult;
  async function _submitForm(values: SetupPaymentMethodData, actions: FormikHelpers<SetupPaymentMethodData>) {
    filterValues = {
      address: {
        line: values.address.line,
        suffix: values.address.suffix,
        zipCode: values.address.zipCode,
        city: values.address.city,
        country: values.address.country,
      },
      accountHolder: values.accountHolder,
      bic: values.bic?.replace(/ /g, ''),
      contactMethod: values.contactMethod,
      iban: values.iban?.replace(/ /g, ''),
      paymentMethod: selectedPayment,
    };

    filterValidation = {
      iban: values.iban ? values.iban : '',
      bic: values.bic ? values.bic : '',
      account_holder: values.accountHolder ? values.accountHolder : '',
    };

    PublicPaymentService.setPaymentMethod(setupId, filterValues)
      .then((_) => {
        actions.setSubmitting(false);
        setValidation(filterValidation);
        navigate(`${restartPath}/success?f=bdm`);
      })
      .catch((error) => {
        actions.setStatus(error);
      });
    actions.setSubmitting(true);
  }

  const formInitialValues: SetupPaymentMethodData = {
    accountHolder: '',
    iban: '',
    bic: '',
    address: {
      line: paymentMethodSetUpValues.address?.line,
      suffix: paymentMethodSetUpValues.address?.suffix,
      zipCode: paymentMethodSetUpValues.address?.zipCode,
      city: paymentMethodSetUpValues.address?.city,
      country: paymentMethodSetUpValues.address?.country,
    },
    paymentMethod: selectedPayment,
    contactMethod: ContactMethod.EMAIL,
  };

  function _handleSubmit(values: SetupPaymentMethodData, actions: FormikHelpers<SetupPaymentMethodData>) {
    if (currentStep === STEPS.SUMMARY) {
      _submitForm(values, actions);
    } else {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  const comeBackLink = () => {
    if (currentStep === STEPS.USER_BANK_INFORMATION) {
      navigate(`${restartPath}/directDebit`);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Formik initialValues={formInitialValues} validationSchema={schemas} onSubmit={_handleSubmit}>
      {({ values, setFieldValue }: { values: any; setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void }) => {
        setValues(values);
        return (
          <Form>
            <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
              {getContentByStep(setFieldValue)}
              <div className={containerClasses.horizontalContainer}>
                <Link style={{ marginLeft: '1rem' }} color="secondary" type="button" onClick={comeBackLink}>
                  {t('links.come_back')}
                </Link>
                <Button
                  icon={currentStep === STEPS.SUMMARY ? 'lock_icon' : ''}
                  type="submit"
                  style={{ width: 'fit-content' }}
                  className={containerClasses.button}
                  name="submit"
                  label={
                    currentStep === STEPS.USER_BANK_INFORMATION
                      ? t('buttons.to_the_billing')
                      : currentStep === STEPS.USER_INFORMATION
                      ? t('buttons.to_the_summary')
                      : t('buttons.give_direct_debit_mandate')
                  }
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
