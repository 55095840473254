import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@payactive/app-common';
import { Link, Typography } from '@mui/material';
import WithPayment from '../WithPayment';
import useErrorHandler from '../../Error/ErrorHandler';
import { ErrorCodes } from '../../Error/ErrorPage';
import BankChooser from '../../../components/BankChooser';
import Dialog from '../../../components/Dialog';
import BankAvatar from '../../../components/BankAvatar';
import FintecDialog from '../../../components/FintecDialog';
import { PaymentFlowPaths } from '../PaymentFlow';
import { Bank } from '../../../types/Bank/Bank';
import { Containerized, useContainerStyles } from '../../../components/Container';
import FintecService from '../../../services/FintecService';
import LoadingSpinnerDiv from '../../../components/loadingSpinnerDiv';
import { WithProgress } from '../../WithProgressContext';

const OnlinePayment = ({ updateContainer }: Containerized) => {
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [isOpenFintecDialog, setOpenFintecDialog] = useState(false);
  const [isCancelDialogOpen, setOpenCancelDialog] = useState(false);
  const [wizardKey, setWizardKey] = useState<string>();
  const [loading, setLoading] = useState<'intro' | 'outro'>();

  const { paymentId, restartPath, reloadPaymentInformation } = useContext(WithPayment);
  const containerClasses = useContainerStyles();
  const handleGenericError = useErrorHandler();

  const { t } = useTranslation('global');
  const navigate = useNavigate();

  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    updatesProgress(40);
  }, [updatesProgress]);

  useEffect(() => {
    updateContainer({ bigIcon: false });
  }, [updateContainer]);

  const openFintecDialog = () => {
    setLoading('intro');
    FintecService.initPayment(paymentId, selectedBank?.bic)
      .then((fintecInit) => {
        setWizardKey(fintecInit.wizard_session_key);
      })
      .catch(() => {
        handleGenericError(new Error('PublicPaymentService.reloadPayment failed'), ErrorCodes.Payment_get_failed);
      });
  };

  const resetDialog = () => {
    setOpenFintecDialog(false);
    setWizardKey(undefined);
    setLoading(undefined);
  };

  return (
    <>
      <div style={{ display: 'none' } /* preload spinner for slow connections */}>{<LoadingSpinnerDiv />}</div>
      {loading ? (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
          <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
            <Typography variant={'h6'} style={{ margin: '0 0 1rem' }}>
              {loading === 'intro' ? t('fintec.secure_connection_setup') : t('fintec.payment_is_executed')}
            </Typography>
            {selectedBank && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                <BankAvatar bank={selectedBank} />
                &nbsp;{selectedBank.name}
              </div>
            )}
            {<LoadingSpinnerDiv />}
          </div>
        </div>
      ) : (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Typography variant={'h6'} style={{ margin: '0 0 1rem' }}>
            {t('fintec.choose_your_bank')}
          </Typography>
          <BankChooser setBank={setSelectedBank} />
          <div className={containerClasses.horizontalContainer}>
            <Link
              color="secondary"
              style={{ margin: '0 1rem 0' }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {t('links.come_back')}
            </Link>
            <Button
              disabled={selectedBank === undefined}
              className={[containerClasses.button, containerClasses.w50].join(' ')}
              name="submit"
              fullWidth
              label={t('buttons.to_bank_login')}
              icon="lock"
              onClick={() => openFintecDialog()}
            />
          </div>
        </div>
      )}

      {wizardKey && (
        <Dialog
          style={{ display: isOpenFintecDialog ? 'inherit' : 'none' }}
          xIcon={true}
          title={
            selectedBank && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <BankAvatar bank={selectedBank} />
                &nbsp;{selectedBank.name}
              </div>
            )
          }
          open={true}
          close={() => {
            setOpenCancelDialog(true);
          }}
        >
          <FintecDialog
            variant="online"
            wizardKey={wizardKey}
            close={() => {
              resetDialog();
            }}
            loaded={() => {
              setOpenFintecDialog(true);
            }}
            success={() => {
              setLoading('outro');
              setOpenFintecDialog(false);
              setTimeout(() => {
                reloadPaymentInformation().then(() => {
                  navigate(`${restartPath}${PaymentFlowPaths.success}?f=o`);
                });
              }, 2000);
            }}
          />
        </Dialog>
      )}

      {isCancelDialogOpen && (
        <Dialog title={t('dialogs.cancel_online_payment_title')} open={isCancelDialogOpen}>
          <>
            <Typography variant={'body2'}>{t('dialogs.cancel_online_payment_subtitle')}</Typography>
            <div className={containerClasses.horizontalContainer}>
              <Link
                color="secondary"
                style={{ margin: '0 1rem 0' }}
                onClick={() => {
                  setOpenCancelDialog(false);
                }}
              >
                {t('links.come_back')}
              </Link>
              <Button
                disabled={selectedBank === undefined}
                className={[containerClasses.button, containerClasses.w50].join(' ')}
                name="submit"
                fullWidth
                label={t('buttons.cancel_payment')}
                onClick={() => navigate(restartPath)}
              />
            </div>
          </>
        </Dialog>
      )}
    </>
  );
};

export default OnlinePayment;
