import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';
import { Button } from '@payactive/app-common';
import UserSummary from './UserSummary';
import DirectDebitDetailsMenu from './DirectDebitDetailsMenu';
import { schema } from '../Schemas';
import UserInformationForm from '../UserInformationForm';
import useErrorHandler from '../../Error/ErrorHandler';
import { ErrorCodes } from '../../Error/ErrorPage';
import BankAvatar from '../../../components/BankAvatar';
import BankChooser from '../../../components/BankChooser';
import { Containerized, useContainerStyles } from '../../../components/Container';
import Dialog from '../../../components/Dialog';
import FintecService from '../../../services/FintecService';
import { WithPaymentMethod } from '../WithPaymentMethod';
import FintecDialog from '../../../components/FintecDialog';
import PublicPaymentService from '../../../services/PublicPaymentService';
import { PaymentMethod } from '../../../types/PaymentMethod';
import { ContactMethod } from '../../../types/ContactMethod';
import { Bank } from '../../../types/Bank/Bank';
import { FintecRiskResult } from '../../../types/FintecRiskResult';
import LoadingSpinnerDiv from '../../../components/loadingSpinnerDiv';
import { WithProgress } from '../../WithProgressContext';
import { SetupPaymentMethodData } from '../../../types/SetupPaymentMethodData';

const useStyles = makeStyles((_) => ({
  warningContainer: {
    display: 'flex',
    backgroundColor: '#DDAF3B',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
  },
}));

export default function DirectDebit({ updateContainer }: Containerized): ReactElement {
  enum STEPS {
    EMPTY,
    USER_INFORMATION,
    SUMMARY,
  }

  const [currentStep, setCurrentStep] = useState(STEPS.EMPTY);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenManualDialog, setIsOpenManualDialog] = React.useState(false);
  const [isOpenFintecDialog, setOpenFintecDialog] = useState(false);
  const [isCancelDialogOpen, setOpenCancelDialog] = useState(false);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [wizardKey, setWizardKey] = useState<string>();
  const [loading, setLoading] = useState<'intro' | 'outro'>();
  const [accountValidation, setAccountValidation] = useState<FintecRiskResult>();

  const { setupId, paymentMethodSetUpValues, restartPath, setValidation, validation } = useContext(WithPaymentMethod);
  const { updatesProgress } = useContext(WithProgress);
  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const { t } = useTranslation('global');
  const selectedPayment = PaymentMethod.DIRECT_DEBIT;
  const schemas = schema(t)[STEPS.USER_INFORMATION];
  const navigate = useNavigate();
  const handleGenericError = useErrorHandler();

  useEffect(() => {
    if (currentStep === STEPS.EMPTY) {
      updatesProgress(40);
    }
  }, [updatesProgress, currentStep]);

  useEffect(() => {
    updateContainer({
      title: <Typography variant="h6">{t('onboarding_direct.title')}</Typography>,
      menu: validation ? <DirectDebitDetailsMenu /> : undefined,
      bigIcon: false,
      menuOpen: !!validation && currentStep !== STEPS.SUMMARY,
    });
  }, [updateContainer, validation, t, currentStep]);

  const openFintecDialog = () => {
    setLoading('intro');
    FintecService.initAccountValidation(selectedBank?.bic)
      .then((wizard_session_key) => {
        setWizardKey(wizard_session_key);
      })
      .catch(() => {
        handleGenericError(new Error('PublicPaymentService.reloadPayment failed'), ErrorCodes.SetupId_not_found);
      });
  };

  const resetDialog = () => {
    setOpenFintecDialog(false);
    setWizardKey(undefined);
    setLoading(undefined);
  };

  const onFinish = () => {
    setLoading('outro');
    setOpenFintecDialog(false);
    if (wizardKey !== undefined) {
      FintecService.fetchAccountValidation(wizardKey)
        .then((res) => {
          setAccountValidation(res);
          setValidation(res);
          setCurrentStep(currentStep + 1);
          setLoading(undefined);
        })
        .catch((e) => {
          handleGenericError(e);
        });
    } else {
      //should be caught in useEffect init
      handleGenericError(new Error('wizardSessionKey is missing'));
    }
  };

  const getContentByStep = (): JSX.Element | undefined => {
    switch (currentStep) {
      case STEPS.USER_INFORMATION:
        updatesProgress(75);
        return <UserInformationForm />;
      case STEPS.SUMMARY:
        updatesProgress(85);
        return <UserSummary />;
      default:
    }
  };

  function _handleSubmit(values: SetupPaymentMethodData, actions: FormikHelpers<SetupPaymentMethodData>) {
    if (currentStep === STEPS.SUMMARY) {
      _submitForm(values, actions);
    } else {
      setCurrentStep(STEPS.SUMMARY);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  let filterValues;
  async function _submitForm(values: SetupPaymentMethodData, actions: FormikHelpers<SetupPaymentMethodData>) {
    filterValues = {
      address: {
        line: values.address.line,
        suffix: values.address.suffix,
        zipCode: values.address.zipCode,
        city: values.address.city,
        country: values.address.country,
      },
      paymentMethod: selectedPayment,
      accountHolder: values.accountHolder,
      desiredPaymentTerm: values.desiredPaymentTerm,
      contactMethod: values.contactMethod,
      iban: values.iban?.replace(/ /g, ''),
      bic: values.bic?.replace(/ /g, ''),
      wizardSessionKey: wizardKey,
    };
    PublicPaymentService.setPaymentMethod(setupId, filterValues)
      .then((_) => {
        actions.setSubmitting(false);
        navigate(`${restartPath}/success?f=bd`);
      })
      .catch((error) => {
        actions.setStatus(error);
      });
    actions.setSubmitting(true);
  }

  const comeBackLink = () => {
    if (currentStep === STEPS.USER_INFORMATION) {
      setIsOpen(true);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const formInitialValues: SetupPaymentMethodData = {
    accountHolder: accountValidation?.account_holder,
    iban: accountValidation?.iban,
    bic: accountValidation?.bic,
    wizardSessionKey: wizardKey,
    address: {
      line: paymentMethodSetUpValues.address?.line,
      suffix: paymentMethodSetUpValues.address?.suffix,
      zipCode: paymentMethodSetUpValues.address?.zipCode,
      city: paymentMethodSetUpValues.address?.city,
      country: paymentMethodSetUpValues.address?.country,
    },
    paymentMethod: selectedPayment,
    desiredPaymentTerm: 0,
    contactMethod: ContactMethod.EMAIL,
  };

  return (
    <>
      <div style={{ display: 'none' } /* preload spinner for slow connections */}>{<LoadingSpinnerDiv />}</div>
      {loading ? (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
          <div className={[containerClasses.contentContainer, containerClasses.whiteContainer, 'pa-container'].join(' ')}>
            <Typography variant={'h6'} style={{ margin: '0 0 1rem' }}>
              {loading === 'intro' ? t('fintec.secure_connection_setup') : t('fintec.account_will_be_verified')}
            </Typography>
            {selectedBank && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                <BankAvatar bank={selectedBank} />
                &nbsp;{selectedBank.name}
              </div>
            )}
            {<LoadingSpinnerDiv />}
          </div>
        </div>
      ) : !loading && currentStep === STEPS.EMPTY ? (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Typography variant={'h6'} style={{ margin: '0 0 1rem' }}>
            {t('fintec.choose_your_bank')}
          </Typography>
          <BankChooser setBank={setSelectedBank} />
          <div className={containerClasses.horizontalContainer}>
            <Link
              color="secondary"
              style={{ margin: '0 1rem 0' }}
              onClick={() => {
                navigate(restartPath);
              }}
            >
              {t('links.come_back')}
            </Link>
            <Button
              disabled={selectedBank === undefined}
              className={[containerClasses.button, containerClasses.w50].join(' ')}
              name="submit"
              fullWidth
              label={t('buttons.to_bank_login')}
              icon="lock"
              onClick={() => openFintecDialog()}
            />
          </div>
          <Link
            color="secondary"
            style={{ margin: '0 1rem 0', textAlignLast: 'left' }}
            onClick={() => {
              setIsOpenManualDialog(true);
              // navigate(`${restartPath}/manually`);
            }}
          >
            {t('links.manual_activation')}
          </Link>
        </div>
      ) : (
        ''
      )}

      {(currentStep === STEPS.USER_INFORMATION || currentStep === STEPS.SUMMARY) && (
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Formik initialValues={formInitialValues} validationSchema={schemas} onSubmit={_handleSubmit}>
            {() => (
              <Form>
                {getContentByStep()}
                <div className={containerClasses.horizontalContainer}>
                  <Link style={{ marginLeft: '1rem' }} color="secondary" type="button" onClick={comeBackLink}>
                    {currentStep === STEPS.USER_INFORMATION ? t('links.cancel') : t('links.come_back')}
                  </Link>
                  <Button
                    icon={currentStep === STEPS.SUMMARY ? 'lock_icon' : ''}
                    type="submit"
                    style={{ width: 'fit-content' }}
                    className={containerClasses.button}
                    name="submit"
                    label={currentStep === STEPS.USER_INFORMATION ? t('buttons.to_the_summary') : t('buttons.give_direct_debit_mandate')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {/* dialog to make direct debit manually */}
      <Dialog
        title={t('dialogs.manual_activation')}
        close={() => {
          setIsOpenManualDialog(false);
        }}
        open={isOpenManualDialog}
      >
        <>
          <div className={classes.warningContainer}>
            <WarningIcon style={{ marginRight: '0.6rem' }} />
            <Typography variant="body2">
              <b>{t('onboarding_direct.note')}</b>
              {t('onboarding_direct.manual_warning1')}
              <Link
                style={{ color: 'white', textDecoration: 'underline' }}
                onClick={() => {
                  setIsOpenManualDialog(false);
                }}
              >
                <b>{t('onboarding_direct.instant_verification')}</b>
              </Link>
              {t('onboarding_direct.manual_warning2')}
            </Typography>
          </div>
          <Typography variant="body2">{t('dialogs.manual_activation_subtitle')}</Typography>
          <div className={containerClasses.horizontalContainer}>
            <Link style={{ marginRight: '1rem' }} color="secondary" type="button" onClick={() => setIsOpenManualDialog(false)}>
              {t('links.come_back')}
            </Link>
            <Button
              onClick={() => {
                navigate(`${restartPath}/manually`);
              }}
              style={{ width: 'fit-content' }}
              className={containerClasses.button}
              name="submit"
              label={t('buttons.cancel_setup')}
            />
          </div>
        </>
      </Dialog>

      {/* fintec dialog */}
      {wizardKey && (
        <Dialog
          style={{ display: isOpenFintecDialog ? 'inherit' : 'none' }}
          xIcon={true}
          title={
            selectedBank && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <BankAvatar bank={selectedBank} />
                &nbsp;{selectedBank.name}
              </div>
            )
          }
          open={true}
          close={() => {
            setOpenCancelDialog(true);
          }}
        >
          <FintecDialog
            variant="direct"
            wizardKey={wizardKey}
            close={() => {
              resetDialog();
            }}
            loaded={() => {
              setOpenFintecDialog(true);
            }}
            success={onFinish}
          />
        </Dialog>
      )}

      {/* dialog when cancel Fintec flow */}
      {isCancelDialogOpen && (
        <Dialog title={t('dialogs.cancel_fintec_setup')} open={isCancelDialogOpen}>
          <>
            <Typography variant={'body2'}>{t('dialogs.cancel_fintec_setup_subtitle')}</Typography>
            <div className={containerClasses.horizontalContainer}>
              <Link
                color="secondary"
                style={{ margin: '0 1rem 0' }}
                onClick={() => {
                  setOpenCancelDialog(false);
                }}
              >
                {t('links.come_back')}
              </Link>
              <Button
                disabled={selectedBank === undefined}
                className={[containerClasses.button, containerClasses.w50].join(' ')}
                name="submit"
                fullWidth
                label={t('buttons.cancel_setup')}
                onClick={() => navigate(restartPath)}
              />
            </div>
          </>
        </Dialog>
      )}

      {/* dialog when cancel User-Information form */}
      <Dialog
        title={t('dialogs.cancel_setup')}
        close={() => {
          setIsOpen(false);
        }}
        open={isOpen}
      >
        <>
          <Typography variant="body2">{t('dialogs.cancel_setup_subtitle')}</Typography>
          <div className={containerClasses.horizontalContainer}>
            <Link style={{ marginRight: '1rem' }} color="secondary" type="button" onClick={() => setIsOpen(false)}>
              {t('links.come_back')}
            </Link>
            <Button
              onClick={() => {
                navigate(restartPath);
              }}
              style={{ width: 'fit-content' }}
              className={containerClasses.button}
              name="submit"
              label={t('buttons.cancel_setup')}
            />
          </div>
        </>
      </Dialog>
    </>
  );
}
