import { Autocomplete, Box, TextField } from '@mui/material';
import { countries, CountryType } from '../types/Country';
import { FormikInputField } from '@payactive/app-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

export default function CountrySelect({
  disabled = false,
  name,
  value,
  label,
  className,
  required = false,
  classes,
}: {
  disabled?: boolean;
  required?: boolean;
  name: string;
  value?: CountryType;
  label: string;
  className?: string;
  classes: { input: string };
}) {
  const { i18n } = useTranslation();
  const { setFieldValue } = useFormikContext<any>();

  const lang = i18n.resolvedLanguage;
  let sortedCountries: CountryType[];
  if (lang === 'de') {
    sortedCountries = countries.sort((a, b) => a.labelDe.localeCompare(b.labelDe));
  } else {
    sortedCountries = countries.sort((a, b) => a.labelEn.localeCompare(b.labelEn));
  }
  return (
    <Autocomplete
      id="country-select"
      options={sortedCountries}
      autoHighlight
      getOptionLabel={(option: CountryType) => (lang === 'de' ? option.labelDe : option.labelEn)}
      onChange={(_event, value) => {
        setFieldValue(name, value?.code);
      }}
      value={value}
      renderOption={(props, option: CountryType) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt={option.code}
          />
          {lang === 'de' ? option.labelDe : option.labelEn} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <FormikInputField
          className={[className, classes.input].join(' ')}
          {...params}
          label={label}
          name={name}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          required={required}
        />
      )}
      disabled={disabled}
    />
  );
}
