import React, { ReactNode, useState } from 'react';
import { FormikInputField } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import LoadingButton from '../LoadingButton';
import { useContainerStyles } from '../Container';
import * as Yup from 'yup';
import OtpVerificationDialog from './OtpVerificationDialog';

const useStyles = makeStyles((theme) => ({
  input: {
    alignItems: 'flex-start',
    margin: '1rem 0rem',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

type InitialValues = FormikValues & {
  debtorEmailAddress: string;
};

export default function OtpChallengeForm({ onSuccess, description }: { onSuccess: () => void; description?: string | ReactNode }) {
  const { t } = useTranslation('global');
  const [isVerificationDialogOpen, setIsVerificationDialogOpen] = useState(false);
  const containerClasses = useContainerStyles();
  const classes = useStyles();

  const schema = Yup.object().shape({
    debtorEmailAddress: Yup.string()
      .email(t('user_form.invalid_email'))
      .required(t('user_form.required', { field: t('emailAddress') })),
  });

  const initialValues: InitialValues = {
    debtorEmailAddress: localStorage.getItem('emailAddress') || '',
    verificationCode: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={() => setIsVerificationDialogOpen(true)}>
      {({
        values,
        isValid,
        setFieldValue,
      }: {
        values: InitialValues;
        isValid: boolean;
        setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
      }) => (
        <>
          <OtpVerificationDialog
            isOpen={isVerificationDialogOpen}
            onClose={() => setIsVerificationDialogOpen(false)}
            onSuccess={onSuccess}
            emailAddress={values.debtorEmailAddress}
          />
          <Form>
            <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
              <Typography variant="h6">
                <b>{t('otp.your_emailAddress')}</b>
              </Typography>
              <FormikInputField
                name="debtorEmailAddress"
                onChange={(e) => {
                  setFieldValue('debtorEmailAddress', e.target.value);
                }}
                label={t('otp.emailAddress')}
                required
                fullWidth
                className={classes.input}
              />
            </div>
            <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
              <Typography variant="body2"> {description ? description : t('otp.general_description')} </Typography>
              <LoadingButton disabled={!isValid} style={{ width: 'fit-content' }} className={containerClasses.button} loading={false}>
                {t('buttons.next')}
              </LoadingButton>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
