import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { formatDate } from '../../utils/DateFormatUtils';
import { Subscriptions } from '../../types/Subscription/Subscription';

export default function schema(t: TFunction) {
  return Yup.object().shape({
    cancellationType: Yup.mixed().oneOf(Object.values(Subscriptions.CancellationType)).required(t('customer_portal.required')),
    cancellationReason: Yup.string().optional(),
    cancellationTargetDate: Yup.string()
      .test('cancellationTargetDate', t('customer_portal.invalid_date_format'), (value) => {
        if (!value) return false;
        try {
          let date = new Date(value);
          if (date.getTime() < new Date().setHours(0, 0, 0, 0)) return false;
          formatDate(value);
          return true;
        } catch (e) {
          return false;
        }
      })
      .required(t('customer_portal.required')),
  });
}
