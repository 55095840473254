import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import './PaymentApp.css';
import { ErrorCodes, ErrorPage } from './Error/ErrorPage';
import PaymentFlowRouter from './PaymentFlow/PaymentFlow';
import PaymentMethodSetupFlow from './OnboardingFlow/OnboardingFlow';
import Verification from './VerificationFlow/Verification';
import { reactPlugin } from '../services/AppInsights';
import '../services/i18n';
import { themeOptions } from '../global/ThemeOption';
import WithProgressContext from './WithProgressContext';
import CheckoutFlowRouter from './CheckoutFlow/CheckoutFlow';
import DebtorPortalRouter from './DebtorPortal/DebtorPortalRouter';
import SocialCashbackFlow from './SocialCashbackFlow/SocialCashbackFlow';
import PaymentResolutionFlow from './PaymentResolution/PaymentResolutionFlow';
import { determineCaptchaSiteKey } from '../utils/RecaptchaUtils';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
});

export const newTheme = createTheme(themeOptions);
function PaymentApp() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={determineCaptchaSiteKey()}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <StylesProvider generateClassName={generateClassName}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={newTheme}>
              <CssBaseline />
              <WithProgressContext>
                <Router>
                  <div className="container d-flex align-items-center flex-column">
                    <Routes>
                      <Route path={'/payment/checkout/:paylinkExternalId/*'} element={<CheckoutFlowRouter />} />
                      <Route path={'/payment/verify'} element={<Verification />} />
                      <Route path={'/payment/verify/:verificationId'} element={<Verification />} />
                      <Route path={'/payment/socialCashback/:verificationId'} element={<SocialCashbackFlow />} />
                      <Route path={'/payment/:companyId/payment-method-setup/:setupId/*'} element={<PaymentMethodSetupFlow />} />
                      <Route path={'/payment/setup/:setupId/*'} element={<PaymentMethodSetupFlow />} />
                      <Route path={'/payment/:paymentId/*'} element={<PaymentFlowRouter />} />
                      <Route path={'/payment/resolution/:verificationId/*'} element={<PaymentResolutionFlow />} />
                      <Route path={'/payment/subscriptions/:companyId/*'} element={<DebtorPortalRouter />} />
                      <Route path={'/payment/error'} element={<ErrorPage errorCode={ErrorCodes.Payment_not_found_route} />} />
                      <Route path={'/*'} element={<ErrorPage errorCode={ErrorCodes.App_not_found} />} />
                    </Routes>
                  </div>
                </Router>
              </WithProgressContext>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </AppInsightsContext.Provider>
    </GoogleReCaptchaProvider>
  );
}

export default PaymentApp;
