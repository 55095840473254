import { ReactElement } from 'react';
import IconWithHeadline from './IconWithHeadline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Cancel';

type StatusIconProps = {
  title: ReactElement | string;
  variant?: 'success' | 'failed' | 'warning';
  variantIcon?: 'up' | 'down';
  children?: any;
  iconSize?: 'inherit' | 'large' | 'medium' | 'small';
};

const StatusIcon = ({ title, variant = 'success', variantIcon = 'down', iconSize, children }: StatusIconProps) => {
  let size = '4rem';
  switch (iconSize) {
    case 'small': {
      size = '1rem';
      break;
    }
    case 'medium': {
      size = '2rem';
      break;
    }
    case 'large': {
      size = '4rem';
      break;
    }
    case 'inherit': {
      size = 'inherit';
      break;
    }
  }

  let icon = <CheckCircleIcon style={{ color: '#20B39D', fontSize: size, marginTop: '1rem' }} />;
  switch (variant) {
    case 'failed': {
      icon = <ErrorIcon style={{ color: '#B83B86', fontSize: size, marginTop: '1rem' }} />;
      break;
    }
    case 'warning': {
      icon = <WarningIcon style={{ color: '#DDAF3B', fontSize: size, marginTop: '1rem' }} />;
      break;
    }
  }

  return (
    <IconWithHeadline title={title} variantIcon={variantIcon} icon={icon}>
      {children}
    </IconWithHeadline>
  );
};

export default StatusIcon;
