import { DialogContent, DialogContentText } from '@mui/material';
import Dialog from '../Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyName: string;
};

export default function AdvantagesDirectDebitDialog({ isOpen, onClose, companyName }: Props) {
  const { t } = useTranslation('global');

  return (
    <Dialog title={t('links.advantages_of_direct_debit')} xIcon={true} close={onClose} open={isOpen}>
      <DialogContent>
        <DialogContentText variant="body2">{t('dialogs.the_one_time_setup', { companyName: companyName })}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
