import React from 'react';
import Container, { useContainerStyles } from '../../components/Container';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import StatusIcon from '../../components/StatusIcon';
import CustomerThemeProvider, { WithCompanyBrandProvider } from '../../components/CustomerThemeProvider/CustomerThemeProvider';

export function ErrorPage({ errorCode }: { errorCode: string }) {
  const containerClasses = useContainerStyles();
  const ec = new URLSearchParams(window.location.search).get('e') || errorCode;

  const { t } = useTranslation('global');
  return (
    <WithCompanyBrandProvider>
      <CustomerThemeProvider>
        <Container title={<Typography variant={'h6'}>{t('error.error_headline')}</Typography>}>
          <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
            <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
              <StatusIcon title={t('error.error')} variant="failed" variantIcon="down" iconSize="large">
                <Typography variant={'body2'}>{t('error.problem_occurred')}</Typography>
              </StatusIcon>
            </div>
            <Typography variant={'body2'} style={{ marginBottom: '1rem' }}>
              {ec}
            </Typography>
          </div>
        </Container>
      </CustomerThemeProvider>
    </WithCompanyBrandProvider>
  );
}

export enum ErrorCodes {
  App_not_found = 'Px0010.0000',
  Verification_not_found = 'Px0020.0000',
  Payment_not_found_flow = 'Px0030.0000',
  Payment_not_found_route = 'Px0031.0000',
  Payment_not_found_init = 'Px0032.0000',
  Payment_get_failed = 'Px0032.0001',
  Payment_restart_failed = 'Px0032.0002',
  Payment_manually_failed = 'Px0032.0003',
  Payment_cancel_failed = 'Px0032.0004',
  SetupId_not_found = 'Px0040.0000',
  Bank_search_failed = 'Px0050.0001',
  Fintec_wizard_key = 'Px0050.0002',
  Payment_information_not_found = 'Px0060.0000',
  Verification_not_set = 'Px0060.0001',
  Project_update_failed = 'Px0070.0001',
  Project_fetch_failed = 'Px0070.0002',
  unexpected = 'Px0090.0001',
  Checkout_get_failed = 'Px0080.0001',
  Checkout_not_found_flow = 'Px0080.0002',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PaylinkExternalId_not_found = 'Cx0040.0000',
}
