import { Icon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { PaymentState } from '../types/PaymentState';

const useStyles = makeStyles((theme) => ({
  CREATING: {},
  PENDING: {
    color: theme.palette.warning.main,
  },
  COMPLETED: {
    color: theme.palette.success.main,
  },
  ABORTED: {
    color: theme.palette.error.main,
  },
  MANUAL: {
    color: theme.palette.warning.main,
  },
  CANCELLED: {
    color: theme.palette.text.primary,
  },
  REFUND_IN_PROGRESS: {
    color: theme.palette.text.primary,
  },
  ERROR: {
    color: theme.palette.error.main,
  },
  VERIFIED: {
    color: theme.palette.success.main,
  },
  REFUND_COMPLETED: {
    color: theme.palette.success.main,
  },
  CHARGED_BACK: { color: theme.palette.error.main },
}));

type StateWithIconProps = {
  state: PaymentState;
  label: string;
};
export default function StateWithIcon({ state, label }: StateWithIconProps) {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Icon className={classes[state]}>fiber_manual_record</Icon>
      <Typography variant="body2">{label || state}</Typography>
    </div>
  );
}
