import AdvantagesOnlinePayment from '../../global/AdvantagesOnlinePayment';
import Dialog from '../Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function AdvantagesOnlinePaymentDialog({ isOpen, onClose }: Props) {
  const { t } = useTranslation('global');

  return (
    <Dialog
      title={t('dialogs.advantages_online_payment_title')}
      xIcon={true}
      open={isOpen}
      close={() => {
        onClose();
      }}
    >
      <AdvantagesOnlinePayment />
    </Dialog>
  );
}
