import React, { useContext, useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Typography } from '@mui/material';
import { WithProgress } from '../WithProgressContext';
import WithCheckout from './WithCheckout';
import CheckoutDirectDebitMenu from './menus/CheckoutDirectDebitMenu';
import { schema } from './Schemas';
import UserFormInfoScreen from './UserFormInfoScreen';
import SuccessCheckout from './SuccessCheckout';
import { Containerized } from '../../components/Container';
import { DoCheckout } from '../../types/DoCheckout';
import PublicCheckoutService from '../../services/PublicCheckoutService';
import { STEPS, WithCustomerData } from './WithCustomerData';
import PreferDirectDebitScreen from './payments/PreferDirectDebitScreen';
import PayDirectDebitScreen from './payments/PayDirectDebitScreen';
import PayManuallyScreen from './payments/PayManuallyScreen';
import CheckoutUserSummary from './CheckoutUserSummary';
import { ContactMethod } from '../../types/ContactMethod';
import { FintecRiskResult } from '../../types/FintecRiskResult';
import CheckoutOnlineMenu from './menus/CheckoutOnlineMenu';
import { PaymentMethod } from '../../types/PaymentMethod';
import { formatCurrency } from '../../utils/NumberFormatUtil';
import PreferManuallyScreen from './payments/PreferManuallyScreen';
import { OtpSecured } from '../../components/Otp/OtpContextProvider';

/*
 * TODO: Refactor: too long, remove dependency for steps (its not react style)
 */

const CheckoutController = ({ updateContainer }: Containerized) => {
  const { quantity, totalPrice, productPrice, customFields, customerData, updateCustomerData, wizardSessionKey } = useContext(WithCustomerData);

  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.PAYMENT_METHOD);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.ONLINE_PAYMENT);
  const [responseSubmit, setResponseSubmit] = useState<DoCheckout>();
  const [accountValidation, setAccountValidation] = useState<FintecRiskResult>();

  const match = useMatch('/payment/checkout/:paylinkExternalId/*');
  const paylinkExternalId = match?.params.paylinkExternalId;
  const { checkoutInformation } = useContext(WithCheckout);

  const { t } = useTranslation('global');
  const schemas = schema(t, checkoutInformation)[currentStep];
  const { updatesProgress } = useContext(WithProgress);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const metadata = Object.fromEntries(new URLSearchParams(window.location.search));

  const isSubscription = checkoutInformation.product.billingType === 'RECURRING';

  useEffect(() => {
    const title = (
      <>
        <Typography variant="h6" style={{ marginRight: 'auto' }}>
          {checkoutInformation.product.name}
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h6">{formatCurrency(totalPrice)} </Typography>
          {isSubscription ? (
            <Typography variant={'body2'}>&nbsp;{t(`checkout.${checkoutInformation.product.recurringBillingPeriod}`)}</Typography>
          ) : (
            ''
          )}
        </div>
      </>
    );

    updateContainer({
      title: title,
      menu:
        paymentMethod === PaymentMethod.ONLINE_PAYMENT ? (
          <CheckoutOnlineMenu currentStep={currentStep} />
        ) : (
          <CheckoutDirectDebitMenu currentStep={currentStep} paymentMethod={paymentMethod} accountValidation={accountValidation} />
        ),
      bigIcon: false,
    });

    switch (currentStep) {
      case STEPS.PAYMENT_METHOD:
        updatesProgress(30);
        break;
      case STEPS.DIRECT_DEBIT:
        updatesProgress(50);
        break;
      case STEPS.DIRECT_DEBIT_MANUALLY:
        updatesProgress(60);
        break;
      case STEPS.SUMMARY:
        updatesProgress(70);
        break;
      case STEPS.USER_FORM:
        updatesProgress(80);
        break;
      case STEPS.SUCCESS:
        updatesProgress(100);
        localStorage.removeItem('customerData');
        break;
      default:
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContainer, currentStep, totalPrice]);

  const nextStep = (method: PaymentMethod) => {
    if (method === PaymentMethod.ONLINE_PAYMENT) {
      setPaymentMethod(PaymentMethod.ONLINE_PAYMENT);
      setCurrentStep(STEPS.USER_FORM);
    } else {
      setPaymentMethod(PaymentMethod.DIRECT_DEBIT);
      setCurrentStep(STEPS.DIRECT_DEBIT);
    }
  };

  const getContentByStep = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    isSubmitting: boolean,
  ): JSX.Element | undefined => {
    switch (currentStep) {
      case STEPS.PAYMENT_METHOD:
        if (isSubscription) {
          return <PreferDirectDebitScreen nextStep={(m) => nextStep(m)} />;
        } else {
          return <PreferManuallyScreen nextStep={(m) => nextStep(m)} />;
        }
      case STEPS.DIRECT_DEBIT:
        return (
          <PayDirectDebitScreen
            onManualSetup={() => {
              setCurrentStep(STEPS.DIRECT_DEBIT_MANUALLY);
              setPaymentMethod(PaymentMethod.DIRECT_DEBIT_MANUALLY);
            }}
            onBack={() => setCurrentStep(STEPS.PAYMENT_METHOD)}
            succeedFintec={() => {
              setPaymentMethod(PaymentMethod.DIRECT_DEBIT);
              setCurrentStep(STEPS.SUMMARY);
            }}
            setAccountValidation={setAccountValidation}
          />
        );
      case STEPS.DIRECT_DEBIT_MANUALLY:
        return <PayManuallyScreen onBack={() => setCurrentStep(STEPS.DIRECT_DEBIT)} setFieldValue={setFieldValue} />;
      case STEPS.SUMMARY:
        return (
          <CheckoutUserSummary
            paymentMethod={paymentMethod}
            onBack={() =>
              paymentMethod === PaymentMethod.DIRECT_DEBIT_MANUALLY ? setCurrentStep(STEPS.DIRECT_DEBIT_MANUALLY) : setCurrentStep(STEPS.DIRECT_DEBIT)
            }
          />
        );
      case STEPS.USER_FORM:
        return <UserFormInfoScreen loading={isSubmitting} />;
      case STEPS.SUCCESS:
        return <SuccessCheckout paymentMethod={paymentMethod} responseSubmit={responseSubmit} />;
      default:
    }
  };

  const _handleSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
    switch (currentStep) {
      case STEPS.USER_FORM:
        return submitForm(values, formikHelpers, wizardSessionKey);
      case STEPS.SUCCESS:
        return responseSubmit?.paymentLink && window.location.assign(responseSubmit.paymentLink);
      case STEPS.DIRECT_DEBIT_MANUALLY:
        updateCustomerData({
          accountHolder: values.accountHolder,
          iban: values.iban,
          bic: values.bic,
        });
        return setCurrentStep(STEPS.SUMMARY);
      case STEPS.SUMMARY:
        return setCurrentStep(STEPS.USER_FORM);
      default:
    }
  };

  const submitForm = async (values: any, formikHelpers: FormikHelpers<any>, wizardSessionKey: string) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      formikHelpers.setSubmitting(false);
      return;
    }
    if (values.debtor.type === 'PERSON') {
      delete values.debtor.companyName;
      delete values.debtor.vatId;
    }
    const req = {
      debtor: { ...values.debtor, emailAddress: localStorage.getItem('emailAddress') },
      token: await executeRecaptcha('checkout'),
      otpChallengeId: localStorage.getItem('challengeId'),
      invoiceAddress: checkoutInformation.requireAddress ? values.address : undefined,
      customFields: values.customFields,
      metadata: metadata,
      paymentMethod: paymentMethod,
      productPrice: productPrice,
      quantity: quantity,
      wizardSessionKey: wizardSessionKey,
      accountHolder: customerData.accountHolder,
      iban: customerData.iban,
      bic: customerData.bic,
    };
    const res = paylinkExternalId ? await PublicCheckoutService.doCheckout(paylinkExternalId, req) : null;
    if (res?.success) {
      setResponseSubmit(res.data);
      setCurrentStep(STEPS.SUCCESS);
    } else {
      formikHelpers.setStatus('Something went wrong');
    }
  };

  const initialValues: FormikValues = {
    accountHolder: '',
    iban: '',
    bic: '',
    paymentMethod: '',
    contactMethod: ContactMethod.EMAIL,
    productId: checkoutInformation.product.id,
    debtor: {
      firstName: '',
      lastName: '',
      companyName: '',
      vatId: '',
    },
    address: {
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      country: '',
    },
    customFields: customFields,
    totalCheckoutAmount: checkoutInformation.totalCheckoutAmount,
    subTotalCheckoutAmount: checkoutInformation.subTotalCheckoutAmount,
    totalTaxAmount: checkoutInformation.totalTaxAmount,
    quantity: quantity,
    metadata: metadata,
  };

  const navigate = useNavigate();
  customFields.forEach((customField) => {
    if (customField.required && (customField.fieldValue === null || (customField.fieldValue + '').trim() === '')) {
      navigate(`/payment/checkout/${paylinkExternalId}/start`);
    }
  });

  return (
    <OtpSecured description={t('checkout.button_emailAddress')}>
      <Formik initialValues={initialValues} validationSchema={schemas} onSubmit={_handleSubmit}>
        {({ setFieldValue, isSubmitting }) => <Form>{getContentByStep(setFieldValue, isSubmitting)}</Form>}
      </Formik>
    </OtpSecured>
  );
};

export default CheckoutController;
