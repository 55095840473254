import { InputAdornment } from '@mui/material';
import React, { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import AutoNumeric, { CallbackOptions } from 'autonumeric';
import { makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { FormikInputField } from '@payactive/app-common';

const useStyles = makeStyles((theme) => ({
  input: {
    alignItems: 'flex-start',
    margin: '1rem 0rem',
    '& .MuiInputBase-colorPrimary': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

interface Props {
  name: string;
  label: string;
  currencySymbol: string;
  value?: number;
  className?: string;
  style?: CSSProperties;
  required?: boolean;
  fullWidth?: boolean;
}

export default function CurrencyTextField({ name, label, currencySymbol, value, className, style, required, fullWidth }: Props) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const classes = useStyles();
  const currencyInputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;

  const [autoNumeric, setAutoNumeric] = useState<AutoNumeric>();

  useEffect(() => {
    let options: CallbackOptions = {
      ...AutoNumeric.getPredefinedOptions().commaDecimalCharDotSeparator,
      minimumValue: '0',
      caretPositionOnFocus: 'start',
      digitGroupSeparator: '',
    };
    let numeric = new AutoNumeric(currencyInputRef.current as HTMLElement, options);
    if (value) numeric.set(value);
    else numeric.set(name.split('.').reduce((o, i) => o[i], values)); // accesses paths in values
    setAutoNumeric(numeric);
  }, []);

  useEffect(() => {
    autoNumeric?.set(name.split('.').reduce((o, i) => o[i], values)); // accesses paths in values
  }, [values]);

  return (
    <>
      <FormikInputField
        name={name}
        value={autoNumeric?.getFormatted()}
        inputRef={currencyInputRef}
        label={label}
        className={[classes.input, className].join(' ')}
        style={style ? style : {}}
        onBlur={() => {
          setFieldValue(name, autoNumeric?.getNumber()).then(() => setFieldTouched(name, true, true));
        }}
        placeholder={'0,00'}
        required={required}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
        }}
        fullWidth={fullWidth}
      />
    </>
  );
}
