import React, { createContext, ReactElement, ReactNode, useEffect, useState } from 'react';
import OtpChallengeForm from './OtpChallengeForm';
import { CircularProgress, Grid } from '@mui/material';
import OtpService from '../../services/OtpService';
import Container, { useContainerStyles } from '../Container';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const OTPContext = createContext<{
  emailAddress: string | null;
  setEmailAddress: (emailAddress: string | null) => void;
  isLoggedIn: boolean;
  isLoading: boolean;
  setAccessData: (accessToken: string, emailAddress: string) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  clearAccessData: () => void;
}>({
  emailAddress: '',
  setEmailAddress: () => {},
  isLoggedIn: false,
  isLoading: true,
  setAccessData: () => {},
  setIsLoggedIn: () => {},
  clearAccessData: () => {},
});

export default function OtpContextProvider({ children }: { children: ReactNode }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [emailAddress, setEmailAddress] = useState(localStorage.getItem('emailAddress'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (executeRecaptcha) {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        executeRecaptcha('verify_otp_access_token').then((recaptchaToken) => {
          OtpService.verifyToken(recaptchaToken, accessToken)
            .then(({ isValid, error }) => {
              if (isValid) {
                setIsLoggedIn(true);
              } else {
                localStorage.removeItem('accessToken');
              }
            })
            .finally(() => setIsLoading(false));
        });
      } else setIsLoading(false);
    }
  }, [executeRecaptcha]); // waiting until Recaptcha is initialized...

  return (
    <OTPContext.Provider
      value={{
        emailAddress,
        setEmailAddress,
        isLoggedIn,
        isLoading,
        setIsLoggedIn,
        setAccessData: (accessToken, emailAddress) => {
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('emailAddress', emailAddress);
          setEmailAddress(emailAddress);
        },
        clearAccessData: () => {
          localStorage.removeItem('emailAddress');
          localStorage.removeItem('accessToken');
        },
      }}
    >
      {children}
    </OTPContext.Provider>
  );
}

const CheckContainer = ({ withContainer, children }: { withContainer: boolean; children: ReactElement }) => {
  if (withContainer) {
    return <Container>{children}</Container>;
  }
  return children;
};

export function OtpSecured({
  description,
  children,
  withContainer = false,
}: {
  description?: string | ReactNode;
  children: ReactNode;
  withContainer?: boolean;
}) {
  const containerClasses = useContainerStyles();

  return (
    <OTPContext.Consumer>
      {({ isLoggedIn, isLoading, setIsLoggedIn }) => (
        <>
          {isLoading ? (
            <CheckContainer withContainer={withContainer}>
              <Grid container className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </CheckContainer>
          ) : isLoggedIn ? (
            children
          ) : (
            <CheckContainer withContainer={withContainer}>
              <OtpChallengeForm onSuccess={() => setIsLoggedIn(true)} description={description} />
            </CheckContainer>
          )}
        </>
      )}
    </OTPContext.Consumer>
  );
}
