import React, { CSSProperties, ReactElement } from 'react';
import { default as MuiDialog } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Dialog = ({
  title,
  alert,
  xIcon,
  children,
  open = false,
  close,
  style,
}: {
  title?: ReactElement | string;
  alert?: ReactElement | undefined;
  xIcon?: boolean;
  children: ReactElement;
  open?: boolean;
  close?: () => void;
  style?: CSSProperties;
}) => {
  if (!open) {
    return <></>;
  }
  return (
    <MuiDialog style={style} fullWidth maxWidth="xs" open={open} onClose={close} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" sx={{ fontWeight: 500 }}>
        {alert}
        {title}
        {xIcon && (
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
