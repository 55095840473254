import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#262626',
    },
    secondary: {
      main: '#595959',
    },
    background: {
      default: '#f2f2f2',
      paper: '#ffffff',
    },
    error: {
      main: '#B83B86',
    },
    warning: {
      main: '#DDAF3B',
    },
    success: {
      main: '#11E0A0',
    },
    contrastThreshold: 2.75,
  },
  typography: {
    fontFamily: 'Noto Sans KR, sans-serif',
    button: {
      fontWeight: 300,
      textTransform: 'none',
    },
    body2: {
      fontSize: '0.8rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
    },
  },
};
