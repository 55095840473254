import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecurringBillingPeriod } from '../../types/CheckoutInformation';
import { formatCurrency } from '../../utils/NumberFormatUtil';
import { Subscriptions } from '../../types/Subscription/Subscription';

interface PriceSummaryProps {
  totalPrice: number;
  recurring: RecurringBillingPeriod | Subscriptions.Interval;
  tax: number;
  className?: string;
}

export default ({ totalPrice, recurring, tax, className }: PriceSummaryProps) => {
  const { t } = useTranslation('global');

  return (
    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} className={className}>
      <Grid item>
        <Typography variant={'caption'} component={'span'}>
          {t('checkout.total')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'h6'} component={'span'}>
          {formatCurrency(totalPrice)}
        </Typography>
        <Typography variant={'body2'} component={'span'}>
          &nbsp;
          {t(`checkout.${recurring}`)}
        </Typography>
      </Grid>
      {tax > 0 && (
        <Grid item>
          <Typography variant={'caption'} component={'span'}>
            {t('checkout.including_vat', { vat: formatCurrency(tax) })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
