import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import BankAvatar from './BankAvatar';
import WithPayment from '../pages/PaymentFlow/WithPayment';
import WithPaymentMethod from '../pages/OnboardingFlow/WithPaymentMethod';
import { Bank } from '../types/Bank/Bank';
import PublicPaymentService from '../services/PublicPaymentService';
import PublicCheckoutService from '../services/PublicCheckoutService';
import useErrorHandler from '../pages/Error/ErrorHandler';
import { ErrorCodes } from '../pages/Error/ErrorPage';
import { useMatch } from 'react-router';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.background.paper,
  },
  countrySelect: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'left',
    marginBottom: '1rem',
  },
  svg: {
    height: '1rem',
    margin: '0rem 0.85rem -0.2rem -0.2rem',
  },
}));

const BankChooser = ({ setBank }: { setBank: (bank: Bank | undefined) => void }) => {
  const [textFieldIcon, setTextFieldIcon] = useState(<SearchIcon />);
  const [options, setOptions] = React.useState<readonly Bank[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [country, setCountry] = React.useState('DE');

  const paymentId = useContext(WithPayment).paymentId;
  const setupId = useContext(WithPaymentMethod).setupId;
  const match = useMatch('/payment/checkout/:paylinkExternalId/*');
  const paylinkExternalId = match?.params.paylinkExternalId;

  const classes = useStyles();
  const { t } = useTranslation('global');
  const handleGenericError = useErrorHandler();

  const unify = (banks: Bank[]): Bank[] => {
    return _.uniqWith(banks, (arrVal: Bank, othVal: Bank) => {
      return arrVal.bic === othVal.bic && arrVal.name === othVal.name && arrVal.city === othVal.city;
    });
  };
  useEffect(() => {
    setLoading(true);
    setOptions([]);
    const handleError = (e: Error) => {
      handleGenericError(e, ErrorCodes.Bank_search_failed);
    };
    if (paymentId) {
      PublicPaymentService.searchForBanksWithPayment(paymentId, inputValue, country)
        .then((r) => setOptions(unify(r)))
        .catch(handleError)
        .finally(() => setLoading(false));
    } else if (setupId) {
      PublicPaymentService.searchForBanksWithSetup(setupId, inputValue, country)
        .then((r) => setOptions(unify(r)))
        .catch(handleError)
        .finally(() => setLoading(false));
    } else if (paylinkExternalId) {
      PublicCheckoutService.searchForBanksWithPayLinkExternalId(paylinkExternalId, inputValue, country)
        .then((r) => setOptions(unify(r)))
        .catch(handleError)
        .finally(() => setLoading(false));
    } else {
      handleError(new Error('calling without context'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, country, paymentId, setupId, paylinkExternalId]);

  const countryLabel = t('fintec.country');

  return (
    <>
      <FormControl>
        <InputLabel>{countryLabel}</InputLabel>
        <Select
          className={classes.countrySelect}
          value={country}
          label={countryLabel}
          onChange={(event: SelectChangeEvent, _) => {
            if (event.target.value !== country) {
              setCountry(event.target.value);
              setOptions([]);
              setInputValue('');
            }
          }}
        >
          <MenuItem value={'DE'}>
            <svg viewBox="0 0 640 480" className={classes.svg}>
              <path fill="#ffce00" d="M0 320h640v160H0z" />
              <path d="M0 0h640v160H0z" />
              <path fill="#d00" d="M0 160h640v160H0z" />
            </svg>
            {t('fintec.germany')}
          </MenuItem>
          <MenuItem value={'AT'}>
            <svg viewBox="0 0 640 480" className={classes.svg}>
              <g fillRule="evenodd">
                <path fill="#fff" d="M640 480H0V0h640z" />
                <path fill="#c8102e" d="M640 480H0V320h640zm0-319.9H0V.1h640z" />
              </g>
            </svg>
            {t('fintec.austria')}
          </MenuItem>
          <MenuItem value={'CH'}>
            <svg viewBox="0 0 640 480" className={classes.svg}>
              <g fillRule="evenodd" strokeWidth="1pt">
                <path fill="#d52b1e" d="M0 0h640v480H0z" />
                <g fill="#fff">
                  <path d="M170 195h300v90H170z" />
                  <path d="M275 90h90v300h-90z" />
                </g>
              </g>
            </svg>
            {t('fintec.switzerland')}
          </MenuItem>
        </Select>
      </FormControl>
      <Autocomplete
        disablePortal={true}
        autoComplete
        loading={loading}
        loadingText={t('fintec.loading')}
        noOptionsText={t('fintec.no_bank_found')}
        filterOptions={(x) => x}
        renderOption={(props, option) => {
          let o = option as Bank;
          return (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={o.bic + o.name + o.city}>
              <BankAvatar bank={o} sx={{ width: 24, height: 24 }} />
              <span style={{ textAlign: 'left', padding: '0 0 0 5px', fontSize: 'smaller' }}>
                {o.name}
                {o.city && ' (' + o.city + ')'}
                <br />({o.bic})
              </span>
            </Box>
          );
        }}
        getOptionLabel={(option) => {
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => {
          let o = option as Bank;
          let v = value as Bank;
          return o.bic === v.bic;
        }}
        onChange={(event, value, _) => {
          if (value) {
            let o = value as Bank;
            setTextFieldIcon(<BankAvatar bank={o} sx={{ width: 24, height: 24 }} />);
            setBank(o);
          } else {
            setTextFieldIcon(<SearchIcon />);
            setBank(undefined);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={t('online_payment.bank_name')}
              label={'Bank'}
              className={classes.input}
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position="start"> {textFieldIcon}</InputAdornment>,
              }}
            ></TextField>
          );
        }}
        options={options}
      />
    </>
  );
};

export default BankChooser;
