import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Link, Typography } from '@mui/material';
import { PaymentFlowPaths, PaymentPaths } from './PaymentFlow';
import WithPayment from './WithPayment';
import { Containerized, useContainerStyles } from '../../components/Container';
import { PaymentState } from '../../types/PaymentState';
import { WithProgress } from '../WithProgressContext';
import AdvantagesOnlinePaymentDialog from '../../components/Payment/AdvantagesOnlinePaymentDialog';

const InitPayment = ({ updateContainer }: Containerized) => {
  const [openPros, setOpenPros] = useState(false);

  const { paymentInformation, restartPath } = useContext(WithPayment);

  const containerClasses = useContainerStyles();

  useEffect(() => {
    updateContainer({ bigIcon: true });
  }, [updateContainer]);

  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    updatesProgress(20);
  }, [updatesProgress]);

  if ([PaymentState.COMPLETED, PaymentState.VERIFIED].includes(paymentInformation.state)) {
    return <Navigate to={`${restartPath}${PaymentPaths.success}?f=m`} />;
  }

  return (
    <>
      <>
        <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
          <Typography variant="body2">{t('online_payment.safe_and_easy')}</Typography>
          <Button
            className={containerClasses.button}
            name="submit"
            fullWidth
            label={t('buttons.pay_online')}
            icon="lock"
            onClick={() => {
              navigate(restartPath + PaymentFlowPaths.online);
            }}
          />
          <p>
            <Link
              color="secondary"
              onClick={() => {
                setOpenPros(true);
              }}
            >
              {t('links.init_payment')}
            </Link>
          </p>
        </div>
        <div className={containerClasses.contentContainer}>
          <Link color="secondary" onClick={() => navigate(restartPath + PaymentFlowPaths.manual)}>
            {t('links.pay_manually')}
          </Link>
        </div>
      </>
      <AdvantagesOnlinePaymentDialog isOpen={openPros} onClose={() => setOpenPros(false)} />
    </>
  );
};

export default InitPayment;
