import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Box, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { selectProject } from './projects';
import AvatarNumberList from '../AvatarNumberList';
import ProjectChooser from './ProjectChooser';
import ProjectCard from './ProjectCard';
import { Project } from '../../types/Project';
import SocialLogo from '../assets/scb-logo.png';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '30px 15px',
    color: 'white',
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
  spacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: 'white',
  },
  divider: {
    backgroundColor: 'white',
    width: '20%',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '1px',
  },
  box: {
    marginTop: '14pt',
    marginBottom: '14pt',
  },
  paper: {
    backgroundColor: '#00b1dd',
    color: '#FFFFFF',
    padding: '14pt',
  },
  links: {
    color: '#FFFFFF',
  },
  projectsButton: {
    marginTop: theme.spacing(2),
    padding: '0.5rem 1rem',
    color: 'white',
    border: ' solid white 1px',
    backgroundColor: '#0198B5',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#e1dddd',
    },
  },
  round: {
    background: 'white',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    alignItems: 'center',
    color: '#0198B5',
    textAlign: 'center',
    margin: 'auto',
    padding: '10px',
    fontSize: 'bold',
    position: 'absolute',
    top: '0',
    left: '0',
  },
  iconContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    margin: 'auto',
    width: '50px',
    height: '64px',
  },
  textOfCents: {
    position: 'absolute',
    top: '0px',
    left: '57px',
    fontSize: '1rem',
  },
}));

type SocialCashbackProps = {
  socialCashbackCents?: number;
  project?: Project;
  onSelectProject: (project: Project) => void;
  variant?: 'onlinePayment' | 'manual' | 'onboarding';
};

const SocialCashback = ({ socialCashbackCents, project, onSelectProject, variant }: SocialCashbackProps) => {
  const { t } = useTranslation('global');
  const classes = useStyles();

  const [openProjectChooser, setOpenProjectChooser] = useState(false);
  const [_project, setProject] = useState<Project | undefined>(project);

  useEffect(() => {
    if (!project) {
      selectProject().then((p) => {
        setProject(p);
        onSelectProject(p);
      });
    }
  }, []);

  const listEntries = [
    {
      text: t('social_cashback.list1'),
    },
    {
      text: t('social_cashback.list2'),
    },
    {
      text: t('social_cashback.list3'),
    },
  ];

  const onlinePayment = (
    <>
      <Typography variant="h6">{t('social_cashback.yes_thank_you')}</Typography>
      <img className={classes.spacing} src={SocialLogo} alt="logo" />
      <Typography variant="body2">{t('social_cashback.we_donate')}</Typography>
      <Typography variant="body2">{t('social_cashback.free_for_you')}</Typography>
      <div className={classes.iconContainer}>
        <Typography variant="h5" className={classes.spacing}>
          <p className={classes.round}>{socialCashbackCents}</p>
          <p className={classes.textOfCents}>
            <b>Cent</b>
          </p>

          <ArrowDropDownIcon fontSize="large" style={{ marginTop: '40px', width: '50px', color: 'white' }} />
        </Typography>
      </div>
    </>
  );

  const manual = (
    <>
      <Typography variant="h6">{t('social_cashback.one_moment')}</Typography>
      <Typography variant="body2">
        {t('social_cashback.you_know')}
        <b>{t('social_cashback.social_cashback')}</b>
      </Typography>
      <img className={classes.spacing} src={SocialLogo} alt="logo" />
      <Typography variant="body2">{t('social_cashback.do_good')}</Typography>
      <Typography variant="body2">
        <b>{t('social_cashback.free_for_you')}</b>
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="h6">{t('social_cashback.that_simple')}</Typography>
      <AvatarNumberList listEntries={listEntries} socialCashback={true} />
      <Typography className={classes.spacing} variant="h6">
        {t('social_cashback.example_project')}
      </Typography>
    </>
  );

  const onboarding = (
    <>
      <Typography variant="h6">{t('social_cashback.one_moment')}</Typography>
      <Typography variant="body2">
        {t('social_cashback.you_know')}
        <b>{t('social_cashback.social_cashback')}</b>
      </Typography>
      <img className={classes.spacing} src={SocialLogo} alt="logo" />
      <Typography variant="body2">{t('social_cashback.we_donate')}</Typography>
      <Typography variant="body2">
        <b>{t('social_cashback.free_for_you')}</b>
      </Typography>
      <Typography variant="body2">{t('social_cashback.an_amount')}</Typography>
      <Typography className={classes.spacingTop} variant="body2">
        <b>{t('social_cashback.select_now')}</b>
      </Typography>
      <Box>
        <Button
          className={classes.projectsButton}
          variant="text"
          name={t('social_cashback.select_a_project')}
          onClick={(e) => {
            e.preventDefault();
            setOpenProjectChooser(true);
          }}
        />
      </Box>
      <Typography className={classes.spacing} variant="body2">
        {t('social_cashback.preselected')}
      </Typography>
    </>
  );

  return (
    <>
      <div className={classes.container} style={{ background: '#0198B5', borderRadius: '5px', marginTop: variant === 'onlinePayment' ? '1rem' : '' }}>
        {variant === 'onlinePayment' ? onlinePayment : variant === 'manual' ? manual : onboarding}

        <Box display="flex" alignItems="center" justifyContent="center">
          {_project && <ProjectCard project={_project} />}
        </Box>
        {variant === 'onlinePayment' && (
          <Box>
            <Button
              className={classes.projectsButton}
              variant="text"
              name={t('social_cashback.select_another')}
              onClick={(e) => {
                e.preventDefault();
                setOpenProjectChooser(true);
              }}
            >
              {t('social_cashback.select_another')}
            </Button>
          </Box>
        )}
        {_project && (
          <ProjectChooser
            open={openProjectChooser}
            onSelect={(project) => {
              setOpenProjectChooser(false);
              setProject(project);
              onSelectProject(project);
            }}
            onClose={() => setOpenProjectChooser(false)}
            currentSelectedProject={_project}
          />
        )}
      </div>
    </>
  );
};

export default SocialCashback;
