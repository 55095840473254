import React, { createContext, ReactElement, useContext } from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { themeOptions } from '../../global/ThemeOption';
import { createTheme } from '@mui/material/styles';
import DefaultBackground from './default.png';
import PayactiveLogo from './payactive.png';
import { CompanyBrand } from '../../types/CompanyBrand';

export const WithCompanyBrand = createContext<CompanyBrand>({} as CompanyBrand);

export const WithCompanyBrandProvider = ({ children, companyBrand }: { children: ReactElement; companyBrand?: CompanyBrand }) => {
  return (
    <WithCompanyBrand.Provider
      value={{
        brandColor: companyBrand?.brandColor,
        brandTextColor: companyBrand?.brandTextColor,
        brandBackgroundImageUrl: companyBrand?.brandBackgroundImageUrl || DefaultBackground,
        companyLogoUrl: companyBrand?.companyLogoUrl || PayactiveLogo,
      }}
    >
      {children}
    </WithCompanyBrand.Provider>
  );
};

const CustomerThemeProvider = ({ children }: { children: ReactElement }) => {
  const { brandColor, brandTextColor, brandBackgroundImageUrl } = useContext(WithCompanyBrand);

  const usedBrandColor = brandColor || undefined;
  const usedBrandTextColor = brandTextColor || undefined;
  const usedBackground = brandBackgroundImageUrl || DefaultBackground;

  const boxedChildren = (
    <Box
      sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: {
          sm: `url(${usedBackground})`,
        },
        backgroundSize: { sm: 'cover' },
      }}
    >
      <Box
        sx={{
          backdropFilter: 'blur(8px)',
          minWidth: '100vw',
          minHeight: '100vh',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          p: {
            sm: '90px 0',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );

  if (!usedBrandColor) {
    return boxedChildren;
  }
  const to = themeOptions;
  // @ts-ignore
  to.palette.primary = { main: usedBrandColor, contrastText: usedBrandTextColor };
  const newTheme = createTheme(to);

  return <ThemeProvider theme={newTheme}>{boxedChildren}</ThemeProvider>;
};
export default CustomerThemeProvider;
