import { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InputAdornment, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import makeStyles from '@mui/styles/makeStyles';
import WithPayment from '../WithPayment';
import PublicPaymentService from '../../../services/PublicPaymentService';
import { PaymentState } from '../../../types/PaymentState';
import { createPurpose } from './functions';
import QRCode from 'react-qr-code';

const useStyles = makeStyles((theme) => ({
  warningContainer: {
    display: 'flex',
    backgroundColor: '#DDAF3B',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
  },
  TextField: {
    backgroundColor: '#E6E6E6',
    marginBottom: '1.25rem',
    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function ManualData(): ReactElement {
  const { paymentInformation, paymentId, reloadPaymentInformation } = useContext(WithPayment);
  const classes = useStyles();
  const { t } = useTranslation('global');

  useEffect(() => {
    if (paymentInformation.state === PaymentState.ABORTED) PublicPaymentService.payManually(paymentId).then(() => reloadPaymentInformation());
  }, [paymentId, reloadPaymentInformation, paymentInformation.state]);

  const purpose = createPurpose(paymentInformation);
  const paymentData = [
    {
      label: t('user_form.recipient_of_payment'),
      data: paymentInformation?.companyName,
    },
    {
      label: t('user_form.amount'),
      data: paymentInformation?.amount.toString(),
    },
    {
      label: t('menu.iban'),
      data: paymentInformation?.companyIban || '',
    },
    {
      label: t('menu.purpose'),
      data: purpose,
    },
  ];

  const qrString = `BCD\n002\n1\nSCT\n\n${paymentInformation.companyName}\n${paymentInformation.companyIban}\nEUR${paymentInformation.amount.toFixed(
    2,
  )}\n\n${paymentInformation.verificationId.trim()}\n${purpose} SQ`;

  return (
    <>
      <Typography variant="h6">{t('manual.payment_data_title')}</Typography>
      <Typography variant="body2" style={{ marginBottom: '1.25rem' }}>
        {t('manual.payment_data_subtitle_qr')}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '1.25rem' }}>
        <QRCode value={qrString} level={'M'}></QRCode>
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '1.25rem' }}>
        {t('manual.or')}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '1.25rem' }}>
        {t('manual.payment_data_subtitle')}
      </Typography>
      {paymentData.map((data) => (
        <TextField
          className={classes.TextField}
          variant="outlined"
          label={data.label}
          value={data.data}
          multiline
          tabIndex={0}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <CopyToClipboard text={data.data}>
                  <Tooltip title="Copy" placement="top">
                    <IconButton aria-label="copy">
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
        />
      ))}
      <div className={classes.warningContainer}>
        <WarningIcon style={{ marginRight: '0.6rem' }} />
        <Typography variant="body2">{t('manual.payment_data_warning')}</Typography>
      </div>
    </>
  );
}
