import React from 'react';
import { Avatar } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Bank } from '../types/Bank/Bank';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export default function BankAvatar({ bank, sx }: { bank: Bank; sx?: SxProps<Theme> }) {
  return (
    <>
      {bank.logo ? (
        <Avatar component={'span'} alt={bank.name} src={bank.logo} sx={sx} />
      ) : (
        <Avatar component={'span'} sx={sx}>
          <AccountBalanceIcon fontSize="small" />
        </Avatar>
      )}
    </>
  );
}
