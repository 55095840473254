import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Container, { useContainerStyles } from '../../../components/Container';
import IconWithHeadline from '../../../components/IconWithHeadline';
import lock from '../../../components/assets/lock.svg';
import loadingSpinner from '../../../components/assets/spinner.gif';
import { useContext, useEffect } from 'react';
import { WithProgress } from '../../WithProgressContext';

const useStyle = makeStyles((_) => ({
  loadingSpinner: {
    position: 'absolute',
    width: '64px',
    height: '64px',
    top: '0',
    left: '0',
  },
  iconContainer: { position: 'relative', margin: 'auto', width: '64px', height: '64px' },
  lockIcon: { position: 'absolute', top: '20px', left: '24px' },
}));
const VerificationLoading = () => {
  const containerClasses = useContainerStyles();
  const classes = useStyle();

  const { t } = useTranslation('global');

  const { updatesProgress } = useContext(WithProgress);

  useEffect(() => {
    updatesProgress(67);
  }, [updatesProgress]);

  return (
    <>
      <Container title={<Typography variant="h6">{t('verification.verify_payment')}</Typography>}>
        <>
          <div className={[containerClasses.contentContainer, containerClasses.greyContainer, containerClasses.outerContainer].join(' ')}>
            <div className={[containerClasses.contentContainer, containerClasses.whiteContainer].join(' ')}>
              <IconWithHeadline
                title={t('verification.being_verified')}
                icon={
                  <div className={classes.iconContainer}>
                    <img alt={'loading spinner'} src={loadingSpinner} className={classes.loadingSpinner}></img>
                    <img alt={'lock'} src={lock} className={classes.lockIcon}></img>
                  </div>
                }
              ></IconWithHeadline>
            </div>
          </div>
        </>
      </Container>
    </>
  );
};

export default VerificationLoading;
