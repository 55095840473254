import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';
import { CompanyBrand } from '../types/CompanyBrand';

export default class BrandService {
  private static _instance: AxiosInstance;
  private static _apiDomain = ApiClient.getApiDomain();

  private static get brandClient(): AxiosInstance {
    return this._instance || (this._instance = ApiClient.client);
  }

  public static async getBrandingByCompanyAccountId(companyAccountId: string): Promise<{ companyBrand?: CompanyBrand }> {
    try {
      let res = await this.brandClient.get(this._apiDomain + `/public/company/${companyAccountId}/branding-settings`);
      if (res.status === 200) {
        return { companyBrand: res.data };
      }
      return { companyBrand: {} };
    } catch (error) {
      return { companyBrand: {} };
    }
  }

  public static async getBrandingByVerificationId(verificationId: string): Promise<{ companyBrand?: CompanyBrand }> {
    try {
      let res = await this.brandClient.get(this._apiDomain + `/public/company/verification-id/${verificationId}/branding-settings`);
      if (res.status === 200) {
        return { companyBrand: res.data };
      }
      return { companyBrand: {} };
    } catch (error) {
      return { companyBrand: {} };
    }
  }
}
