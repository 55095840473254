import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IBAN from 'iban';
import HeadlineMenu from '../../../components/HeadlineMenu';
import StateWithIcon from '../../../components/StateWithIcon';
import { PaymentState } from '../../../types/PaymentState';
import { WithPaymentMethod } from '../WithPaymentMethod';

const DirectDebitDetailsMenu = ({ fromSuccess = false }: { fromSuccess?: boolean }) => {
  const { t } = useTranslation('global');

  const { validation, paymentMethodSetUpValues } = useContext(WithPaymentMethod);

  if (!validation) {
    return <></>;
  }

  const insertAt = (menuEntries: any, index: number, ...elementsArray: any) => {
    menuEntries.splice(index, 0, ...elementsArray);
  };

  const menuEntries = [
    {
      name: t('menu.account_holder'),
      value: validation.account_holder,
    },
    {
      name: t('menu.iban'),
      value: IBAN.printFormat(validation.iban),
    },
    {
      name: t('menu.bic'),
      value: validation.bic,
    },
    {
      name: t('menu.state'),
      value: (
        <>
          <Typography variant="body2" style={{ display: 'flex' }}>
            <StateWithIcon state={PaymentState.VERIFIED} label={fromSuccess ? t('states.direct_debit_set_up') : t('states.account_verified')} />
          </Typography>
        </>
      ),
    },
  ];

  fromSuccess &&
    insertAt(
      menuEntries,
      3,
      {
        name: t('menu.creditor_id'),
        value: paymentMethodSetUpValues.creditorIdentifier,
      },
      {
        name: t('menu.company_name'),
        value: paymentMethodSetUpValues.companyName,
      },
    );

  return <HeadlineMenu entries={menuEntries}></HeadlineMenu>;
};

export default DirectDebitDetailsMenu;
