import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Containerized, useContainerStyles } from '../../components/Container';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import OnlinePaymentOption from '../../components/Payment/OnlinePaymentOption';
import ManualPaymentOption from '../../components/Payment/ManualPaymentOption';
import { PaymentState } from '../../types/PaymentState';
import { Navigate } from 'react-router-dom';
import { PaymentPaths } from '../PaymentFlow/PaymentFlow';
import { WithProgress } from '../WithProgressContext';
import OtpVerifiedEmail from '../../components/Otp/OtpVerifiedEmail';
import WithPayment, { WithPaymentBrandedProvider } from '../PaymentFlow/WithPayment';
import { toLocalAmount } from '../../utils/NumberFormatUtil';
import PaymentDetailsMenu from '../../components/Payment/PaymentDetailsMenu';

const useStyles = makeStyles(() => ({
  marginTop: {
    margin: '10px 0 0 0',
  },
  centeredText: {
    textAlign: 'center',
  },
  titleContainer: {
    padding: '0.7rem',
  },
}));

export default function PaymentResolution({ updateContainer }: Containerized) {
  const { t } = useTranslation();
  const containerClasses = useContainerStyles();
  const { updatesProgress } = useContext(WithProgress);
  const classes = useStyles();
  const { paymentInformation } = useContext(WithPayment);

  useEffect(() => {
    updatesProgress(20);
    updateContainer({
      title: (
        <>
          <Grid container direction={'row'} justifyContent={'space-between'}>
            <Grid item>
              <Typography variant="h6" style={{ marginRight: 'auto' }}>
                {t('resolution.menu_title')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">{toLocalAmount(paymentInformation.amount)} €</Typography>
            </Grid>
          </Grid>
        </>
      ),
      menu: <PaymentDetailsMenu />,
      bigIcon: false,
    });
  }, [paymentInformation]);

  if (paymentInformation && [PaymentState.COMPLETED, PaymentState.VERIFIED].includes(paymentInformation.state)) {
    const paymentPath = `/payment/${paymentInformation?.paymentFlowId}`;
    return <Navigate to={`${paymentPath}${PaymentPaths.success}?f=m`} />;
  }

  return (
    <WithPaymentBrandedProvider
      value={{ paymentId: paymentInformation.paymentFlowId, reloadPaymentInformation: () => Promise.reject(), restartPath: '', paymentInformation }}
    >
      <Grid container direction={'column'} alignItems={'center'} alignContent={'stretch'} className={classes.marginTop}>
        {paymentInformation ? (
          <>
            <Grid item className={[containerClasses.contentContainer, containerClasses.greyContainer, classes.titleContainer].join(' ')}>
              <Typography variant="h6">{t('resolution.app_title')}</Typography>
              <Typography variant={'body2'} style={{ textAlign: 'center' }}>
                {t('resolution.problem_description')}
              </Typography>
              <OnlinePaymentOption paymentFlowId={paymentInformation.paymentFlowId} />
            </Grid>
            <ManualPaymentOption paymentFlowId={paymentInformation.paymentFlowId} />
          </>
        ) : (
          <Grid item className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
            <Typography variant={'body2'}>{t('resolution.errors.payment_information_could_not_be_loaded')}</Typography>
            <br />
            <OtpVerifiedEmail />
          </Grid>
        )}
      </Grid>
    </WithPaymentBrandedProvider>
  );
}
