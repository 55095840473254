import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import WithPayment from './WithPayment';
import { toLocalAmount } from '../../utils/NumberFormatUtil';

const YourPaymentTitle = () => {
  const { t } = useTranslation('global');

  const { paymentInformation } = useContext(WithPayment);
  return (
    <>
      <Typography variant="h6" style={{ marginRight: 'auto' }}>
        {t('online_payment.your_payment')}
      </Typography>
      <Typography variant="h6">{toLocalAmount(paymentInformation.amount)} €</Typography>
    </>
  );
};

export default YourPaymentTitle;
