import { Link } from '@mui/material';
import { useContext } from 'react';
import HeadlineMenu from '../../components/HeadlineMenu';
import { useTranslation } from 'react-i18next';
import StateWithIcon from '../../components/StateWithIcon';
import ApiClient from '../../services/ApiClient';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PaymentState } from '../../types/PaymentState';
import { formatDateTime } from '../../utils/DateFormatUtils';
import WithPayment from '../../pages/PaymentFlow/WithPayment';
import { createPurpose } from '../../pages/PaymentFlow/ManualPayment/functions';

const PaymentDetailsMenu = () => {
  const { t } = useTranslation('global');

  const { paymentInformation, paymentId } = useContext(WithPayment);

  const visibleState = [PaymentState.MANUAL, PaymentState.ABORTED].includes(paymentInformation.state)
    ? PaymentState.PENDING
    : paymentInformation.state;

  const menuEntries = [
    {
      name: t('menu.payment_purpose'),
      value: createPurpose(paymentInformation),
    },
    {
      name: t('menu.receiver'),
      value: paymentInformation.companyName,
    },
    {
      name: t('menu.payment_status'),
      value: <StateWithIcon state={visibleState} label={t(`states.${visibleState}`)} />,
    },
    {
      name: t('menu.created_date'),
      value: formatDateTime(paymentInformation.createdDate, t),
    },
  ];
  if (paymentInformation.paymentDate) {
    menuEntries.push({
      name: t('menu.paid_date'),
      value: formatDateTime(paymentInformation.paymentDate, t),
    });
  }
  if (paymentInformation.invoiceTitle) {
    menuEntries.push({
      name: t('menu.download_invoice'),
      value: (
        <Link underline="hover" href={`${ApiClient.getApiDomain()}/public/payments/${paymentId}/invoice`} target="_blank">
          <FileDownloadIcon sx={{ fontSize: '1rem', position: 'relative', top: '4px' }} />
          {paymentInformation.invoiceTitle}
        </Link>
      ),
    });
  }

  return <HeadlineMenu entries={menuEntries}></HeadlineMenu>;
};

export default PaymentDetailsMenu;
