import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useNavigate } from 'react-router-dom';
import { ErrorCodes } from './ErrorPage';

export default function useErrorHandler() {
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();

  return (error: Error, code: ErrorCodes = ErrorCodes.unexpected, forwardToErrorPage = true) => {
    appInsights.trackException({ exception: error });
    if (forwardToErrorPage) {
      navigate('/payment/error?e=' + code);
    }
  };
}
