import makeStyles from '@mui/styles/makeStyles';
import logo from './logo.png';
import logoWhite from './payactive_white.png';
import poweredByPayactiveLogo from './powered_by_payactive.png';

const useStyles = makeStyles({
  root: {
    paddingTop: '14px',
  },
  subtext: {
    color: '#14a6c8',
    fontSize: '7pt',
  },
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PayactiveLogoWhite({ pixels = 100 }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        style={{
          maxWidth: pixels + 'px',
        }}
        alt="logo"
        src={logoWhite}
      />
    </div>
  );
}
function PoweredByPayactive({ pixels = 100 }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        style={{
          maxWidth: pixels + 'px',
        }}
        alt="logo"
        src={poweredByPayactiveLogo}
      />
    </div>
  );
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PayactiveLogoWithText({ pixels = 100 }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        style={{
          maxWidth: pixels + 'px',
        }}
        alt="logo"
        src={logo}
      />
    </div>
  );
}

export { PayactiveLogoWhite, PayactiveLogoWithText, PoweredByPayactive };
