import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useContainerStyles } from '../../components/Container';
import WithCheckout from './WithCheckout';
import { Form, Formik } from 'formik';
import CustomFields from './CustomFields';
import { STEPS, WithCustomerData } from './WithCustomerData';
import PriceSummary from './PriceSummary';
import { WithProgress } from '../WithProgressContext';
import { schema } from './Schemas';
import { useMatch, useNavigate } from 'react-router-dom';
import LoadingButton from '../../components/LoadingButton';
import { ProductType } from '../../types/CheckoutInformation';
import FlexiblePriceSummary from './FlexiblePriceSummary';

const useStyles = makeStyles((_) => ({
  productImage: {
    width: '100%',
    paddingBottom: '75%',
    borderRadius: '4px 4px 0px 0px',
    backgroundPosition: 'center',
  },
  quantityContainer: {
    padding: '10px 15px',
  },
}));

const InitCheckoutScreen = ({ resetContainer }: { resetContainer: () => void }) => {
  const classes = useStyles();
  const { totalPrice, updateProductPrice, updateTotalPrice, totalTax, quantity, updateQuantity, customFields } = useContext(WithCustomerData);
  const { updatesProgress } = useContext(WithProgress);
  const navigate = useNavigate();

  const match = useMatch('/payment/checkout/:paylinkExternalId/*');
  const paylinkExternalId = match?.params.paylinkExternalId;

  const { checkoutInformation } = useContext(WithCheckout);
  const containerClasses = useContainerStyles();

  const isDonationProduct = checkoutInformation.product.productType === ProductType.DONATION;

  const { t } = useTranslation('global');

  useEffect(() => {
    updatesProgress(10);
    resetContainer();
  }, []);

  const calculateTax = (quantity: number, taxRate: number, netPrice: number) => {
    return (taxRate / 100) * netPrice * quantity;
  };

  const calculatePrice = (quantity: number, taxRate: number, netPrice: number) => {
    return netPrice * quantity + calculateTax(quantity, taxRate, netPrice);
  };

  return (
    <Formik
      validationSchema={schema(t, checkoutInformation)[STEPS.INIT]}
      initialValues={{
        customFields: customFields,
        donationAmount: isDonationProduct ? checkoutInformation.product.defaultDonationAmount : checkoutInformation.product.price,
      }}
      onSubmit={(values) => {
        if (isDonationProduct) {
          updateProductPrice(values.donationAmount);
          updateTotalPrice(calculatePrice(quantity, checkoutInformation.product.taxRate.rate, values.donationAmount));
        }
        navigate(`/payment/checkout/${paylinkExternalId}/summary${window.location.search}`);
      }}
    >
      {({ isValid }) => (
        <Form>
          {checkoutInformation.product.image?.blobURL && (
            <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
              <div
                style={{
                  backgroundImage: `url(${checkoutInformation.product.image?.blobURL})`,
                  backgroundSize: 'cover',
                }}
                className={classes.productImage}
              />
            </div>
          )}

          {checkoutInformation.allowQuantityChange && (
            <div className={[containerClasses.contentContainer, containerClasses.greyContainer, classes.quantityContainer].join(' ')}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant={'h6'}>{t('checkout.quantity')}</Typography>
                </Grid>
                <Grid item container justifyContent={'space-evenly'} alignItems={'center'}>
                  <Grid item>
                    <IconButton onClick={() => updateQuantity(quantity - 1)} disabled={quantity <= 1}>
                      <RemoveCircleOutlineRoundedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: '24px', color: 'black' }}>{quantity}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => updateQuantity(quantity + 1)}>
                      <AddCircleOutlineRoundedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
          <CustomFields />
          <div className={[containerClasses.contentContainer, containerClasses.greyContainer].join(' ')}>
            <div>
              <>
                <Typography variant={'h6'} style={{ margin: '0 0 0.5rem' }}>
                  {checkoutInformation.product.name}
                </Typography>
                {checkoutInformation.product.description && (
                  <Typography variant={'body2'} style={{ margin: '0 0 0.5rem' }}>
                    {checkoutInformation.product.description}
                  </Typography>
                )}
                <Box mt={2} mb={2}></Box>
                <Divider />
                <Box mt={2} mb={2}></Box>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {checkoutInformation.product.productType === ProductType.DONATION && <FlexiblePriceSummary />}
                  {checkoutInformation.product.productType === ProductType.PRODUCT && (
                    <PriceSummary totalPrice={totalPrice} recurring={checkoutInformation.product.recurringBillingPeriod} tax={totalTax} />
                  )}
                </div>
              </>
            </div>
            <LoadingButton disabled={!isValid} style={{ width: 'fit-content' }} className={containerClasses.button} loading={false}>
              {t('buttons.next')}
            </LoadingButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InitCheckoutScreen;
