import { Typography } from '@mui/material';
import { ReactElement } from 'react';

type IconWithHeadlineProps = {
  icon: ReactElement;
  title: ReactElement | string;
  variantIcon?: 'up' | 'down';
  children?: ReactElement;
};

const IconWithHeadline = ({ icon, title, variantIcon = 'down', children }: IconWithHeadlineProps) => {
  return (
    <>
      <div>
        {variantIcon === 'up' && icon}
        <Typography variant="h6">{title}</Typography>
        {variantIcon === 'down' && icon}
        {children}
      </div>
    </>
  );
};

export default IconWithHeadline;
