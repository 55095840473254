import React, { ReactElement, useContext } from 'react';
import WithCheckout from '../WithCheckout';
import { useTranslation } from 'react-i18next';
import HeadlineMenu from '../../../components/HeadlineMenu';
import { PaymentState } from '../../../types/PaymentState';
import { Typography } from '@mui/material';
import StateWithIcon from '../../../components/StateWithIcon';
import { STEPS, WithCustomerData } from '../WithCustomerData';
import { insertAt } from '../../../utils/MenuUtils';
import { formatDate } from '../../../utils/DateFormatUtils';

interface Props {
  currentStep: number;
}

export default function CheckoutOnlineMenu({ currentStep }: Props): ReactElement {
  const { checkoutInformation } = useContext(WithCheckout);
  const { t } = useTranslation('global');

  const isDonation = checkoutInformation.product.productType === 'DONATION';
  const isSubscription = checkoutInformation.product.billingType === 'RECURRING';

  const { customFields } = useContext(WithCustomerData);

  const menuEntries = [
    {
      name: t('checkout.info'),
      value: checkoutInformation.product.description ? checkoutInformation.product.description : checkoutInformation.product.name,
    },
  ];

  customFields?.map(
    (cf: any, index: any) =>
      customFields?.[index].fieldValue &&
      insertAt(menuEntries, 1 + index, {
        name: cf.fieldLabel,
        value: customFields?.[index].fieldType === 'DATE' ? formatDate(customFields?.[index].fieldValue as string) : customFields?.[index].fieldValue,
      }),
  );

  currentStep >= STEPS.USER_FORM &&
    insertAt(menuEntries, 3, {
      name: t('checkout.payment'),
      value: t('checkout.online_payment'),
    });

  currentStep === STEPS.SUCCESS &&
    insertAt(menuEntries, menuEntries.length, {
      name: t('menu.state'),
      value: (
        <>
          <Typography variant="body2" style={{ display: 'flex' }}>
            <StateWithIcon
              state={PaymentState.VERIFIED}
              label={isDonation ? t('states.donated') : isSubscription ? t('states.subscribed') : t('states.bought')}
            />
          </Typography>
        </>
      ),
    });

  return <HeadlineMenu entries={menuEntries}></HeadlineMenu>;
}
