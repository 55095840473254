import { createContext, ReactElement } from 'react';
import { DebtorPaymentMethodSetup } from '../../types/Debtor/DebtorPaymentMethodSetup';
import { FintecRiskResult } from '../../types/FintecRiskResult';
import { WithCompanyBrandProvider } from '../../components/CustomerThemeProvider/CustomerThemeProvider';

type WithPaymentMethodT = {
  setupId: string;
  companyId?: string;
  paymentMethodSetUpValues: DebtorPaymentMethodSetup;
  restartPath: string;
  validation?: FintecRiskResult;
  setValidation: (riskResult: FintecRiskResult) => void;
};

export const WithPaymentMethod = createContext<WithPaymentMethodT>({
  setupId: '',
  companyId: '',
  paymentMethodSetUpValues: {} as DebtorPaymentMethodSetup,
  restartPath: '',
  setValidation: (_) => {
    throw new Error('useContext without initialization');
  },
});

export const WithPaymentMethodBrandedProvider = ({ value, children }: { value: WithPaymentMethodT; children: ReactElement }) => {
  return (
    <WithPaymentMethod.Provider value={value}>
      <WithCompanyBrandProvider companyBrand={value.paymentMethodSetUpValues}>{children}</WithCompanyBrandProvider>
    </WithPaymentMethod.Provider>
  );
};

export default WithPaymentMethod;
