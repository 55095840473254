import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import SocialCashback from '../../../components/SocialCashback/SocialCashback';
import { Project } from '../../../types/Project';
import useErrorHandler from '../../Error/ErrorHandler';
import { ErrorCodes } from '../../Error/ErrorPage';
import StatusIcon from '../../../components/StatusIcon';
import WithPayment from '../WithPayment';
import PublicPaymentService from '../../../services/PublicPaymentService';

export default function OnlineSuccessful(): ReactElement {
  const { t } = useTranslation('global');

  const { paymentId, paymentInformation } = useContext(WithPayment);
  const handleGenericError = useErrorHandler();

  const onSelectProject = (project: Project) => {
    PublicPaymentService.updateProject(paymentId, project.id).then((res) => {
      if (!res) {
        handleGenericError(new Error('PublicPaymentService.updateProject failed'), ErrorCodes.Project_update_failed);
      }
    });
  };

  return (
    <>
      <StatusIcon variantIcon="up" variant="success" iconSize="medium" title={t('success.payment_successful')}>
        <Typography variant="body2">{t('success.received_your_payment')}</Typography>
      </StatusIcon>
      <SocialCashback
        socialCashbackCents={paymentInformation.socialCashbackCents}
        project={paymentInformation.project}
        onSelectProject={onSelectProject}
        variant="onlinePayment"
      />
    </>
  );
}
