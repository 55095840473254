import React, { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ErrorCodes, ErrorPage } from '../Error/ErrorPage';
import useErrorHandler from '../Error/ErrorHandler';
import { ContainerRef, ContainerReference } from '../../components/Container';
import { CheckoutInformation } from '../../types/CheckoutInformation';
import PublicCheckoutService from '../../services/PublicCheckoutService';
import { WithCheckoutBrandedProvider } from './WithCheckout';
import CustomerThemeProvider from '../../components/CustomerThemeProvider/CustomerThemeProvider';
import LoadingContainer from '../../components/LoadingContainer';
import CheckoutController from './CheckoutController';
import { Typography } from '@mui/material';
import WithCustomerDataContextProvider from './WithCustomerData';
import OtpContextProvider from '../../components/Otp/OtpContextProvider';
import InitCheckoutScreen from './InitCheckoutScreen';

export const CheckoutPaths = {
  online: '/online',
};

export default function CheckoutFlowRouter() {
  const match = useMatch('/payment/checkout/:paylinkExternalId/*');
  const paylinkExternalId = match?.params.paylinkExternalId;

  const [checkoutInformation, setCheckoutInformation] = useState<CheckoutInformation>({} as CheckoutInformation);
  const handleGenericError = useErrorHandler();

  const checkForExternalId = () => {
    if (localStorage.getItem('paylinkExternalId')) {
      if (localStorage.getItem('paylinkExternalId') === paylinkExternalId) {
        return;
      } else {
        const emailAddress = localStorage.getItem('emailAddress');
        const accessToken = localStorage.getItem('accessToken');
        localStorage.clear();
        if (emailAddress) localStorage.setItem('emailAddress', emailAddress);
        if (accessToken) localStorage.setItem('accessToken', accessToken);
        sessionStorage.clear();
      }
    } else {
      paylinkExternalId !== undefined && localStorage.setItem('paylinkExternalId', paylinkExternalId);
    }
  };

  function loadCheckoutInformation() {
    if (paylinkExternalId !== undefined) {
      checkForExternalId();
      return PublicCheckoutService.getCheckoutPaylinks(paylinkExternalId)
        .then((res) => {
          if (res) {
            setCheckoutInformation(res);
          }
        })
        .catch((e) => {
          handleGenericError(e, ErrorCodes.Checkout_get_failed);
        });
    }
    return Promise.reject<any>();
  }

  useEffect(() => {
    if (checkoutInformation.checkoutState === undefined) {
      loadCheckoutInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = useRef<ContainerReference>(null);

  if (paylinkExternalId === undefined) {
    return <ErrorPage errorCode={ErrorCodes.Checkout_not_found_flow} />;
  }

  if (checkoutInformation.checkoutState === undefined) {
    return <LoadingContainer />;
  }

  return (
    <WithCheckoutBrandedProvider
      value={{
        paylinkExternalId: paylinkExternalId,
        restartPath: '/payment/checkout/' + paylinkExternalId,
        checkoutInformation: checkoutInformation,
        reloadCheckoutInformation: loadCheckoutInformation,
      }}
    >
      <CustomerThemeProvider>
        <WithCustomerDataContextProvider>
          <ContainerRef ref={container}>
            <OtpContextProvider>
              <Routes>
                {/* prettier-ignore */}
                <Route path={'/start'} element={<InitCheckoutScreen resetContainer={() => {
                  container.current?.setProps({
                    title: <Typography variant="h6">Checkout</Typography>,
                    menuOpen: true,
                    bigIcon: true,
                  });
                }} />} />
                <Route
                  path={'/summary'}
                  element={
                    <CheckoutController
                      updateContainer={(props) => {
                        container.current?.setProps(props);
                      }}
                    />
                  }
                />
                <Route path={'*'} element={<Navigate to={`/payment/checkout/${paylinkExternalId}/start${window.location.search}`} />} />
              </Routes>
            </OtpContextProvider>
          </ContainerRef>
        </WithCustomerDataContextProvider>
      </CustomerThemeProvider>
    </WithCheckoutBrandedProvider>
  );
}
