import { TFunction } from 'i18next';
import IBAN from 'iban';
import * as Yup from 'yup';
import { CheckoutInformation } from '../../types/CheckoutInformation';
import bicValidator from 'bic-validator';
import { formatDate } from '../../utils/DateFormatUtils';
import AutoNumeric from 'autonumeric';

const schema = (t: TFunction, checkoutInformation: CheckoutInformation): { [key in number]?: any } => {
  return {
    0: Yup.object().shape({
      donationAmount: Yup.number().min(
        checkoutInformation.product.price,
        t('user_form.invalid_donation_amount', {
          minDonationAmount: AutoNumeric.format(checkoutInformation.product.price, AutoNumeric.getPredefinedOptions().commaDecimalCharDotSeparator),
        }),
      ),
      customFields: Yup.array().of(
        Yup.object({
          fieldValue: Yup.string()
            .nullable()
            .when('required', (required, schema) => {
              return required ? schema.required(t('user_form.required')) : schema;
            })
            .when('fieldType', (type, schema) => {
              return type === 'DATE'
                ? schema.test('fieldValue', t('checkout.invalid_date_format'), (value: Date) => {
                    try {
                      formatDate(value);
                      return true;
                    } catch (e) {
                      return false;
                    }
                  })
                : schema;
            }),
        }),
      ),
    }),
    1: Yup.object().shape({
      verificationData: Yup.object().shape({
        debitorEmailAddress: Yup.string()
          .email(t('user_form.invalid_email'))
          .required(t('user_form.required', { field: t('emailAddress') })),
      }),
    }),
    5: Yup.object().shape({
      accountHolder: Yup.string().required(t('user_form.required')),
      iban: Yup.mixed()
        .required(t('user_form.required'))
        .test('valid', t('user_form.no_valid_iban'), (iban?: string) => {
          return IBAN.isValid(iban || ''.replaceAll(/\s|_/g, ''));
        }),
      bic: Yup.mixed()
        .required(t('user_form.required'))
        .test('valid', t('user_form.no_valid_bic'), (bic) => bicValidator.isValid(bic)),
    }),
    7: Yup.object().shape({
      debtor: Yup.object().shape({
        firstName: Yup.string().required(t('user_form.required')),
        lastName: Yup.string().required(t('user_form.required')),
        companyName: Yup.string().when('type', {
          is: 'ORGANIZATION',
          then: (schema) => schema.required(() => (checkoutInformation.requireAddress ? t('user_form.required') : undefined)),
        }),
        vatId: Yup.string().when('type', {
          is: 'ORGANIZATION',
          then: (schema) => schema.required(() => (checkoutInformation.reverseChargeEnabled ? t('user_form.required') : undefined)),
        }),
      }),
      address: Yup.object().shape({
        line: Yup.string().required(() => (checkoutInformation.requireAddress ? t('user_form.required') : undefined)),
        zipCode: Yup.number()
          .required(() => (checkoutInformation.requireAddress ? t('user_form.required') : undefined))
          .positive(t('user_form.positive'))
          .typeError(t('user_form.positive')),
        city: Yup.string().required(() => (checkoutInformation.requireAddress ? t('user_form.required') : undefined)),
        country: Yup.string().required(() => (checkoutInformation.requireAddress ? t('user_form.required') : undefined)),
      }),
    }),
  };
};

export { schema };
